import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAnalyticsService, PodcastAnalyticsService } from "common/services";

class ProducerDashboardRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.podcastAnalyticsService = rootStore.createApplicationService(PodcastAnalyticsService);
        this.nakiNigunAnalyticsService = rootStore.createApplicationService(NakiNigunAnalyticsService);
    }

    async getTrendingPodcasts(filter) {
        const response = await this.podcastAnalyticsService.getTrendingPodcastsForProducer(filter);
        return response.data;
    }

    async getTrendingAlbums(filter) {
        const response = await this.nakiNigunAnalyticsService.getTrendingAlbumsForProducer(filter);
        return response.data;
    }

    async getTrendingAlbumsTotalStreams(filter) {
        const response = await this.nakiNigunAnalyticsService.getTrendingAlbumsTotalStreamsForProducer(filter);
        return response.data;
    }

    async getTrendingPodcastsTotalStreams(filter) {
        const response = await this.podcastAnalyticsService.getTrendingPodcastsTotalStreamsForProducer(filter);
        return response.data;
    }
}

export default ProducerDashboardRouteStore;
