import React from "react";
import { PropTypes } from "prop-types";
import {
    HomePageLayout,
    HomePageHeader,
    HomeMainView,
    HomeSearchResults,
    ConnectDeviceModal,
} from "application/home/components";
import { MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function HomeTemplate({ homeViewStore, t }) {
    const { connectDeviceModal } = homeViewStore;
    return (
        <HomePageLayout store={homeViewStore} t={t}>
            <div>
                <MrbPageHeader classNameExtend="c-page__header--secondary">
                    <HomePageHeader homeViewStore={homeViewStore} t={t} />
                </MrbPageHeader>
                <ResultsContainer homeViewStore={homeViewStore} t={t} />
            </div>
            <ConnectDeviceModal modalParams={connectDeviceModal} t={t} />
        </HomePageLayout>
    );
}

HomeTemplate.propTypes = {
    homeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const ResultsContainer = observer(function ResultsContainer({
    homeViewStore,
    t,
}) {
    const { inSearchMode, homeGenericSearchViewStore } = homeViewStore;
    return (
        <React.Fragment>
            {inSearchMode ? (
                <HomeSearchResults
                    homeGenericSearchViewStore={homeGenericSearchViewStore}
                    t={t}
                />
            ) : (
                <HomeMainView homeViewStore={homeViewStore} t={t} />
            )}
        </React.Fragment>
    );
});

export default defaultTemplate(HomeTemplate);
