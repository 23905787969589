import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { action, computed } from "mobx";

class DeviceListViewStore extends MrbBaseListViewStore {
    @computed get isAddDeviceEnabled() {
        return this.rootStore.userDeviceStore.isDeviceSlotAvailable;
    }

    @computed get deviceLimit() {
        return this.rootStore.userDeviceStore.deviceLimit;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            layoutActions: {
                create: {
                    onClick: () => this.rootStore.routerStore.goTo("master.application.device.create"),
                    label: "Add New Device",
                },
            },
            queryConfig: {
                embed: "device,device.systemType",
                orderBy: "deviceName",
                orderDirection: "asc",
            },
        });
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) =>
                    this.rootStore.routerStore.goTo("master.application.device.edit", { id: item.id }),
                actions: {
                    delete: {
                        onClick: (item) => this.deleteUserDevice(item),
                    },
                },
            })
        );

        this.joyrideSteps = [
            {
                target: ".c-device__card--sml",
                content: "Here you can set prohibited time for this device!",
            },
        ];

        this.goToAddNewDevice = this.goToAddNewDevice.bind(this);
    }

    find(params) {
        return this.routeStore.findUserDevices(params);
    }

    goToAddNewDevice() {
        this.rootStore.routerStore.goTo("master.application.device.create");
    }

    @action.bound
    async deleteUserDevice(resource) {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to delete device?",
            onConfirm: async () => {
                try {
                    this.rootStore.mainLoaderStore.suspend();
                    await this.routeStore.deleteUserDevice(resource);
                    this.rootStore.mainLoaderStore.resume();
                    this.queryUtility.fetch();
                } catch (err) {
                    this.rootStore.mainLoaderStore.resume();
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred. Unable to delete device.",
                        err
                    );
                }
            },
            yesLabel: "Delete",
            noLabel: "Cancel",
            description: "You won't be able to revert this action.",
        });
    }
}

export default DeviceListViewStore;
