import { LocalizedForm } from "common/localization";

export default class UserProfileChangePasswordForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "password",
                    label:
                        "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.FIELDS.PASSWORD_LABEL",
                    type: "password",
                    placeholder:
                        "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.FIELDS.PASSWORD_PLACEHOLDER",
                    rules: [
                        "required",
                        "string",
                        "min:8",
                        "regex:/([^a-zA-Z\\d])+([a-zA-Z\\d])+|([a-zA-Z\\d])+([^a-zA-Z\\d])+/",
                    ],
                },
                {
                    name: "confirmPassword",
                    label:
                        "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.FIELDS.CONFIRM_PASSWORD_LABEL",
                    placeholder:
                        "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.FIELDS.CONFIRM_PASSWORD_PLACEHOLDER",
                    rules: "required|string|same:password",
                    type: "password",
                },
            ],
        };
    }
}
