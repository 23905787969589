import { computed, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { httpStatusCodes } from "mrb/core/constants";
import { TorahAnytimeTopicsFilter } from "application/torah-anytime/filters";

class TorahAnytimeSelectTopicsViewStore extends MrbBaseListViewStore {
    _abortController = null;

    @computed get isTopicSelectionEmpty() {
        return this.torahAnytimeSelectionViewStore.selectedTopics.length === 0;
    }

    constructor(rootStore, { routeStore, torahAnytimeSelectionViewStore }) {
        super(rootStore, {
            queryConfig: {
                filter: new TorahAnytimeTopicsFilter(),
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.torahAnytimeSelectionViewStore = torahAnytimeSelectionViewStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
            })
        );

        this.reaction(
            () => (this.queryUtility.filter.search || "").trim(),
            () => {
                this.queryUtility.fetch();
            },
            {
                delay: 300,
            }
        );
    }

    onInit() {
        return this.queryUtility.initialize(true, true);
    }

    find(params) {
        this.abortExistingRequest();
        this._abortController = new AbortController();
        return this.routeStore.findTopics(params, this._abortController);
    }

    abortExistingRequest() {
        if (this._abortController) {
            this._abortController.abort();
        }
    }

    onFetchError(error) {
        if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
            return;
        }
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    onDestroy() {
        this.abortExistingRequest();
    }
}

export default TorahAnytimeSelectTopicsViewStore;
