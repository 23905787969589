import { action, makeObservable, observable, computed } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { find } from "lodash";

class ProducerPreviewViewStore extends MrbBaseViewStore {
    @observable selectedTab = { value: 1, title: "Dashboard" };

    tabs = [
        { value: 1, title: "Dashboard" },
        { value: 2, title: "Revenue" },
    ];

    @computed get producerTypeSlug() {
        return this.rootStore.userStore?.producerType?.slug;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        makeObservable(this);
    }

    async onInit() {
        var currentRoute = this.rootStore.routerStore.getCurrentRoute();
        if (currentRoute.name === "master.application.producer.preview.dashboard" && this.selectedTab.value !== 1) {
            this.setSelectedTab(1);
        } else if (
            currentRoute.name === "master.application.producer.preview.revenue" &&
            this.selectedTab.value !== 2
        ) {
            this.setSelectedTab(2);
        }
    }

    onViewUpdate(prevProps, newProps) {
        var currentRoute = this.rootStore.routerStore.getCurrentRoute();
        if (this.selectedTab.value === 2 && currentRoute.name === "master.application.producer.preview.dashboard") {
            this.setSelectedTab(1);
        }
    }

    @action.bound
    setSelectedTab(value) {
        this.selectedTab = find(this.tabs, { value: value });
    }
}

export default ProducerPreviewViewStore;
