import { override } from "mobx";
import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService } from "common/services";

class DeviceCreateRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceService);
    }

    async registerUserDevice(resource) {
        const response = await this.service.registerUserDevice(resource);
        await this.rootStore.userDeviceStore.loadUserDevices();
        return response.data;
    }

    @override
    onBeforeEnter() {
        if (!this.rootStore.userDeviceStore.isDeviceSlotAvailable) {
            return Promise.reject("master.application.device.list");
        }
    }
}

export default DeviceCreateRouteStore;
