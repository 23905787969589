import React from "react";
import { DeviceListTemplate } from "themes/application/device-settings/pages";
import { DeviceListViewStore } from "application/device-settings/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new DeviceListViewStore(rootStore, componentProps),
    "deviceListViewStore"
)
class DeviceList extends React.Component {
    render() {
        return <DeviceListTemplate {...this.props} />;
    }
}

export default DeviceList;
