import { MrbBaseRouteStore } from "mrb/core";
import {
    NakiNigunAnalyticsService,
    PodcastAnalyticsService,
    ProducerReportService,
    ProducerUserAccountService,
} from "common/services";

class ProducerRevenueRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ProducerReportService);
        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
        this.podcastAnalyticsService = rootStore.createApplicationService(PodcastAnalyticsService);
        this.nakiNigunAnalyticsService = rootStore.createApplicationService(NakiNigunAnalyticsService);
    }

    async findProducerReports(params) {
        const response = await this.service.findProducerReports(params);
        return response.data;
    }

    async getProducerAccountSummary() {
        const response = await this.producerUserAccountService.getProducerAccountSummary();
        return response.data;
    }

    async getStripeConnectOnboardingStatus() {
        const response = await this.producerUserAccountService.getStripeConnectOnboardingStatus();
        return response.data;
    }

    async createStripeConnectLink(resource) {
        const response = await this.producerUserAccountService.createStripeConnectLink(resource);
        return response.data;
    }

    async getProducerAccountBalance(filter) {
        const response = await this.producerUserAccountService.getProducerAccountBalance(filter);
        return response.data;
    }

    async getProducerAccountRevenueTransaction(filter) {
        const response = await this.producerUserAccountService.getProducerAccountRevenueTransaction(filter);
        return response.data;
    }

    async getProducers() {
        const response = await this.producerUserAccountService.getProducers();
        return response.data;
    }
}

export default ProducerRevenueRouteStore;
