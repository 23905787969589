import React from "react";
import { PropTypes } from "prop-types";
import { HomeEmptyState, HomeOrderBySelect, HomeListSearchLoader, HomeListLoader } from "application/home/components";
import { NakiNigunSearchResultGridCell } from "application/naki-nigun/components";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { MrbGrid } from "mrb/components/grid";
import { PremiumBadge, Image } from "common/components";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbPager } from "mrb/components/pager";
import { observer } from "mobx-react";
import { map } from "lodash";

function NakiNigunHomeSearchResultsTemplate({ nakiNigunGenericSearchViewStore, t }) {
    const { gridStore, orderBySelectStore, queryUtility } = nakiNigunGenericSearchViewStore;

    return (
        <React.Fragment>
            <MrbContentLoader loaderStore={gridStore.loaderStore} loaderRender={() => <HomeListLoader row={1} title />}>
                {() => (
                    <div>
                        <ArtistsList nakiNigunGenericSearchViewStore={nakiNigunGenericSearchViewStore} t={t} />
                    </div>
                )}
            </MrbContentLoader>

            {gridStore.hasItems && (
                <div className="c-home__filter">
                    <div className="c-home__filter__wrapper">
                        <p className="u-type--base u-type--wgt--bold">Search results</p>

                        <div className="c-home__filter__select">
                            <HomeOrderBySelect store={orderBySelectStore} t={t} />
                        </div>
                    </div>
                </div>
            )}

            <MrbGrid
                store={gridStore}
                t={t}
                classNameOverride="c-home__list--search"
                loaderRender={() => <HomeListSearchLoader row={8} />}
                cellRender={(props) => <NakiNigunSearchResultGridCell {...props} />}
                emptyStateRender={() => <HomeEmptyState classNameExtend="c-empty-state--no-results--lrg" t={t} />}
                pagerRender={(props) => (
                    <MrbPager {...props} hideItemsPerPage={true} hidePagerIfUnderLimit={queryUtility.filter.pageSize} />
                )}
            />
        </React.Fragment>
    );
}

NakiNigunHomeSearchResultsTemplate.propTypes = {
    nakiNigunGenericSearchViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const ArtistsList = observer(function ArtistsList({ nakiNigunGenericSearchViewStore, t }) {
    const {
        artists,
        onClickArtist,
        scrollFeaturedLeft,
        scrollFeaturedRight,
        isAppearsInScrollable,
        setAppearsInContainerRef,
    } = nakiNigunGenericSearchViewStore;
    return (
        <React.Fragment>
            {artists.length > 0 && (
                <div>
                    <div className="c-home__list__header">
                        <div className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                            {t("APPLICATION.NAKI_NIGUN.LIST.GENERIC_SEARCH.APPEARS_IN")}
                        </div>
                        {isAppearsInScrollable && (
                            <div className="u-display--flex">
                                <i
                                    className="u-icon u-icon--base u-icon--arrow-left u-mar--right--tny"
                                    onClick={scrollFeaturedLeft}
                                />
                                <i className="u-icon u-icon--base u-icon--arrow-right" onClick={scrollFeaturedRight} />
                            </div>
                        )}
                    </div>

                    <div className="u-animation--fadeIn">
                        <div className="c-home__list--scroll" ref={setAppearsInContainerRef}>
                            {map(artists, (artist) => (
                                <Artist item={artist} key={artist.artistId} onClickArtist={onClickArtist} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

function Artist({ item, onClickArtist }) {
    const canAccessContent = useCanAccessContent(nakiRadioContentType.nakiNigun, {
        isPremium: item.isPremium,
    });
    return (
        <div className="c-home__card" onClick={() => onClickArtist(item)}>
            <div className="c-home__card__media">
                <div className="c-home__card__img-wrapper">
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge isPremium={item.isArtistPremium} isPremiumSubscriptionValid={canAccessContent} />
                </div>
            </div>
            <div className="c-home__card__title">{item.name}</div>
        </div>
    );
}

export default observer(NakiNigunHomeSearchResultsTemplate);
