import React from "react";
import ContentLoader from "react-content-loader";

const ProducerStatsLoaderTemplate = (props) => {
    const { row = 10, loadMore, noButton, ...rest } = props;
    const screenwidth = window.innerWidth;
    const items = [];
    let loaderWidth;
    let loaderSml = false;

    if (screenwidth > 766) {
        loaderWidth = 768;
    } else {
        loaderWidth = screenwidth;
    }

    if (screenwidth < 543) {
        loaderSml = true;
    }

    let y = loadMore ? 30 : 2;
    let imgY = loadMore ? 16 : 3;

    for (let i = 0; i < row; i++) {
        let textWidth = Math.random() * 120 + 60;
        items.push(
            <React.Fragment key={`top-trending_${i}`}>
                {loadMore && i === 0 && <rect x="0" y={imgY + 0} rx="6" ry="6" width="100%" height="1" />}
                <rect x="10" y={y} rx="4" ry="4" width="10" height="16" />

                <rect x="50" y={loaderSml ? y - 6 : y + 8} rx="4" ry="4" width={textWidth} height="10" />

                <rect x="92%" y={loaderSml ? y + 16 : y + 8} rx="4" ry="4" width="8%" height="10" />
                {loaderSml && <rect x={"94.5%"} y={loaderSml ? y + 34 : y + 6} rx="4" ry="4" width={"12"} height="6" />}
                <rect x="0" y={imgY + (loadMore ? 56 : 40)} rx="6" ry="6" width="100%" height="1" />
            </React.Fragment>
        );

        y += 56;
        imgY += 56.33;
    }

    return (
        <div>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${!noButton ? y + 56 : y - 20}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...rest}
            >
                {items}
            </ContentLoader>
        </div>
    );
};

export default ProducerStatsLoaderTemplate;
