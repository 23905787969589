import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "baasic-react-bootstrap/src";
import { observer } from "mobx-react";

function NakiNigunManageSelectedItemsTemplate({ nakiNigunManageSelectedItemsViewStore, t }) {
    const { isBulk, changeIsBulk, selectAll, deselectAll, isUpdating, saveSelection } =
        nakiNigunManageSelectedItemsViewStore;

    return (
        <div>
            {isBulk ? (
                <div className="c-home__header__options--secondary">
                    <div className="u-display--flex">
                        <MrbButton
                            classNameExtend="u-mar--right--tny"
                            label="Deselect all"
                            onClick={deselectAll}
                            disabled={isUpdating}
                        />
                        <MrbButton label="Select all" onClick={selectAll} disabled={isUpdating} />
                    </div>
                    <div className="u-display--flex">
                        <MrbButton
                            classNameOverride="c-btn c-btn--base c-btn--ghost u-mar--right--tny"
                            label="Cancel"
                            onClick={changeIsBulk}
                            disabled={isUpdating}
                        />
                        <MrbButton label="Save" onClick={saveSelection} disabled={isUpdating} />
                    </div>
                </div>
            ) : (
                <div className="c-home__header__options">
                    <MrbButton label="Edit" classNameExtend="jq-onboarding-bulk-edit" onClick={changeIsBulk} />
                </div>
            )}
        </div>
    );
}

NakiNigunManageSelectedItemsTemplate.propTypes = {
    nakiNigunManageSelectedItemsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunManageSelectedItemsTemplate);
