import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { nakiRadioSystemTypes } from "common/constants";
import { UpdateTorahAnytimeCollectionStatusOptions } from "application/common/models";
import { find, isNil, filter, some } from "lodash";

class TorahAnytimeCollectionListItemViewStore extends MrbBaseViewStore {
    collectionId;

    @observable isCollapsed = false;
    @observable isUpdating = false;

    @computed get hasTopics() {
        return some(this.collection.topics, (topic) => !isNil(topic?.topic));
    }

    @computed get hasSpeakers() {
        return some(this.collection.speakers, (speaker) => !isNil(speaker?.speaker));
    }

    @computed get speakersLength() {
        const speakers = filter(this.collection.speakers, (speaker) => {
            return !isNil(speaker?.speaker);
        });

        return speakers.length;
    }

    @computed get topicsLength() {
        const topics = filter(this.collection.topics, (topic) => {
            return !isNil(topic?.topic);
        });

        return topics.length;
    }

    @computed get collection() {
        return find(this.torahAnytimeViewStore.gridStore.data, (item) => item.id === this.collectionId);
    }

    @computed get contentAccessResult() {
        return this.evaluateContentAccess(this.torahAnytimeViewStore.torahAnytimePodcast);
    }

    @computed get isDisabled() {
        return this.isUpdating;
    }

    @computed get collectionStatusItem() {
        if (this.collection) {
            return this.collection.userDeviceCollection;
        }
        return null;
    }

    @computed get isTurnedOn() {
        if (!isNil(this.collectionStatusItem)) {
            return this.collectionStatusItem.isTurnedOn;
        } else if (this.torahAnytimeViewStore.torahAnytimePodcast) {
            const selectedDevice = this.rootStore.userDeviceStore.selectedDevice;
            if (selectedDevice && selectedDevice.device && selectedDevice.device.systemType) {
                switch (selectedDevice.device.systemType.abrv) {
                    case nakiRadioSystemTypes.venice:
                        return this.torahAnytimeViewStore.torahAnytimePodcast.isTurnedOnByDefault;
                    case nakiRadioSystemTypes.mobile:
                        return this.torahAnytimeViewStore.torahAnytimePodcast.isTurnedOnByDefaultInApp;
                    default:
                        break;
                }
            }
        }
        return false;
    }

    constructor(rootStore, { collectionId, torahAnytimeViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.collectionId = collectionId;
        this.torahAnytimeViewStore = torahAnytimeViewStore;
    }

    @action.bound
    async toggleCollectionStatus(isTurnedOn) {
        this.setIsUpdating(true);
        await this.updateCollectionTurnedOnStatus(isTurnedOn);
        this.setIsUpdating(false);
    }

    @action.bound
    async updateCollectionTurnedOnStatus(isTurnedOn) {
        try {
            const options = new UpdateTorahAnytimeCollectionStatusOptions(
                this.collection.id,
                this.collection.name,
                this.torahAnytimeViewStore.torahAnytimePodcast.id,
                this.torahAnytimeViewStore.torahAnytimePodcast.title,
                this.torahAnytimeViewStore.torahAnytimePodcast.isPremium,
                this.torahAnytimeViewStore.torahAnytimePodcast.isVisibleInApp === false
            );
            await this.rootStore.userContentManagerStore.updateTorahAnytimeCollectionsStatus(options, isTurnedOn);
            runInAction(() => {
                if (this.collection.userDeviceCollection) {
                    this.collection.userDeviceCollection.isTurnedOn = isTurnedOn;
                } else {
                    this.collection.userDeviceCollection = { isTurnedOn: isTurnedOn };
                }
            });
        } catch (err) {
            console.log(err);
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        }
    }

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    @action.bound
    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }

    evaluateContentAccess(torahAnytimePodcast) {
        if (isNil(torahAnytimePodcast)) {
            return false;
        }

        return this.rootStore.contentAccessValidator.canAccessPodcast({
            isPremium: torahAnytimePodcast.isPremium,
            unavailableInMobileApp: torahAnytimePodcast.isVisibleInApp === false,
        });
    }
}

export default TorahAnytimeCollectionListItemViewStore;
