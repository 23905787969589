import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbEditFormLayout } from "mrb/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { CurrencyInputField } from "common/components";
import { currencyFormatter } from "mrb/common/formatting";
import { ProducerRevenueTransferSuccessModal } from "application/producer/components";
import { defaultTemplate } from "common/hoc";

function ProducerRevenueTransferTemplate({ producerRevenueTransferViewStore, t }) {
    const { form, currentAccountBalance, newAccountBalance, transferRevenueSuccessModal } =
        producerRevenueTransferViewStore;
    return (
        <MrbPage>
            <MrbPageHeader>
                <MrbRouterLink routeName="master.application.producer.preview.revenue">
                    <span className="display--ib u-mar--right--tny align--v--middle">
                        <i className="u-icon u-icon--med u-icon--back" />
                    </span>
                    Producer Account
                </MrbRouterLink>
            </MrbPageHeader>

            <div className="row">
                <div className="col col-sml-12 col-lrg-5">
                    <div className="c-card--primary c-card--med">
                        <MrbEditFormLayout form={form} submitLabel="Transfer to Stripe Connect" t={t}>
                            <CurrencyInputField
                                t={t}
                                allowNegativeValue={false}
                                classNameOverride="c-input c-input--outline c-input--numeric c-input--numeric--base u-width--300--max"
                                field={form.$("amount")}
                            />
                            <div className="row">
                                <div className="col col-sml-12 col-lrg-6">
                                    <p className="c-form__field__label">Current Account Balance</p>
                                    <div>
                                        {currencyFormatter.format(currentAccountBalance, {
                                            currency: "USD",
                                        })}
                                    </div>
                                </div>
                                <div className="col col-sml-12 col-lrg-6">
                                    <p className="c-form__field__label">New Account Balance</p>
                                    <div>
                                        {currencyFormatter.format(newAccountBalance, {
                                            currency: "USD",
                                        })}
                                    </div>
                                </div>
                            </div>
                        </MrbEditFormLayout>
                    </div>
                </div>
            </div>
            <ProducerRevenueTransferSuccessModal modalParams={transferRevenueSuccessModal} t={t} />
        </MrbPage>
    );
}
ProducerRevenueTransferTemplate.propTypes = {
    producerRevenueTransferViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(ProducerRevenueTransferTemplate);
