import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { map } from "lodash";
import { mergeCss } from "mrb/common/utils";

function TorahAnytimeWizardLayoutTemplate({ store, children, controlsRenderer, t }) {
    return (
        <div>
            <WizardHeader store={store} t={t} />
            {children}
            {controlsRenderer ? controlsRenderer({ store }) : <WizardControls store={store} t={t} />}
        </div>
    );
}

const WizardHeader = observer(function WizardHeader({ store, t }) {
    const { steps, currentStep, stepIndex } = store;
    return (
        <div className="c-stepper__wrapper">
            {map(steps, (step, i) => {
                return (
                    <WizardStep
                        key={step.id}
                        step={{ ...step, index: i }}
                        lastStep={steps.length - 1 === i}
                        stepIndex={stepIndex}
                        isActive={currentStep.id === step.id}
                        t={t}
                    />
                );
            })}
        </div>
    );
});

function WizardStep({ step, t, isActive, lastStep, stepIndex }) {
    return (
        <div className="c-stepper__step">
            <span
                className={mergeCss(
                    "c-stepper__title u-type--base",
                    isActive ? "active" : "",
                    step.index < stepIndex ? "u-type--color--primary" : ""
                )}
            >
                {t(step.title)}
            </span>
            {!lastStep && <span className="c-stepper__separator"></span>}
        </div>
    );
}

const WizardControls = observer(function WizardControls({ store, t }) {
    const { nextStep, currentStep, previousStep, hasPreviousStep } = store;
    return (
        <div>
            <div className="u-separator--primary"></div>
            <div
                className={mergeCss(
                    "u-display--flex",
                    `u-display--flex--jc--${hasPreviousStep ? "space-between" : "flex-end"}`,
                    "u-mar--top--med"
                )}
            >
                {hasPreviousStep && (
                    <MrbButton
                        label={currentStep.backButtonLabel ? currentStep.backButtonLabel : "Back"}
                        t={t}
                        classNameOverride="c-btn c-btn--base c-btn--ghost"
                        onClick={previousStep}
                    />
                )}
                <MrbButton
                    label={currentStep.nextButtonLabel ? currentStep.nextButtonLabel : "Continue"}
                    t={t}
                    onClick={nextStep}
                />
            </div>
        </div>
    );
});

TorahAnytimeWizardLayoutTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default TorahAnytimeWizardLayoutTemplate;
