import { LocalizedForm } from "common/localization";

export default class UserProfileEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "email",
                    label: "APPLICATION.USER_SETTINGS.EDIT.FIELDS.EMAIL_LABEL",
                    placeholder:
                        "APPLICATION.USER_SETTINGS.EDIT.FIELDS.EMAIL_PLACEHOLDER",
                    rules: "required|email|string",
                },
                {
                    name: "firstName",
                    label: "APPLICATION.USER_SETTINGS.EDIT.FIELDS.FIRST_NAME_LABEL",
                    placeholder:
                        "APPLICATION.USER_SETTINGS.EDIT.FIELDS.FIRST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "lastName",
                    label: "APPLICATION.USER_SETTINGS.EDIT.FIELDS.LAST_NAME_LABEL",
                    placeholder:
                        "APPLICATION.USER_SETTINGS.EDIT.FIELDS.LAST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
