import React from "react";
import { observer } from "mobx-react";
import { TakePaymentTemplate } from "themes/application/payment/pages";
import { TakePaymentViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";
import { stripeProvider } from "common/hoc";

@stripeProvider
@setCurrentView(
    (rootStore, componentProps) => new TakePaymentViewStore(rootStore, componentProps),
    "takePaymentViewStore"
)
@observer
class TakePayment extends React.Component {
    render() {
        return <TakePaymentTemplate {...this.props} />;
    }
}

export default TakePayment;
