import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const ProducerRevenueLoaderTemplate = (props) => {
    const loaderHeight = 110;
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y={0} rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y={26} rx="6" ry="6" width="30%" height="12" />

                <rect x="0" y={62} rx="6" ry="6" width="100%" height="46" />
            </ContentLoader>
        </div>
    );
};

export default ProducerRevenueLoaderTemplate;
