import React from "react";
import { HomePodcastGroupTemplate } from "themes/application/home/pages";
import { HomePodcastGroupViewStore } from "application/home/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new HomePodcastGroupViewStore(rootStore, componentProps),
    "homePodcastViewStore"
)
class HomePodcastGroup extends React.Component {
    render() {
        return <HomePodcastGroupTemplate {...this.props} />;
    }
}

export default HomePodcastGroup;
