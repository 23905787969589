import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const TorahAnyTimeLoaderTemplate = ({ row = 6, props, backgroundColor = "#3C3D3E", foregroundColor = "#404143" }) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    const screenwidth = window.innerWidth;

    const list = [];

    // prettier-ignore
    const cardSpace = screenwidth > 540 ?  188 : 148;
    const cardSize = screenwidth > 540 ? 140 : 100;

    let x = 0;
    let y1 = 0;

    let col;
    let setX;

    if (screenwidth > 988) {
        row = 5;
        col = Math.floor((screenwidth - 200) / 150);
        setX = Math.floor((screenwidth - 55) / col);
    } else if (screenwidth > 540) {
        col = Math.floor((screenwidth - 32) / 150);
        setX = Math.floor(screenwidth / col / col) + 136;
        row = 3;
    } else {
        col = Math.floor((screenwidth - 32) / 103);
        setX = Math.floor(screenwidth / col / col) + 86;
    }

    for (let i = 0; i < col; i++) {
        for (let j = 0; j < row; j++) {
            list.push(
                <React.Fragment key={`${i}_${j}`}>
                    {/* prettier-ignore */}
                    <rect x={x} y={y1} rx="6" ry="6" width={cardSize + 4} height={cardSize + 33} />
                </React.Fragment>
            );
            y1 += cardSpace;
        }
        y1 = 0;

        x += setX;
    }

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${row * cardSpace}`}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                {...props}
            >
                {list}
            </ContentLoader>
        </div>
    );
};

export default TorahAnyTimeLoaderTemplate;
