import React from "react";
import { PropTypes } from "prop-types";
import { ProducerPaymentReportSummary } from "administration/producer/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { ProducerReportLayout, ProducerReport } from "common/components/producer";
import { defaultTemplate } from "common/hoc";

function ProducerRevenueReportTemplate({ producerRevenueReportViewStore, t }) {
    return (
        <ProducerReportLayout
            producerReportViewStore={producerRevenueReportViewStore}
            backButtonRenderer={() => <BackButton producerId={producerRevenueReportViewStore.producerId} />}
            t={t}
        >
            <div className="c-container--sidebar u-padd--none">
                <ProducerReport producerReportViewStore={producerRevenueReportViewStore} t={t} />

                <div className="c-container--sml order-2">
                    <ProducerPaymentReportSummary
                        producerPaymentReportPreviewViewStore={producerRevenueReportViewStore}
                        t={t}
                    />
                </div>
            </div>
        </ProducerReportLayout>
    );
}

ProducerRevenueReportTemplate.propTypes = {
    producerRevenueReportViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function BackButton({ producerId }) {
    return (
        <MrbRouterLink routeName="master.application.producer.preview.revenue" queryParams={{ producerId }}>
            <span className="display--ib u-mar--right--tny align--v--middle">
                <i className="u-icon u-icon--med u-icon--back" />
            </span>
            Back
        </MrbRouterLink>
    );
}

export default defaultTemplate(ProducerRevenueReportTemplate);
