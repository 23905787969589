import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { HomePreviewStationsLoader, HomePreviewHeader } from "application/home/components";
import { HTMLRender, DescriptionLoader, PremiumBadge, Image } from "common/components";
import { StationUnavailableInApp } from "application/common/components";
import { dateFormatter } from "mrb/common/formatting";
import { mergeCss } from "mrb/common/utils";
import { applicationDefaults } from "common/constants";
import { defaultTemplate } from "common/hoc";

function HomeStationTemplate({ homeStationViewStore, t }) {
    const {
        loaderStore,
        item,
        coverImageUrl,
        rootStore: { premiumSubscriptionStore },
        contentAccessResult,
    } = homeStationViewStore;

    return (
        <MrbPreviewPage store={homeStationViewStore} t={t} contentClassNameExtend="u-padd--none">
            <MrbPageHeader>
                <HomePreviewHeader store={homeStationViewStore} t={t} />
            </MrbPageHeader>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div>
                                <div className="u-animation--fadeIn">
                                    <div
                                        className={mergeCss(
                                            "c-home__preview__header__icon",
                                            !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                                        )}
                                    >
                                        <Image src={coverImageUrl} />
                                        <PremiumBadge
                                            isPremium={item.isPremium}
                                            isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.HOME.STATION.FIELDS.TITLE")}
                                        </p>
                                        <p
                                            className={mergeCss(
                                                "c-content__header--primary__title",
                                                !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                                            )}
                                        >
                                            {item.title}
                                        </p>
                                        {!contentAccessResult.canAccessOnSelectedDevice && <StationUnavailableInApp />}
                                        {premiumSubscriptionStore.isLegacySubscriptionActive(
                                            item.legacySubscriptionExpirationDate
                                        ) && (
                                            <React.Fragment>
                                                <p className="u-type--sml u-type--color--text">
                                                    Old subscription until:{" "}
                                                    <span className="u-type--sml u-type--color--primary">
                                                        {dateFormatter.format(item.legacySubscriptionExpirationDate, {
                                                            format: applicationDefaults.formats.date,
                                                        })}
                                                    </span>
                                                </p>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="c-content__header--primary__descriprtion">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.HOME.STATION.FIELDS.DESCRIPTION_LINK")}
                                        </p>
                                        {item.descriptionLink && (
                                            <a href={item.descriptionLink} target="_blank" rel="noreferrer">
                                                {item.descriptionLink}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <StationInfo store={homeStationViewStore} item={item} t={t} />
        </MrbPreviewPage>
    );
}

HomeStationTemplate.propTypes = {
    homeStationViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function StationInfo({ store, item, t }) {
    const { loaderStore } = store;

    return (
        <div className="c-container--sidebar">
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DescriptionLoader />}>
                    {() => (
                        <div className="u-animation--fadeIn">
                            <div className="row">
                                <div className="col col-sml-12">
                                    <div className="u-type--base u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.HOME.STATION.FIELDS.DESCRIPTION")}
                                        </p>
                                        <HTMLRender className="c-collapse-panel__body__description">
                                            {item.alternativeDescription}
                                        </HTMLRender>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </MrbContentLoader>
            </div>

            <div>
                <div className="c-home__preview__sidebar">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <HomePreviewStationsLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="row">
                                    <div className="col col-sml-12">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("APPLICATION.HOME.STATION.FIELDS.LOCATION")}
                                            </p>
                                            <p className="u-type--base">{item.location && item.location.name}</p>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("APPLICATION.HOME.STATION.FIELDS.LANGUAGE")}
                                            </p>
                                            <p className="u-type--base">{item.language && item.language.name}</p>
                                        </div>
                                    </div>
                                    <div className="col col-sml-12">
                                        <div className="u-type--base">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("APPLICATION.HOME.STATION.FIELDS.GENRES")}
                                            </p>
                                            {item.genres}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
            </div>
        </div>
    );
}

export default defaultTemplate(HomeStationTemplate);
