import { observable, makeObservable } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";
import { nakiNigunContentType } from "application/naki-nigun/models";

class NakiNigunHomeContentFilter extends MrbFilterParameters {
    @observable contentType;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    reset() {
        super.reset();
        this.contentType = nakiNigunContentType.albums;
    }
}

export default NakiNigunHomeContentFilter;
