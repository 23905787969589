import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput } from "mrb/components/input";
import { MrbEditFormLayout } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { PasswordFieldInfo } from "common/components";
function UserProfilePasswordChangeTemplate({
    userProfilePasswordChangeViewStore,
    t,
}) {
    const { form } = userProfilePasswordChangeViewStore;
    return (
        <div>
            <MrbEditFormLayout form={form} t={t}>
                <div className="row">
                    <div className="col col-sml-12">
                        <MrbFieldInput
                            field={form.$("password")}
                            t={t}
                            classNameExtend="c-input--outline"
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="col col-sml-12">
                        <PasswordFieldInfo field={form.$("password")} />
                    </div>
                    <div className="col col-sml-12">
                        <MrbFieldInput
                            field={form.$("confirmPassword")}
                            t={t}
                            classNameExtend="c-input--outline"
                            autoComplete="new-password"
                        />
                    </div>
                </div>
            </MrbEditFormLayout>
        </div>
    );
}

UserProfilePasswordChangeTemplate.propTypes = {
    userProfilePasswordChangeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UserProfilePasswordChangeTemplate);
