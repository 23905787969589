import { override } from "mobx";
import { MrbBaseEditViewStore, httpStatusCodes } from "mrb/core";
import { localizationService } from "common/localization";
import { merge } from "lodash";
import { UserProfileEditForm } from "application/user-settings/forms";

class UserProfileEditViewStore extends MrbBaseEditViewStore {
    administratorRole = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            id: rootStore.userStore.user.id,
            name: "user",
            FormClass: UserProfileEditForm,
            autoFocusField: "firstName",
            successCreateNotification: null,
            successUpdateNotification: localizationService.t("APPLICATION.USER_SETTINGS.EDIT.SUCCESS_MESSAGE"),
            redirectOnUpdateSuccess: false
        });
        this.routeStore = routeStore;
    }

    get() {
        return this.routeStore.getResource();
    }

    update(data) {
        const resource = merge({}, this.item);
        resource.firstName = data.firstName;
        resource.lastName = data.lastName;
        resource.email = data.email;
        resource.roles = null;

        return this.routeStore.update(resource);
    }

    @override
    updateForm(values) {
        this.form.set({
            firstName: values.userProfile && values.userProfile.firstName,
            lastName: values.userProfile && values.userProfile.lastName,
            email: values.email,
        });
    }

    onUpdateError(error) {
        if (
            error.statusCode &&
            error.statusCode === httpStatusCodes.BadRequest
        ) {
            this.form
                .$("email")
                .invalidate(
                    "User with specified E-mail address already exists."
                );
        } else {
            this.rootStore.notificationStore.error(
                localizationService.t(
                    "APPLICATION.USER_SETTINGS.EDIT.ERROR.GENERIC_MESSAGE"
                ),
                error
            );
        }
    }
}

export default UserProfileEditViewStore;
