import { LocalizedForm } from "common/localization";

export default class DeviceCreateForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "deviceTemporaryCode",
                    label: "APPLICATION.DEVICE_SETTINGS.CREATE.FIELDS.DEVICE_ID_LABEL",
                    placeholder: "APPLICATION.DEVICE_SETTINGS.CREATE.FIELDS.DEVICE_ID_PLACEHOLDER",
                    rules: "required|string",
                    extra: {
                        infoLabel: "Where can I find my Device ID?",
                    },
                },
                {
                    name: "deviceName",
                    label: "APPLICATION.DEVICE_SETTINGS.CREATE.FIELDS.DEVICE_NAME_LABEL",
                    placeholder: "APPLICATION.DEVICE_SETTINGS.CREATE.FIELDS.DEVICE_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
