import { computed, observable, action, makeObservable, toJS } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";
import {
    TorahAnytimeCreatePlaylist,
    TorahAnytimeSelectSpeakers,
    TorahAnytimeSelectTopics,
} from "application/torah-anytime/components";
import { TorahAnytimeSelectionViewStore } from "application/torah-anytime/stores";
import { forEach, map } from "lodash";

class TorahAnytimeWizardViewStore extends MrbBaseViewStore {
    collectionId = null;
    podcastId = null;
    steps = [
        {
            title: "APPLICATION.TORAH_ANYTIME.EDIT.SELECT_TOPICS.STEP_TITLE",
            id: "topics",
            component: TorahAnytimeSelectTopics,
        },
        {
            title: "APPLICATION.TORAH_ANYTIME.EDIT.SELECT_SPEAKERS.STEP_TITLE",
            id: "speakers",
            component: TorahAnytimeSelectSpeakers,
        },
        {
            title: "APPLICATION.TORAH_ANYTIME.EDIT.CREATE_PLAYLIST.STEP_TITLE",
            id: "playlist",
            component: TorahAnytimeCreatePlaylist,
        },
    ];

    @observable selectedSpeakers = [];
    @observable selectedTopics = [];
    @observable item = null;
    @observable torahAnytimePodcast = null;
    @observable selectedTopicsSpeakerIds = [];

    get maximumNumberOfSteps() {
        return this.steps.length;
    }

    @observable stepIndex = 0;

    @computed get currentStep() {
        if (this.steps[this.stepIndex].id === "speakers") {
            const topicIds = map(this.torahAnytimeSelectionViewStore.selectedTopics, (topic) => topic.topicId);
            const resource = { topicIds: topicIds };
            this.selectedTopicsSpeakerIds = this.routeStore.findSpeakerIdsByTopicIds(resource);
        }
        return this.steps[this.stepIndex];
    }

    @computed get hasNextStep() {
        return this.stepIndex < this.maximumNumberOfSteps - 1;
    }

    @computed get hasPreviousStep() {
        return this.stepIndex > 0;
    }

    constructor(rootStore, { routeStore }) {
        const collectionId = rootStore.routerStore.routerState.params.collectionId;
        const podcastId = rootStore.routerStore.routerState.params.id;
        super(rootStore);
        makeObservable(this);
        this.collectionId = collectionId;
        this.podcastId = podcastId;
        this.routeStore = routeStore;
        this.torahAnytimeSelectionViewStore = new TorahAnytimeSelectionViewStore(rootStore);
    }

    onInit() {
        return Promise.all([this.initializeResource(), this.getTorahAnytimePodcast(this.podcastId)]);
    }

    @action.bound
    nextStep() {
        if (this.hasNextStep) {
            this.goToStep(this.stepIndex + 1);
        }
    }

    @action.bound
    previousStep() {
        if (this.hasPreviousStep) {
            this.goToStep(this.stepIndex - 1);
        }
    }

    @action.bound
    goToStep(step) {
        this.stepIndex = step;
    }

    @action.bound
    createCollection(data) {
        const speakers = toJS(this.torahAnytimeSelectionViewStore.selectedSpeakers);
        const topics = toJS(this.torahAnytimeSelectionViewStore.selectedTopics);
        const newCollection = this.prepareCollectionResource(data.name, topics, speakers);
        return this.routeStore.createUserTorahAnytimeCollection(newCollection);
    }

    @action.bound
    updateCollection(data) {
        const speakers = toJS(this.torahAnytimeSelectionViewStore.selectedSpeakers);
        const topics = toJS(this.torahAnytimeSelectionViewStore.selectedTopics);
        const newCollection = this.prepareCollectionResource(data.name, topics, speakers);
        newCollection.id = this.item.id;
        return this.routeStore.updateUserTorahAnytimeCollection(newCollection);
    }

    async initializeResource() {
        if (this.collectionId) {
            try {
                await this.getResource(this.collectionId);
                return true;
            } catch (err) {
                if (err.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw err;
                }
            }
        }
    }

    async getResource(id) {
        const item = await this.routeStore.getUserTorahAnytimeCollection(id);
        this.setItem(item);
    }

    @action.bound
    setItem(item) {
        this.item = item;
        this.torahAnytimeSelectionViewStore.initializeSelection(this.item.topics, this.item.speakers);
    }

    @action.bound
    async getTorahAnytimePodcast(podcastId) {
        if (podcastId) {
            try {
                const torahAnytimePodcast = await this.routeStore.getPodcast(podcastId);
                this.setTorahAnytimePodcast(torahAnytimePodcast);
            } catch (err) {
                if (err.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw err;
                }
            }
        }
    }

    @action.bound
    setTorahAnytimePodcast(podcast) {
        this.torahAnytimePodcast = podcast;
    }

    prepareCollectionResource(name, selectedTopics, selectedSpeakers) {
        if (selectedSpeakers) {
            forEach(selectedSpeakers, (speaker) => {
                speaker.speaker = null;
            });
        }
        if (selectedTopics) {
            forEach(selectedTopics, (topic) => {
                topic.topic = null;
            });
        }
        const collection = {
            name: name,
            podcastId: this.podcastId,
            speakers: selectedSpeakers,
            topics: selectedTopics,
        };

        return collection;
    }
}

export default TorahAnytimeWizardViewStore;
