import React from "react";
import { HomeTemplate } from "themes/application/home/pages";
import { HomeViewStore } from "application/home/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new HomeViewStore(rootStore, componentProps),
    "homeViewStore"
)
class Home extends React.Component {
    render() {
        return <HomeTemplate {...this.props} />;
    }
}

export default Home;
