import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { ProducerPreviewReportPeriodCell, ProducerPreviewReportPlaybackTimeCell } from "common/components/producer";
import { numberFormatter } from "common/utils";
import { MrbQueryUtility } from "mrb/core/filter";
import { isNil } from "lodash";
import moment from "moment";

class ProducerRevenueViewStore extends MrbBaseViewStore {
    range = null;
    @observable.ref summaryData = null;
    @observable reportsGridStore = null;
    @observable revenueGridStore = null;
    @observable balanceGridStore = null;
    reportsQueryUtility = null;
    revenueQueryUtility = null;
    balanceQueryUtility = null;
    @observable producers = null;
    @observable selectedProducerId = null;
    @observable completedOnboarding = false;
    @observable.ref onboardingStatus = null;
    @observable stripeAccountLink;
    @observable isCreateStripeAccountButtonDisabled = false;

    producerId = null;

    @computed get isStripeAccountConnected() {
        if (this.summaryData) {
            return !isNil(this.summaryData.stripeAccountId);
        }
        return false;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.producerId = this.rootStore.routerStore.routerState.queryParams.producerId;

        this.revenueQueryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.revenueFetchResources(filter);
            },
            {
                orderBy: "dateCreated",
                orderDirection: "desc",
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
            }
        );

        this.balanceQueryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.balanceFetchResources(filter);
            },
            {
                orderBy: "dateCreated",
                orderDirection: "desc",
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
            }
        );

        this.reportsQueryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.reportsFetchResources(filter);
            },
            {
                orderBy: "monthOfYear",
                orderDirection: "desc",
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
            }
        );
        this.revenueFetchResources = this.revenueFetchResources.bind(this);
        this.balanceFetchResources = this.balanceFetchResources.bind(this);

        const from = moment.utc().subtract(1, "year").startOf("month").format(applicationDefaults.formats.isoDate);
        const to = moment.utc().subtract(1, "month").startOf("month").format(applicationDefaults.formats.isoDate);
        this.range = {
            from: from,
            to: to,
        };

        this.setReportsGridStore(
            new MrbTableViewStore(this.rootStore, this.reportsQueryUtility, {
                onRowClick: (node) => {
                    const monthOfYear = dateFormatter.format(node.data.monthOfYear, {
                        format: applicationDefaults.formats.isoDate,
                        timeZone: "utc",
                    });
                    this.rootStore.routerStore.goTo(
                        "master.application.producer.report",
                        {
                            id: this.selectedProducerId,
                        },
                        {
                            monthOfYear: monthOfYear,
                        }
                    );
                },
                columns: [
                    {
                        key: "monthOfYear",
                        title: "Period",
                        sortable: true,
                        cell: ProducerPreviewReportPeriodCell,
                    },
                    {
                        key: "totalProducerPlaybacks",
                        title: "Playback Time",
                        sortable: true,
                        cell: ProducerPreviewReportPlaybackTimeCell,
                    },
                    {
                        key: "totalProducerPlaybacks",
                        title: "Playback Points",
                        format: (value) => numberFormatter.format(value),
                    },
                    {
                        key: "totalProducerPayment",
                        title: "Revenue",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                ],
            })
        );

        this.setRevenueGridStore(
            new MrbTableViewStore(this.rootStore, this.revenueQueryUtility, {
                columns: [
                    {
                        key: "dateCreated",
                        title: "Date",
                        sortable: true,
                        format: (value) =>
                            dateFormatter.format(value, {
                                format: applicationDefaults.formats.dateTime,
                            }),
                    },
                    {
                        key: "stripeTransactionId",
                        title: "Stripe Transaction Id",
                    },
                    {
                        key: "amount",
                        title: "Amount",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                ],
            })
        );

        this.setBalanceGridStore(
            new MrbTableViewStore(this.rootStore, this.balanceQueryUtility, {
                columns: [
                    {
                        key: "dateCreated",
                        title: "Date",
                        sortable: true,
                        format: (value) =>
                            dateFormatter.format(value, {
                                format: applicationDefaults.formats.dateTime,
                            }),
                    },
                    {
                        key: "notes",
                        title: "Notes",
                    },
                    {
                        key: "amount",
                        title: "Amount",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },

                        cellClass: (item) => {
                            if (item.value > 0) {
                                return "u-type--color--success";
                            } else if (item.value < 0) {
                                return "u-type--color--warning";
                            }
                        },
                    },
                    {
                        key: "producer.name",
                        title: "Producer",
                    },
                ],
            })
        );
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    async reportsFetchResources(filter) {
        this.reportsGridStore.suspend();
        try {
            filter.producerId = this.selectedProducerId;
            const response = await this.fetchReportsData(filter);
            runInAction(() => {
                this.reportsGridStore.setData(response);
                this.reportsGridStore.resume();
            });
        } catch (err) {
            this.reportsGridStore.resume();
            this.onFetchError(err);
        }
    }

    async revenueFetchResources(filter) {
        this.revenueGridStore.suspend();
        try {
            const response = await this.fetchRevenueData(filter);
            runInAction(() => {
                this.revenueGridStore.setData(response);
                this.revenueGridStore.resume();
            });
        } catch (err) {
            this.revenueGridStore.resume();
            this.onFetchError(err);
        }
    }

    async balanceFetchResources(filter) {
        this.balanceGridStore.suspend();
        try {
            const response = await this.fetchBalanceData(filter);
            runInAction(() => {
                this.balanceGridStore.setData(response);
                this.balanceGridStore.resume();
            });
        } catch (err) {
            this.balanceGridStore.resume();
            this.onFetchError(err);
        }
    }

    async fetchReportsData(filter) {
        try {
            return this.routeStore.findProducerReports(filter);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    async fetchRevenueData(filter) {
        try {
            return await this.routeStore.getProducerAccountRevenueTransaction(filter);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    async fetchBalanceData(filter) {
        try {
            return await this.routeStore.getProducerAccountBalance(filter);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    async onInit() {
        await Promise.all([
            this.fetchProducers(),
            this.fetchAccountSummaryData(),
            this.revenueQueryUtility.initialize(),
            this.balanceQueryUtility.initialize(),
        ]);
    }

    @action.bound
    setStripeAccountOnboardingStatus(data) {
        this.onboardingStatus = data;
        this.setCompletedOnboarding(data.chargesEnabled && data.detailsSubmitted);
    }

    @action.bound
    setCompletedOnboarding(state) {
        this.completedOnboarding = state;
    }

    @action.bound
    setStripeAccountLink(data) {
        this.stripeAccountLink = data;
    }

    @action.bound
    setSummaryData(data) {
        this.summaryData = data;
    }

    @action.bound
    setReportsGridStore(reportsGridStore) {
        this.reportsGridStore = reportsGridStore;
    }

    @action.bound
    setRevenueGridStore(revenueGridStore) {
        this.revenueGridStore = revenueGridStore;
    }

    @action.bound
    setBalanceGridStore(balanceGridStore) {
        this.balanceGridStore = balanceGridStore;
    }

    @action.bound
    setProducers(producers) {
        this.producers = producers;
    }

    @action.bound
    setIsCreateStripeAccountButtonDisabled(state) {
        this.isCreateStripeAccountButtonDisabled = state;
    }

    @action.bound
    async selectProducer(producerId) {
        this.selectedProducerId = producerId;
        await this.reportsQueryUtility.initialize();
    }

    @action.bound
    async createStripeAccount() {
        try {
            this.setIsCreateStripeAccountButtonDisabled(true);
            const resource = { returnUrl: this.generateCurrentUrl(), refreshUrl: this.generateCurrentUrl() };
            const data = await this.routeStore.createStripeConnectLink(resource);
            this.setStripeAccountLink(data);

            await this.fetchAccountSummaryData();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    async fetchAccountSummaryData() {
        try {
            const data = await this.routeStore.getProducerAccountSummary();
            this.setSummaryData(data);
            if (this.isStripeAccountConnected) {
                await this.fetchStripeAccountOnboardingStatus();

                if (isNil(this.stripeAccountLink) && !this.completedOnboarding) {
                    await this.fetchStripeAccountLink();
                }
            }
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    async fetchStripeAccountOnboardingStatus() {
        try {
            const data = await this.routeStore.getStripeConnectOnboardingStatus();
            this.setStripeAccountOnboardingStatus(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    async fetchStripeAccountLink() {
        try {
            const resource = { returnUrl: this.generateCurrentUrl(), refreshUrl: this.generateCurrentUrl() };
            const data = await this.routeStore.createStripeConnectLink(resource);
            this.setStripeAccountLink(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    async fetchProducers() {
        try {
            const producers = await this.routeStore.getProducers();
            this.setProducers(producers);
            if (!isNil(this.producerId)) {
                this.selectProducer(this.producerId);
            } else {
                this.selectProducer(producers[0].id);
            }
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    generateCurrentUrl() {
        return `${window.location.origin}/producer/revenue`;
    }
}

export default ProducerRevenueViewStore;
