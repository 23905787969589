import React from "react";
import { PropTypes } from "prop-types";
import {
    DeviceDowntimeEdit,
    DeviceDowntimeLoader,
    DeviceEditPageHeader,
    DowntimeNoteLoader,
} from "application/device-settings/components";
import { MrbFieldInput } from "mrb/components/input";
import { MrbEditPage } from "mrb/components/layout";
import { MrbContentLoader, MrbFormFieldContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import { MrbButton, MrbPageHeader } from "baasic-react-bootstrap/src";
function DeviceEditTemplate({ deviceEditViewStore, t = (i) => i }) {
    const {
        form,
        deviceDowntimeEditViewStore,
        loaderStore,
        navigateBack,
        cancelLabel,
        submitLabel,
        onClickCancel,
        title,
        layoutActions,
        navigationOptions,
        isDowntimeDisabled,
    } = deviceEditViewStore;

    return (
        <MrbEditPage store={deviceEditViewStore} t={t} submitLabel="Update Device">
            <MrbPageHeader>
                <DeviceEditPageHeader
                    form={form}
                    onClickCancel={onClickCancel}
                    title={title}
                    layoutActions={layoutActions}
                    navigateBack={navigateBack}
                    navigationOptions={navigationOptions}
                    submitLabel={submitLabel}
                    cancelLabel={cancelLabel}
                    store={deviceEditViewStore}
                    t={t}
                />
            </MrbPageHeader>
            <div className="c-container--sidebar c-container--sidebar--secondary u-padd--none u-mar--bottom--med">
                <div className="c-card c-card--primary c-card--med">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbFormFieldContentLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("deviceName")} />
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
                {!isDowntimeDisabled && (
                    <div className="c-card c-card--primary c-card--med">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <MrbFormFieldContentLoader col={3} />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="c-form__field__label">Presets</div>
                                    <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--wrap">
                                        <div className="u-display--flex">
                                            <MrbButton
                                                classNameExtend="u-mar--right--tny u-mar--bottom--tny"
                                                label="Nights off"
                                                onClick={deviceDowntimeEditViewStore.disableNight}
                                            ></MrbButton>
                                            <MrbButton
                                                classNameExtend="u-mar--right--tny u-mar--bottom--tny"
                                                label="Shabbos"
                                                onClick={deviceDowntimeEditViewStore.disableShabbos}
                                            ></MrbButton>
                                        </div>
                                        <div className="u-display--flex">
                                            <MrbButton
                                                classNameOverride="c-btn c-btn--base c-btn--ghost u-mar--bottom--tny"
                                                label="Reset"
                                                onClick={deviceDowntimeEditViewStore.reset}
                                            ></MrbButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                )}
                {!isDowntimeDisabled && (
                    <div
                        className={`c-card c-card--${
                            loaderStore.loading || loaderStore._initial ? "primary" : "message c-card--message--info"
                        }`}
                    >
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <DowntimeNoteLoader fieldWidth={380} />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    Downtime is an option that enables you to lock the device at a certain time.
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                )}
            </div>
            {!isDowntimeDisabled && (
                <div className="c-card--primary c-card--med u-overflow--hidden">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DeviceDowntimeLoader />}>
                        {() => <DeviceDowntimeEdit store={deviceDowntimeEditViewStore} t={t} />}
                    </MrbContentLoader>
                </div>
            )}
        </MrbEditPage>
    );
}

DeviceEditTemplate.propTypes = {
    deviceEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(DeviceEditTemplate);
