import React from "react";
import { PropTypes } from "prop-types";
import { MrbContentLoader } from "mrb/components/loader";
import { HomeListLoader } from "application/home/components";
import { NakiNigunHomeGridCell } from "application/naki-nigun/components";
import { map } from "lodash";
import { observer } from "mobx-react";

function NakiNigunHomeFeaturedTemplate({
    nakiNigunHomeFeaturedContentViewStore,
    nakiNigunManageSelectedItemsViewStore,
    t,
}) {
    const {
        items,
        loaderStore,
        isFeaturedScrollable,
        scrollFeaturedLeft,
        scrollFeaturedRight,
        setFeaturedContainerRef,
        onClickCellItem,
        goTo,
        rootStore: { premiumSubscriptionStore },
    } = nakiNigunHomeFeaturedContentViewStore;
    const { isBulk } = nakiNigunManageSelectedItemsViewStore;

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <HomeListLoader row={1} bulk={isBulk} title />}>
            {() => (
                <React.Fragment>
                    {items.length > 0 && (
                        <div>
                            <div className="c-home__list__header">
                                <div className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                                    {t("APPLICATION.NAKI_NIGUN.LIST.FEATURED")}
                                </div>
                                {isFeaturedScrollable && (
                                    <div className="u-display--flex">
                                        <i
                                            className="u-icon u-icon--base u-icon--arrow-left u-mar--right--tny"
                                            onClick={scrollFeaturedLeft}
                                        />
                                        <i
                                            className="u-icon u-icon--base u-icon--arrow-right"
                                            onClick={scrollFeaturedRight}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="u-animation--fadeIn">
                                <div className="c-home__list--scroll" ref={setFeaturedContainerRef}>
                                    {map(items, (item) => (
                                        <NakiNigunHomeGridCell
                                            goTo={goTo}
                                            key={item.albumId || item.artistId}
                                            premiumSubscriptionStore={premiumSubscriptionStore}
                                            nakiNigunManageSelectedItemsViewStore={
                                                nakiNigunManageSelectedItemsViewStore
                                            }
                                            item={item}
                                            onCellClick={onClickCellItem}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
}

NakiNigunHomeFeaturedTemplate.propTypes = {
    nakiNigunHomeFeaturedContentViewStore: PropTypes.object.isRequired,
    nakiNigunManageSelectedItemsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunHomeFeaturedTemplate);
