import React from "react";
import { ProducerRevenueReportTemplate } from "themes/application/producer/pages";
import { ProducerRevenueReportViewStore } from "application/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ProducerRevenueReportViewStore(rootStore, componentProps),
    "producerRevenueReportViewStore"
)
class ProducerRevenueReport extends React.Component {
    render() {
        return <ProducerRevenueReportTemplate {...this.props} />;
    }
}

export default ProducerRevenueReport;
