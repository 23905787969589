import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunUserContentService } from "common/services";

class NakiNigunHomeRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.service = this.rootStore.createApplicationService(NakiNigunUserContentService);
    }

    async findArtists(params) {
        const response = await this.service.findArtists(params);
        return response.data;
    }

    async findAlbums(params) {
        const response = await this.service.findAlbums(params);
        return response.data;
    }

    async genericSearch(params, abortController) {
        const response = await this.service.genericSearch(params, abortController);
        return response.data;
    }

    async getFeaturedArtists(deviceId) {
        const response = await this.service.getFeaturedArtists(deviceId);
        return response.data;
    }

    async getFeaturedAlbums(deviceId) {
        const response = await this.service.getFeaturedAlbums(deviceId);
        return response.data;
    }
}

export default NakiNigunHomeRouteStore;
