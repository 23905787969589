import { MrbFilterParameters } from "mrb/core/filter";
import { calculateNumberOfColumns, calculateNumberOfTorahAnytimeWizardRows } from "common/utils";

class TorahAnytimeSpeakersFilter extends MrbFilterParameters {
    constructor() {
        super();
        this.reset();
    }

    reset() {
        super.reset();
        this.pageSize =
            calculateNumberOfColumns(window.innerWidth) * calculateNumberOfTorahAnytimeWizardRows(window.innerWidth);
    }
}

export default TorahAnytimeSpeakersFilter;
