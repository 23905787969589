import React from "react";
import { PropTypes } from "prop-types";
import { MrbListPage, MrbPageHeader } from "mrb/components/layout";
import { MrbGrid } from "mrb/components/grid";
import { MrbRectangleContentLoader } from "mrb/components/loader";
import { MrbButton } from "mrb/components/button";
import { DeviceGridCell } from "application/device-settings/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function DeviceListTemplate({ deviceListViewStore, t }) {
    const { gridStore } = deviceListViewStore;

    return (
        <MrbListPage store={deviceListViewStore} t={t}>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <div>{t(deviceListViewStore.title)}</div>
                    <HeaderActions store={deviceListViewStore} />
                </div>
            </MrbPageHeader>
            <h1 className="u-type--base u-mar--bottom--sml">
                {t("APPLICATION.DEVICE_SETTINGS.LIST.TITLE")}{" "}
                <DeviceCounter deviceListViewStore={deviceListViewStore} t={t} />
            </h1>
            <MrbGrid
                store={gridStore}
                t={t}
                emptyStateRender={deviceEmptyStateRender}
                cellRender={(props) => <DeviceGridCell {...props} />}
                loaderRender={() => <MrbRectangleContentLoader spacing={30} xBase={0} yBase={0} />}
            />
        </MrbListPage>
    );
}

DeviceListTemplate.propTypes = {
    deviceListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function deviceEmptyStateRender() {
    return (
        <div className="c-card c-card--primary u-width--100 u-type--center">
            <div className="c-empty-state u-type--center">
                <i className="u-icon u-icon--xxlrg u-icon--empty-state" />
                <h3 className="c-empty-state__title">Ooops! There is no device</h3>
                <p className="c-empty-state__desc">Please, add your device to start using NakiRadio.</p>
            </div>
        </div>
    );
}

const DeviceCounter = observer(function DeviceCounter({ deviceListViewStore, t }) {
    const { gridStore, deviceLimit } = deviceListViewStore;

    if (!gridStore.dataInitialized) {
        return null;
    }

    if (isNil(deviceLimit)) {
        return (
            <React.Fragment>
                ({gridStore.recordCount}/{t("APPLICATION.DEVICE_SETTINGS.LIST.UNLIMITED")})
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            ({gridStore.recordCount}/{deviceLimit})
        </React.Fragment>
    );
});

const HeaderActions = observer(function HeaderActions({ store }) {
    return (
        <div className="c-header__actions--end">
            <MrbButton label="Add New Device" onClick={store.goToAddNewDevice} disabled={!store.isAddDeviceEnabled} />
        </div>
    );
});

export default defaultTemplate(DeviceListTemplate);
