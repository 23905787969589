import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { MrbSimpleSelectStore } from "mrb/components/select";
import { MrbModalParams } from "mrb/common/models";
import { TorahAnytimeCollectionGridItemDecorator } from "application/torah-anytime/models";

class TorahAnytimeViewStore extends MrbBaseListViewStore {
    id = null;
    @observable torahAnytimePodcast;

    @computed get selectedDevice() {
        return this.rootStore.userDeviceStore.selectedDevice;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            queryConfig: {
                embed: "speakers,speakers.speaker,topics,topics.topic",
                orderBy: "name",
                orderDirection: "asc",
            },
        });
        makeObservable(this);
        this.id = id;
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.goToWizardPage(item),
                actions: {
                    edit: {
                        onClick: (item) => this.goToWizardPage(item),
                    },
                    delete: {
                        onClick: (item) => this.delete(item),
                    },
                },
                mapper: (item) => {
                    return new TorahAnytimeCollectionGridItemDecorator({
                        ...item,
                    });
                },
            })
        );

        this.deviceSelectStore = new MrbSimpleSelectStore(
            {
                textKey: "deviceName",
                valueKey: "id",
                actions: {
                    onChange: (option) => {
                        this.rootStore.userDeviceStore.setSelectedDevice(option.item);
                    },
                },
            },
            this.rootStore.userDeviceStore.devices
        );
        this.deviceSelectStore.setSelectedItem(this.rootStore.userDeviceStore.selectedDevice);

        this.goToCreateNewCollectionPage = this.goToCreateNewCollectionPage.bind(this);
        this.displayTorahAnytimeAvailabilityModal = this.displayTorahAnytimeAvailabilityModal.bind(this);
        this.disableTorahAnytimeAvailability = this.disableTorahAnytimeAvailability.bind(this);

        this.torahAnytimeAvailabilityModal = new MrbModalParams(
            {
                onClose: () => {},
            },
            {
                disableTorahAnytimeAvailabilityModal: this.disableTorahAnytimeAvailability,
            }
        );

        this.reaction(
            () => this.selectedDevice,
            () => {
                this.queryUtility.fetch();
            }
        );
    }

    onInit() {
        this.displayTorahAnytimeAvailabilityModal();
        return Promise.all([this.queryUtility.initialize(), this.fetchTorahAnytimePodcast(this.id)]);
    }

    find(params) {
        params.podcastId = this.id;
        return this.routeStore.findUserTorahAnytimeCollections(this.selectedDevice.id, params);
    }

    delete = (item) => {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to delete playlist?",
            onConfirm: async () => {
                try {
                    await this.routeStore.deleteUserTorahAnytimeCollection(item);
                    this.queryUtility.fetch();
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred. Unable to delete playlist.",
                        err
                    );
                }
            },
            yesLabel: "Delete",
            noLabel: "Cancel",
            description: "You won't be able to revert this action.",
        });
    };

    goToCreateNewCollectionPage() {
        this.rootStore.routerStore.goTo("master.application.home.torah-anytime.create", { id: this.id });
    }

    goToWizardPage = (item) => {
        this.rootStore.routerStore.goTo("master.application.home.torah-anytime.edit", {
            id: this.id,
            collectionId: item.id,
        });
    };

    async fetchTorahAnytimePodcast(id) {
        try {
            const data = await this.routeStore.getPodcast(id);
            this.setTorahAnytimePodcast(data);
        } catch (err) {
            console.log(err);
        }
    }

    displayTorahAnytimeAvailabilityModal() {
        if (this.rootStore.userStore.isTorahAnytimeAvailabilityVisible) {
            this.torahAnytimeAvailabilityModal.open();
        }
    }

    async disableTorahAnytimeAvailability() {
        try {
            this.torahAnytimeAvailabilityModal.close();
            this.rootStore.mainLoaderStore.suspend();
            await this.rootStore.userStore.disableTorahAnytimeAvailability();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update user settings.",
                err
            );
        } finally {
            this.rootStore.mainLoaderStore.resume();
        }
    }

    @action.bound
    setTorahAnytimePodcast(item) {
        this.torahAnytimePodcast = item;
    }
}

export default TorahAnytimeViewStore;
