import { action, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";
import { MrbGridViewStore } from "mrb/components/grid";
import { MrbSimpleSelectStore } from "mrb/components/select";
import { sideScroll } from "common/utils";
import { find, filter, map, isNil, uniqBy } from "lodash";

class NakiNigunGenericSearchViewStore extends MrbBaseListViewStore {
    @observable showSearchView = false;
    @observable.ref artists = [];
    @observable isAppearsInScrollable = false;
    @observable.ref appearsInContainerRef = null;
    _isInitialLoad = true;

    _abortController = null;

    orderOptions = [
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.GENERIC_SEARCH.ORDER.RELEVANCE_OPTION",
            value: "-1",
        },
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.GENERIC_SEARCH.ORDER.RECENTLY_ADDED_OPTION",
            value: "dateCreated",
        },
        {
            label: "APPLICATION.NAKI_NIGUN.LIST.GENERIC_SEARCH.ORDER.ALPHABETICALY_OPTION",
            value: "title",
        },
    ];

    constructor(rootStore, nakiNigunHomeViewStore) {
        super(rootStore, {
            queryConfig: {
                disableUpdateQueryParams: true,
                pageSize: 30,
            },
        });
        makeObservable(this);
        this.nakiNigunHomeViewStore = nakiNigunHomeViewStore;
        this.routeStore = this.nakiNigunHomeViewStore.routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
                generateKey: (item) => item.albumId,
                mapper: (item) => {
                    return {
                        ...item,
                        coverImageUrl: this.createAlbumCoverImageUrl(item),
                    };
                },
            })
        );

        this.orderBySelectStore = new MrbSimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                actions: {
                    onChange: (selectedOrder) => {
                        if (this.queryUtility.filter.orderBy !== selectedOrder.value) {
                            if (selectedOrder.value === "-1") {
                                this.queryUtility.filter.orderBy = null;
                                this.queryUtility.filter.orderDirection = null;
                            } else {
                                this.queryUtility.filter.orderBy = selectedOrder.value;
                                if (selectedOrder.value === "title") {
                                    this.queryUtility.filter.orderDirection = "asc";
                                } else {
                                    this.queryUtility.filter.orderDirection = "desc";
                                }
                            }
                            this.queryUtility.fetch();
                        }
                    },
                },
            },
            this.orderOptions
        );

        this.initializeSortingDefault();

        this.reaction(
            () => (this.queryUtility.filter.search || "").trim(),
            (searchPhrase) => {
                if (searchPhrase) {
                    this.queryUtility.fetch();
                } else {
                    this.reset();
                }
            },
            {
                delay: 300,
            }
        );

        this.reaction(
            () => ({
                artists: this.artists,
                container: this.appearsInContainerRef,
            }),
            (context) => {
                if (context.container) {
                    setTimeout(() => {
                        runInAction(() => {
                            this.isAppearsInScrollable = context.container.scrollWidth > context.container.clientWidth;
                        });
                    });
                }
            }
        );
    }

    async fetchResources(filter) {
        if (filter.search) {
            this.abortExistingRequest();
            this.setShowSearchView(true);
            let delay = null;
            if (this._isInitialLoad) {
                delay = 0;
            }
            this.gridStore.suspend(delay);
            try {
                const response = await this.find(filter);
                runInAction(() => {
                    this.gridStore.setData(response);
                    this.setArtists(response);
                    this.gridStore.resume();
                });
            } catch (err) {
                runInAction(() => {
                    this.gridStore.resume();
                });
                this.onFetchError(err);
            } finally {
                this._isInitialLoad = false;
            }
        } else {
            this.reset();
        }
    }

    @action.bound
    setArtists(response) {
        const data = response.item ? response.item : response;
        const artists = map(
            uniqBy(
                filter(data, (item) => !isNil(item.artistId)),
                "artistId"
            ),
            (item) => {
                return {
                    artistId: item.artistId,
                    name: item.artistName,
                    isArtistPremium: item.isArtistPremium,
                    coverImageUrl: this.createArtistCoverImageUrl(item.artistCoverImageId),
                };
            }
        );
        this.artists = artists;
    }

    initializeSortingDefault() {
        if (this.queryUtility.filter.orderBy) {
            this.orderBySelectStore.setSelectedItem(
                find(this.orderOptions, (orderOption) => orderOption.value === this.queryUtility.filter.orderBy)
            );
        } else {
            this.orderBySelectStore.setSelectedItem(this.orderOptions[0]);
        }
    }

    find(params) {
        this._abortController = new AbortController();
        return this.routeStore.genericSearch(params, this._abortController);
    }

    onClickCellItem = (item) => {
        this.rootStore.routerStore.goTo(
            "master.application.naki-nigun.artist",
            {
                id: item.artistId,
            },
            { albumId: item.albumId }
        );
    };

    createAlbumCoverImageUrl(item) {
        if (item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(item.coverImageId, 200, 200);
        }
        return null;
    }

    createArtistCoverImageUrl(artistCoverImageId) {
        if (artistCoverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(artistCoverImageId, 200, 200);
        }
        return null;
    }

    onFetchError(error) {
        if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
            return;
        }
        console.log(error);
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    @action.bound
    setShowSearchView(value) {
        this.showSearchView = value;
    }

    @action.bound
    reset() {
        this.setShowSearchView(false);
        this.abortExistingRequest();
        this.gridStore.setData([]);
        this.setArtists([]);
        this._isInitialLoad = true;
    }

    @action.bound
    scrollFeaturedLeft() {
        sideScroll(this.appearsInContainerRef, "left", 20, 400, 20);
    }

    @action.bound
    scrollFeaturedRight() {
        sideScroll(this.appearsInContainerRef, "right", 20, 400, 20);
    }

    abortExistingRequest() {
        if (this._abortController) {
            this._abortController.abort();
        }
    }

    onDestroy() {
        this.abortExistingRequest();
    }

    onClickArtist = (artist) => {
        this.rootStore.routerStore.goTo("master.application.naki-nigun.artist", {
            id: artist.artistId,
        });
    };

    @action.bound
    setAppearsInContainerRef(ref) {
        this.appearsInContainerRef = ref;
    }
}

export default NakiNigunGenericSearchViewStore;
