import React, { useCallback, useEffect, useState } from "react";
/* eslint-disable react-hooks/rules-of-hooks */
import { mergeCss } from "baasic-react-bootstrap/src/common";
import { useElementScrollPosition, useSideScroll, useWindowSize } from "common/hooks";

const Tabs = (props) => {
    const { value, id, options, disabled, onChange } = props;

    const [disabledArrows, setDisabledArrows] = useState(false);
    const [tabsRef, setTabsRef] = useState();
    const [scrollPosition, setScrollPosition] = useState({ start: true, end: false });

    const elemScrollPosition = useElementScrollPosition(tabsRef || null);
    const { width } = useWindowSize();

    const handleChangeOnResize = useCallback(() => {
        if (!tabsRef) return;
        const scrollStart = tabsRef.scrollLeft === 0;
        const scrollEnd = tabsRef.scrollWidth <= Math.ceil(tabsRef.scrollLeft) + Math.ceil(tabsRef.clientWidth) + 12;

        if (tabsRef.scrollLeft > 0 && !scrollEnd) {
            setScrollPosition({ start: false, end: false });
        } else if (scrollStart) {
            setScrollPosition({ start: true, end: false });
        } else if (scrollEnd) {
            setScrollPosition({ start: false, end: true });
        }
    }, [tabsRef]);

    useEffect(() => {
        handleChangeOnResize();
    }, [elemScrollPosition, handleChangeOnResize]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleChangeOnResize();
        }, 400);

        return () => clearTimeout(timer);
    }, [width, tabsRef, handleChangeOnResize]);

    const handleScrollAction = (direction) => {
        if (
            !tabsRef ||
            (direction === "left" && scrollPosition.start) ||
            (direction === "right" && scrollPosition.end)
        ) {
            return;
        }

        useSideScroll({
            element: tabsRef,
            direction: direction,
            speed: 20,
            distance: 400,
            step: 20,
        });

        setTimeout(() => {
            handleChangeOnResize();
        }, 400);
    };

    return (
        <div className="u-display--flex u-gap--med">
            {disabledArrows ? null : (
                <button
                    className="c-btn c-btn--base c-btn--alt c-btn--ghost"
                    disabled={scrollPosition.start}
                    onClick={() => handleScrollAction("left")}
                >
                    <i className="u-icon u-icon--base u-icon--arrow-left--primary"></i>
                </button>
            )}

            <div
                className="u-display--flex u-overflow--auto u-padd--bottom--sml"
                ref={(tabsRef) => {
                    if (!tabsRef) return;
                    setTabsRef(tabsRef);
                    setDisabledArrows(tabsRef.scrollWidth <= tabsRef.clientWidth);
                }}
            >
                {options.map((option, index) => {
                    const itemId = `${id}_${option.id}`;
                    const isChecked = value === option.id;

                    return (
                        <React.Fragment key={itemId}>
                            <input
                                id={itemId}
                                value={option.name}
                                checked={isChecked}
                                disabled={disabled}
                                type="radio"
                                onChange={() => onChange(option.id)}
                                tabIndex={0}
                                className={mergeCss(
                                    "c-segmented c-segmented--secondary c-segmented--secondary--lrg",
                                    index === 0 ? "c-segmented--first" : "",
                                    index === options.length - 1 ? "c-segmented--last" : "",
                                    isChecked ? "active" : ""
                                )}
                            />

                            <label htmlFor={itemId}>{option.label || option.name}</label>
                        </React.Fragment>
                    );
                })}
            </div>

            {disabledArrows ? null : (
                <button
                    className="c-btn c-btn--base c-btn--alt c-btn--ghost"
                    disabled={scrollPosition.end}
                    onClick={() => handleScrollAction("right")}
                >
                    <i className="u-icon u-icon--base u-icon--caret-right--primary"></i>
                </button>
            )}
        </div>
    );
};

export default Tabs;
