import React from "react";
import { NakiNigunArtistAlbumTemplate } from "themes/application/naki-nigun/components";
import { NakiNigunArtistAlbumViewStore } from "application/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistAlbumViewStore(rootStore, componentProps),
    "nakiNigunArtistAlbumViewStore"
)
class NakiNigunArtistAlbum extends React.Component {
    render() {
        return <NakiNigunArtistAlbumTemplate {...this.props} />;
    }
}

export default NakiNigunArtistAlbum;
