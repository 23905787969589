import { MrbBaseRouteStore } from "mrb/core";
import { ProducerReportService } from "common/services";

class ProducerRevenueReportRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ProducerReportService);
    }

    async getProducerPaymentSummary(producerId, options) {
        const response = await this.service.getProducerPaymentSummary(producerId, options);
        return response.data;
    }

    async findProducerReportItems(filter) {
        const response = await this.service.findProducerReportItems(filter);
        return response.data;
    }

    async findProducerReportPayoutUserEvents(params) {
        const response = await this.service.findProducerReportPayoutUserEvents(params);
        return response.data;
    }
}

export default ProducerRevenueReportRouteStore;
