import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbEditFormLayout, MrbButton } from "mrb/components";
import { MrbPageHeader } from "mrb/components/layout";
import { TorahAnytimeWizardLayout } from "application/torah-anytime/components";
import { observer } from "mobx-react";
import { MrbRouterLink } from "mrb/core/infrastructure/router";

function TorahAnytimeCreatePlaylistTemplate({ torahAnytimeCollectionEditViewStore, torahAnytimeWizardViewStore, t }) {
    const { form, isEdit } = torahAnytimeCollectionEditViewStore;
    return (
        <React.Fragment>
            <MrbPageHeader classNameExtend="c-page__header--secondary">
                <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center u-gap--lrg">
                    <MrbRouterLink
                        routeName="master.application.home.torah-anytime"
                        params={{ id: torahAnytimeWizardViewStore.podcastId }}
                    >
                        <i className="u-icon u-icon--med u-icon--back u-mar--right--tny"></i>
                        Back
                    </MrbRouterLink>
                </div>
            </MrbPageHeader>
            <MrbEditFormLayout form={form} layoutFooterVisible={false} t={t}>
                <TorahAnytimeWizardLayout
                    store={torahAnytimeWizardViewStore}
                    isEdit={isEdit}
                    controlsRenderer={(props) => (
                        <CreatePlaylistWizardControls {...props} t={t} isEdit={isEdit} onSubmit={form.onSubmit} />
                    )}
                    t={t}
                >
                    <div className="row">
                        <div className="col col-sml-4">
                            <MrbFieldInput t={t} field={form.$("name")} classNameExtend="c-input--outline" />
                        </div>
                    </div>
                </TorahAnytimeWizardLayout>
            </MrbEditFormLayout>
        </React.Fragment>
    );
}

const CreatePlaylistWizardControls = observer(function CreatePlaylistWizardControls({ store, isEdit, onSubmit, t }) {
    const { currentStep, previousStep } = store;

    return (
        <div>
            <div className="u-separator--primary"></div>
            <div className="u-display--flex u-display--flex--jc--space-between">
                <MrbButton
                    label={currentStep.backButtonLabel ? currentStep.backButtonLabel : "Back"}
                    t={t}
                    classNameOverride="c-btn c-btn--base c-btn--ghost"
                    onClick={previousStep}
                />
                <MrbButton label={isEdit ? "Save" : "Create Playlist"} t={t} type="submit" onClick={onSubmit} />
            </div>
        </div>
    );
});

TorahAnytimeCreatePlaylistTemplate.propTypes = {
    torahAnytimeCollectionEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(TorahAnytimeCreatePlaylistTemplate);
