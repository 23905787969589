import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService, DayHourCacheService, WeekDayCacheService } from "common/services";

class DeviceEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceService);
        this.dayHourService = new DayHourCacheService(this.rootStore);
        this.weekDayService = new WeekDayCacheService(this.rootStore);
    }

    updateUserDevice(resource) {
        return this.rootStore.userDeviceStore.updateUserDevice(resource);
    }

    deleteUserDevice(resource) {
        return this.rootStore.userDeviceStore.deleteUserDevice(resource);
    }

    getUserDevice(id) {
        return this.rootStore.userDeviceStore.getUserDevice(id, { embed: "device,device.systemType" });
    }

    getWeekDays() {
        return this.weekDayService.getItems();
    }

    getDayHours() {
        return this.dayHourService.getItems();
    }
}

export default DeviceEditRouteStore;
