import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunUserContentService } from "common/services";

class NakiNigunArtistRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.service = this.rootStore.createApplicationService(NakiNigunUserContentService);
    }

    async getNakiNigunArtistWthDeviceSettings(artistId, deviceId) {
        const response = await this.service.getNakiNigunArtistWthDeviceSettings(artistId, deviceId);
        return response.data;
    }

    async getNakiNigunArtist(artistId) {
        const response = await this.service.getNakiNigunArtist(artistId);
        return response.data;
    }

    async getUserDeviceAlbumsByArtist(deviceId, artistId) {
        const response = await this.service.getUserDeviceAlbumsByArtist(deviceId, artistId);
        return response.data;
    }
}

export default NakiNigunArtistRouteStore;
