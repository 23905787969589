import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService } from "common/services";

class DeviceListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceService);
    }

    async findUserDevices(params) {
        const response = await this.service.findUserDeviceForAuthenticatedUser(
            params
        );
        return response.data;
    }

    deleteUserDevice(resource) {
        return this.rootStore.userDeviceStore.deleteUserDevice(resource);
    }
}

export default DeviceListRouteStore;
