const useSideScroll = ({ element, direction, speed, distance, step }) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(function () {
        if (direction === "left") {
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }

        scrollAmount += step;

        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
        }
    }, speed);
}

export default useSideScroll;

