import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbEditPage } from "mrb/components";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import { defaultTemplate } from "common/hoc";
import deviceInfo from "themes/assets/images/device-info.png";

function DeviceCreateTemplate({ deviceCreateViewStore, t }) {
    const { form, deviceTemporaryCodeToUpper, onClickDeviceIdInfo, deviceIdInfoModal } = deviceCreateViewStore;
    return (
        <MrbEditPage
            store={deviceCreateViewStore}
            t={t}
            submitLabel="Add Device"
            contentClassNameExtend="c-container--content--sml"
        >
            <h2 className="u-type--base u-mar--bottom--sml">Connect your device to the portal</h2>
            <div className="c-card--primary">
                <MrbFieldInput
                    t={t}
                    infoLabelRenderer={(props) => <InfoLabelRenderer {...props} onClick={onClickDeviceIdInfo} />}
                    field={form.$("deviceTemporaryCode")}
                    classNameExtend="c-input--outline"
                    onChange={(event) => deviceTemporaryCodeToUpper(event.target.value)}
                />

                <MrbFieldInput t={t} field={form.$("deviceName")} classNameExtend="c-input--outline" />
            </div>

            <DeviceIdInfoModal modalParams={deviceIdInfoModal} />
        </MrbEditPage>
    );
}

DeviceCreateTemplate.propTypes = {
    deviceCreateViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function InfoLabelRenderer({ infoLabel, onClick }) {
    return (
        <div
            className="c-form__field__info"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                onClick();
            }}
        >
            <span className="u-type u-type--color--tertiary u-cursor--pointer">
                <i className="u-icon u-icon--med u-icon--question u-mar--right--tny" />
                {infoLabel}
            </span>
        </div>
    );
}

function DeviceIdInfoModal({ modalParams, t }) {
    return (
        <MrbModal modalParams={modalParams} t={t} displayCloseIcon={false}>
            <div>
                <div className="modal__icon u-mar--bottom--sml">
                    <img src={deviceInfo} className="c-device__info-icon" alt="Device Info" />
                </div>
                <div className="modal__header modal__header--secondary">
                    <h2>Where to find Device ID?</h2>
                </div>
                <div className="modal__body">
                    <ol className="modal__body__list">
                        <li className="modal__body__list-item">Press the menu/back button on your device.</li>
                        <li className="modal__body__list-item">
                            Choose <strong>Station List</strong>.
                        </li>
                        <li className="modal__body__list-item">You Device ID is a code with 8 characters.</li>
                    </ol>
                </div>
                <div className="modal__footer">
                    <MrbButton
                        t={t}
                        label="Close"
                        onClick={modalParams.close}
                        classNameOverride="c-btn c-btn--base c-btn--ghost"
                    />
                </div>
            </div>
        </MrbModal>
    );
}

export default defaultTemplate(DeviceCreateTemplate);
