import { action, computed, makeObservable, observable } from "mobx";
import { forEach } from "lodash";

class NakiNigunAlbum {
    @observable _isTurnedOn = null;

    constructor(obj) {
        makeObservable(this);
        forEach(obj, (value, key) => {
            if (key === "isTurnedOn") {
                this.setIsTurnedOn(value);
            } else {
                this[key] = value;
            }
        });
    }

    @computed get isTurnedOn() {
        return this._isTurnedOn;
    }

    set isTurnedOn(value) {
        this.setIsTurnedOn(value);
    }

    @action.bound
    setIsTurnedOn(isTurnedOn) {
        this._isTurnedOn = isTurnedOn;
    }
}

export default NakiNigunAlbum;
