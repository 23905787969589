import React from "react";
import { PropTypes } from "prop-types";
import { HomeDeviceSelect } from "application/home/components";
import { observer } from "mobx-react";

function TorahAnytimeHeaderTemplate({ store, t }) {
    const {
        deviceSelectStore,
        torahAnytimePodcast,
        rootStore: { userDeviceStore },
    } = store;

    return (
        <div className="c-home__header">
            <div className="c-home__header__input__wrapper">{torahAnytimePodcast && torahAnytimePodcast.title}</div>
            {userDeviceStore.deviceCount > 0 && (
                <React.Fragment>
                    <HomeDeviceSelect store={deviceSelectStore} userDeviceStore={userDeviceStore} t={t} />
                </React.Fragment>
            )}
        </div>
    );
}

TorahAnytimeHeaderTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(TorahAnytimeHeaderTemplate);
