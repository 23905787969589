import { moduleProviderFactory } from "mrb/core";
import { DeviceCreate, DeviceEdit, DeviceList } from "application/device-settings/pages";
import { DeviceCreateRouteStore, DeviceEditRouteStore, DeviceListRouteStore } from "application/device-settings/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "DeviceSettings",
        routes: [
            {
                name: "master.application.device",
                pattern: "/device",
                children: [
                    {
                        name: "master.application.device.list",
                        pattern: "",
                        component: DeviceList,
                        store: DeviceListRouteStore,
                        data: {
                            title: "APPLICATION.DEVICE_SETTINGS.TITLE",
                        },
                    },
                    {
                        name: "master.application.device.create",
                        pattern: "/create",
                        component: DeviceCreate,
                        store: DeviceCreateRouteStore,
                    },
                    {
                        name: "master.application.device.edit",
                        pattern: "/:id/edit",
                        component: DeviceEdit,
                        store: DeviceEditRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.DEVICES",
                order: 4,
                icon: "devices",
                route: "master.application.device.list",
            },
        ],
    });
})();
