import React from "react";
import { HomeStationTemplate } from "themes/application/home/pages";
import { HomeStationViewStore } from "application/home/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new HomeStationViewStore(rootStore, componentProps),
    "homeStationViewStore"
)
class HomeStation extends React.Component {
    render() {
        return <HomeStationTemplate {...this.props} />;
    }
}

export default HomeStation;
