import React, { useRef } from "react";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import { mergeCss } from "mrb/common/utils";
import { numberFormatter } from "common/utils";
import { producerTypes } from "common/constants";
import { MrbSimpleSelect } from "mrb/components/select";
import { MrbDatePicker } from "mrb/components/date-picker";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { MrbRadioButtons } from "baasic-react-bootstrap/src";
import { MrbPageHeader } from "mrb/components/layout";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { ProducerStatsLoader, ProducerTotalLoader } from "application/producer/components";
import { StatsWidgetEmptyState } from "common/components/empty-state";

function ProducerDashboardTemplate({ producerDashboardViewStore, t }) {
    const {
        nakiNigunStatsInfiniteScrollStore,
        nakiTalesStatsInfiniteScrollStore,
        producerTypeSlug,
        producerDashboardTimeRangeViewStore,
        statsFilterOptions,
        nakiNigunFilterSelectedOptionValue,
        nakiTalesFilterSelectedOptionValue,
        setNakiNigunFilterSelectedOptionValue,
        setNakiTalesFilterSelectedOptionValue,
        nakiNigunTotalListeners,
        nakiTalesTotalListeners,
    } = producerDashboardViewStore;

    function getHeaderTitle() {
        if (producerTypeSlug === producerTypes.podcasterSlug) {
            return t("APPLICATION.PRODUCER.PREVIEW.PODCASTER_HEADER_TITLE");
        } else if (producerTypeSlug === producerTypes.artistSlug) {
            return t("APPLICATION.PRODUCER.PREVIEW.ARTIST_HEADER_TITLE");
        } else {
            return "Producer Account";
        }
    }

    return (
        <React.Fragment>
            <MrbPageHeader>
                <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center u-width--100">
                    {getHeaderTitle()}
                    <DatePickerTemplate
                        producerDashboardTimeRangeViewStore={producerDashboardTimeRangeViewStore}
                        t={t}
                    />
                </div>
            </MrbPageHeader>
            <div className="row">
                {producerTypeSlug === producerTypes.podcasterSlug ? (
                    <div className="col col-sml-12 col-xxlrg-6 u-mar--bottom--med">
                        <NakiTalesStats
                            nakiTalesStatsInfiniteScrollStore={nakiTalesStatsInfiniteScrollStore}
                            title={t("APPLICATION.PRODUCER.DASHBOARD.PODCASTER.PODCAST_STATS_TITLE")}
                            statsFilterOptions={statsFilterOptions}
                            nakiTalesFilterSelectedOptionValue={nakiTalesFilterSelectedOptionValue}
                            setNakiTalesFilterSelectedOptionValue={setNakiTalesFilterSelectedOptionValue}
                            totalListeners={nakiTalesTotalListeners}
                            t={t}
                        />
                    </div>
                ) : producerTypeSlug === producerTypes.artistSlug ? (
                    <>
                        <div className="col col-sml-12 col-xxlrg-6 u-mar--bottom--med">
                            <NakiNigunStats
                                nakiNigunStatsInfiniteScrollStore={nakiNigunStatsInfiniteScrollStore}
                                title={t("APPLICATION.PRODUCER.DASHBOARD.ARTIST.NAKI_NIGUN_STATS_TITLE")}
                                statsFilterOptions={statsFilterOptions}
                                nakiNigunFilterSelectedOptionValue={nakiNigunFilterSelectedOptionValue}
                                setNakiNigunFilterSelectedOptionValue={setNakiNigunFilterSelectedOptionValue}
                                totalListeners={nakiNigunTotalListeners}
                                t={t}
                            />
                        </div>
                        <div className="col col-sml-12 col-xxlrg-6">
                            <NakiTalesStats
                                nakiTalesStatsInfiniteScrollStore={nakiTalesStatsInfiniteScrollStore}
                                title={t("APPLICATION.PRODUCER.DASHBOARD.ARTIST.NAKI_TALES_STATS_TITLE")}
                                statsFilterOptions={statsFilterOptions}
                                nakiTalesFilterSelectedOptionValue={nakiTalesFilterSelectedOptionValue}
                                setNakiTalesFilterSelectedOptionValue={setNakiTalesFilterSelectedOptionValue}
                                totalListeners={nakiTalesTotalListeners}
                                t={t}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col col-sml-12 col-xxlrg-6 u-mar--bottom--med">
                            <NakiNigunStats
                                nakiNigunStatsInfiniteScrollStore={nakiNigunStatsInfiniteScrollStore}
                                title={t("APPLICATION.PRODUCER.DASHBOARD.ARTIST.NAKI_NIGUN_STATS_TITLE")}
                                statsFilterOptions={statsFilterOptions}
                                nakiNigunFilterSelectedOptionValue={nakiNigunFilterSelectedOptionValue}
                                setNakiNigunFilterSelectedOptionValue={setNakiNigunFilterSelectedOptionValue}
                                totalListeners={nakiNigunTotalListeners}
                                t={t}
                            />
                        </div>
                        <div className="col col-sml-12 col-xxlrg-6">
                            <NakiTalesStats
                                nakiTalesStatsInfiniteScrollStore={nakiTalesStatsInfiniteScrollStore}
                                title={t("APPLICATION.PRODUCER.DASHBOARD.ARTIST.NAKI_TALES_STATS_TITLE")}
                                statsFilterOptions={statsFilterOptions}
                                nakiTalesFilterSelectedOptionValue={nakiTalesFilterSelectedOptionValue}
                                setNakiTalesFilterSelectedOptionValue={setNakiTalesFilterSelectedOptionValue}
                                totalListeners={nakiTalesTotalListeners}
                                t={t}
                            />
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );
}

ProducerDashboardTemplate.propTypes = {
    producerDashboardViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const DatePickerTemplate = observer(function DatePickerTemplate({ producerDashboardTimeRangeViewStore, t }) {
    const { filter, maxDate, minDate, applyFilter, setToDate, setFromDate, isDateRangeDisabled, timeRangeSelectStore } =
        producerDashboardTimeRangeViewStore;

    return (
        <div className="u-display--flex u-display--flex--ai--center u-gap--base">
            <MrbSimpleSelect store={timeRangeSelectStore} classNameExtend="c-select--secondary c-select--date" />

            <div className="u-display--flex u-display--flex--ai--center">
                <MrbDatePicker
                    value={filter.from}
                    selectsStart={true}
                    isClearable={false}
                    disabled={isDateRangeDisabled}
                    maxDate={maxDate}
                    timeZone={filter.timeZone}
                    startDate={filter.from}
                    endDate={filter.to}
                    onChange={setFromDate}
                />
                <span className="u-mar--right--tny u-mar--left--tny">-</span>
                <MrbDatePicker
                    value={filter.to}
                    selectsEnd={true}
                    isClearable={false}
                    disabled={isDateRangeDisabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    timeZone={filter.timeZone}
                    startDate={filter.from}
                    endDate={filter.to}
                    onChange={setToDate}
                />
            </div>

            <MrbButton
                t={t}
                className="c-btn c-btn--base c-btn--primary"
                onClick={applyFilter}
                label="Apply"
                disabled={isDateRangeDisabled}
            />
        </div>
    );
});

const NakiTalesStats = observer(function NakiTalesStats({
    nakiTalesStatsInfiniteScrollStore,
    title,
    statsFilterOptions,
    nakiTalesFilterSelectedOptionValue,
    setNakiTalesFilterSelectedOptionValue,
    totalListeners,
    t,
}) {
    const { gridStore } = nakiTalesStatsInfiniteScrollStore;
    const scrollParentRef = useRef(null);
    return (
        <div>
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml">
                <h1 className="u-type--base">{title}</h1>
                <MrbRadioButtons
                    id="trending-podcasts-stats-filter"
                    name="trending-podcasts-stats-filter"
                    inputClassNameExtend="c-segmented--vertical"
                    options={statsFilterOptions}
                    onChange={setNakiTalesFilterSelectedOptionValue}
                    value={nakiTalesFilterSelectedOptionValue}
                />
            </div>
            {totalListeners != null ? (
                nakiTalesFilterSelectedOptionValue === 1 ? (
                    <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml u-type--base c-card--primary">
                        <p>{t("APPLICATION.PRODUCER.DASHBOARD.TOTAL.STREAMS")}</p>
                        <p>{numberFormatter.format(totalListeners)}</p>
                    </div>
                ) : (
                    <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml u-type--base c-card--primary">
                        <p>{t("APPLICATION.PRODUCER.DASHBOARD.TOTAL.LISTENERS")}</p>
                        <p>{numberFormatter.format(totalListeners)}</p>
                    </div>
                )
            ) : (
                <ProducerTotalLoader />
            )}
            <div className="c-card--primary u-overflow--auto u-height--530--max" ref={scrollParentRef}>
                <MrbInfiniteScrollGrid
                    loaderRenderer={() => <ProducerStatsLoader noButton />}
                    loadMoreLoader={<ProducerStatsLoader loadMore row={3} noButton />}
                    emptyStateRenderer={() => (
                        <StatsWidgetEmptyState classNameExtend="u-padd--top--lrg u-padd--bottom--lrg" />
                    )}
                    classNameOverride=""
                    store={gridStore}
                    scrollParent={scrollParentRef.current}
                    cellRenderer={(props) => (
                        <div className="u-animation--fadeIn c-analytics__list__item c-analytics__list__item--secondary">
                            <div className="c-analytics__list__item" key={props.item.id}>
                                <div className="u-display--flex u-display--flex--ai--center">
                                    <span className="u-mar--right--tny u-mar--left--tny">{props.index + 1}.</span>
                                    {props.item.title ? <p>{props.item.title}</p> : <span>Unknown podcast</span>}
                                    <i
                                        className={mergeCss(
                                            "u-icon u-icon--base u-mar--left--tny",
                                            props.item.isPremium ? "u-icon--premium" : null
                                        )}
                                    ></i>
                                </div>
                                <p className="c-analytics__list__count">{numberFormatter.format(props.item.score)}</p>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
});

const NakiNigunStats = observer(function NakiNigunStats({
    nakiNigunStatsInfiniteScrollStore,
    title,
    statsFilterOptions,
    nakiNigunFilterSelectedOptionValue,
    setNakiNigunFilterSelectedOptionValue,
    totalListeners,
    t,
}) {
    const { gridStore } = nakiNigunStatsInfiniteScrollStore;
    const scrollParentRef = useRef(null);

    return (
        <div>
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml">
                <h1 className="u-type--base">{title}</h1>
                <MrbRadioButtons
                    id="trending-albums-stats-filter"
                    name="trending-albums-stats-filter"
                    inputClassNameExtend="c-segmented--vertical"
                    options={statsFilterOptions}
                    onChange={setNakiNigunFilterSelectedOptionValue}
                    value={nakiNigunFilterSelectedOptionValue}
                />
            </div>
            {totalListeners != null ? (
                nakiNigunFilterSelectedOptionValue === 1 ? (
                    <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml u-type--base c-card--primary">
                        <p>{t("APPLICATION.PRODUCER.DASHBOARD.TOTAL.STREAMS")}</p>
                        <p>{numberFormatter.format(totalListeners)}</p>
                    </div>
                ) : (
                    <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml u-type--base c-card--primary">
                        <p>{t("APPLICATION.PRODUCER.DASHBOARD.TOTAL.LISTENERS")}</p>
                        <p>{numberFormatter.format(totalListeners)}</p>
                    </div>
                )
            ) : (
                <ProducerTotalLoader />
            )}

            <div className="c-card--primary u-overflow--auto u-height--530--max" ref={scrollParentRef}>
                <MrbInfiniteScrollGrid
                    loaderRenderer={() => <ProducerStatsLoader noButton />}
                    loadMoreLoader={<ProducerStatsLoader loadMore row={3} noButton />}
                    emptyStateRenderer={() => (
                        <StatsWidgetEmptyState classNameExtend="u-padd--top--lrg u-padd--bottom--lrg" />
                    )}
                    classNameOverride=""
                    store={gridStore}
                    scrollParent={scrollParentRef.current}
                    cellRenderer={(props) => (
                        <div className="u-animation--fadeIn c-analytics__list__item c-analytics__list__item--secondary">
                            <div className="c-analytics__list__item" key={props.item.id}>
                                <div className="u-display--flex u-display--flex--ai--center">
                                    <span className="u-mar--right--tny u-mar--left--tny">{props.index + 1}.</span>
                                    {props.item.title ? <p>{props.item.title}</p> : <span>Unknown album</span>}
                                    <i
                                        className={mergeCss(
                                            "u-icon u-icon--base u-mar--left--tny",
                                            props.item.isPremium ? "u-icon--premium" : null
                                        )}
                                    ></i>
                                </div>
                                <p className="c-analytics__list__count">{numberFormatter.format(props.item.score)}</p>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
});

export default defaultTemplate(ProducerDashboardTemplate);
