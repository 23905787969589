import { BaseProducerRevenueReportViewStore } from "common/stores/producer";

class ProducerRevenueReportViewStore extends BaseProducerRevenueReportViewStore {
    producerId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, { maskUserNames: true });
        this.routeStore = routeStore;
        this.producerId = this.rootStore.routerStore.routerState.params.id;
    }

    find(params) {
        params.producerId = this.producerId;
        return this.routeStore.findProducerReportItems(params);
    }

    getSumaryData(params) {
        return this.routeStore.getProducerPaymentSummary(this.producerId, params);
    }
}

export default ProducerRevenueReportViewStore;
