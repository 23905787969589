import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import {
    forEach,
    map,
    reduce,
    sortBy,
    some,
    isEmpty,
    filter,
    take,
} from "lodash";
import moment from "moment";
import "moment-timezone";

class DeviceDowntimeEditViewStore extends MrbBaseViewStore {
    @observable.ref weekDays = [];
    @observable.ref dayHours = [];
    @observable disableActionOccured = false;

    nightTime = [
        "8pm",
        "9pm",
        "10pm",
        "11pm",
        "12am",
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
    ];
    shabbosTimeFriday = ["7pm", "8pm", "9pm", "10pm", "11pm", "12am"];
    shabbosTimeSaturday = [
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
    ];

    constructor(rootStore, routeStore, form) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.form = form;
        if (!this.form.has("prohibitedTime")) {
            this.form.add({ key: "prohibitedTime" });
        }

        this.timeZones = moment.tz.names();
        this.isAllDayChecked = this.isAllDayChecked.bind(this);
    }

    findTimeZone = (searchTerm) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (!searchTerm) {
                    resolve(take(this.timeZones, 15));
                } else {
                    const items = filter(
                        this.timeZones,
                        (timeZone) =>
                            timeZone.toLowerCase().indexOf(searchTerm) !== -1
                    );
                    resolve(take(items, 15));
                }
            }, 300);
        });
    };

    async initialize() {
        const [weekDays, dayHours] = await Promise.all([
            this.routeStore.getWeekDays(),
            this.routeStore.getDayHours(),
        ]);
        runInAction(() => {
            this.weekDays = sortBy(weekDays, (item) => item.order);
            this.dayHours = sortBy(dayHours, (item) => item.order);
        });
        const currentTimeZone = this.form.$("timeZone").value;
        if (!currentTimeZone) {
            this.form.$("timeZone").set(moment.tz.guess());
        }

        const timeZoneOptions = map(this.timeZones, (timeZone) => {
            return {
                id: timeZone,
                name: timeZone,
            };
        });

        this.timezoneSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                isMulti: false,
                virtualized: true,
            },
            this.form.$("timeZone"),
            timeZoneOptions
        );
    }

    @action.bound
    buildForm(data) {
        forEach(this.weekDays, (day) => {
            this.form.$("prohibitedTime").add({
                key: day.abrv,
                label: day.name,
                fields: [
                    ...this.generateTimeFrameFields(data && data[day.abrv]),
                ],
            });
        });
    }

    generateTimeFrameFields(data) {
        return map(this.dayHours, (hour) => {
            return {
                name: hour.abrv,
                type: "bool",
                value:
                    data && data.length > 0
                        ? some(data, (hourAbrv) => hourAbrv === hour.abrv)
                        : false,
            };
        });
    }

    @action.bound
    disableEntireDay(selectedDay, isChecked) {
        let days = this.form.$("prohibitedTime").value;
        forEach(days[selectedDay], (value, index) => {
            days[selectedDay][index] = isChecked;
        });
        this.form.$("prohibitedTime").update(days);
        this.disableActionOccured = true;
    }

    @action.bound
    disableNight() {
        let days = this.form.$("prohibitedTime").value;
        forEach(this.nightTime, (value) => {
            forEach(days, (v, index) => {
                days[index][value] = true;
            });
        });
        this.form.$("prohibitedTime").update(days);
        this.disableActionOccured = true;
    }

    @action.bound
    disableShabbos() {
        let days = this.form.$("prohibitedTime").value;
        forEach(this.shabbosTimeFriday, function (value) {
            days["fri"][value] = true;
        });
        forEach(this.shabbosTimeSaturday, function (value) {
            days["sat"][value] = true;
        });
        this.form.$("prohibitedTime").update(days);
        this.disableActionOccured = true;
    }

    @action.bound
    reset() {
        this.form.$("prohibitedTime").reset();
    }

    isAllDayChecked(day) {
        return !some(day, (isSelected) => !isSelected);
    }

    processFormResult(deviceDowntime) {
        const obj = {};
        forEach(deviceDowntime, (dayTimeFrames, dayAbrv) => {
            const timeFrames = reduce(
                dayTimeFrames,
                function (result, value, key) {
                    if (value) {
                        result.push(key);
                    }
                    return result;
                },
                []
            );
            if (timeFrames && timeFrames.length > 0) {
                obj[dayAbrv] = timeFrames;
            }
        });
        return !isEmpty(obj) ? obj : null;
    }
}

export default DeviceDowntimeEditViewStore;
