import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage } from "mrb/components/layout";
import { PremiumContentList, SubscriptionPlanCard } from "application/payment/components";
import { observer } from "mobx-react";
import { defaultTemplate } from "common/hoc";
import { map } from "lodash";

function PremiumSubscriptionsTemplate({ premiumSubscriptionsViewStore, t }) {
    return (
        <MrbPreviewPage t={t} store={premiumSubscriptionsViewStore}>
            <SubscriptionPlans premiumSubscriptionsViewStore={premiumSubscriptionsViewStore} />
            <PremiumContentList
                routeStore={premiumSubscriptionsViewStore.routeStore}
                premiumSubscriptionsViewStore={premiumSubscriptionsViewStore}
            />
        </MrbPreviewPage>
    );
}

const SubscriptionPlans = observer(function SubscriptionPlans({ premiumSubscriptionsViewStore }) {
    const {
        loaderStore,
        subscriptionPlans,
        setSelectedSubscriptionPlan,
        subscriptionExist,
        rootStore: { premiumSubscriptionStore },
    } = premiumSubscriptionsViewStore;

    return (
        <div>
            <React.Fragment>
                {subscriptionPlans && subscriptionPlans.length > 0 && (
                    <div className="c-payment__list u-mar--bottom--lrg">
                        {map(subscriptionPlans, (subscriptionPlan) => {
                            return (
                                <SubscriptionPlanCard
                                    loaderStore={loaderStore}
                                    isPremiumTrialPeriodUsed={premiumSubscriptionStore.isPremiumTrialPeriodUsed}
                                    isInternalSubscriptionActive={premiumSubscriptionStore.isInternalSubscriptionActive}
                                    subscriptionPlan={subscriptionPlan}
                                    isSubscriptionBundleActive={premiumSubscriptionStore.isSubscriptionBundleActive}
                                    subscription={subscriptionPlan.subscription}
                                    subscriptionExist={subscriptionExist}
                                    key={subscriptionPlan.id}
                                    onClickExploreContent={setSelectedSubscriptionPlan}
                                />
                            );
                        })}
                    </div>
                )}
            </React.Fragment>
        </div>
    );
});

PremiumSubscriptionsTemplate.propTypes = {
    premiumSubscriptionsViewStore: PropTypes.object.isRequired,
};

export default defaultTemplate(PremiumSubscriptionsTemplate);
