import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { HomePreviewHeader, HomeSubPodcast, HomePreviewSubPodcastListLoader } from "application/home/components";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";
import { map } from "lodash";
import { observer } from "mobx-react";
import { HTMLRender, DescriptionLoader, PremiumBadge, Image } from "common/components";

function HomePodcastGroupTemplate({ homePodcastViewStore, t }) {
    const { loaderStore, item, coverImageUrl, canAccessPremiumContent } = homePodcastViewStore;

    return (
        <MrbPreviewPage store={homePodcastViewStore} t={t} contentClassNameExtend="u-padd--none">
            <MrbPageHeader>
                <HomePreviewHeader store={homePodcastViewStore} hideStatusButton={true} t={t} />
            </MrbPageHeader>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div>
                                <div className="u-animation--fadeIn">
                                    <div className="c-home__preview__header__icon">
                                        <Image src={coverImageUrl} />
                                        <PremiumBadge
                                            isPremium={item.isPremium}
                                            isPremiumSubscriptionValid={canAccessPremiumContent}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.HOME.PODCAST.FIELDS.GROUP_TITLE")}
                                        </p>
                                        <p className="c-content__header--primary__title">{item.title}</p>
                                    </div>

                                    <div className="c-content__header--primary__descriprtion">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.HOME.PODCAST.FIELDS.DESCRIPTION_LINK")}
                                        </p>
                                        {item.descriptionLink && (
                                            <a href={item.descriptionLink} target="_blank" rel="noreferrer">
                                                {item.descriptionLink}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <PodcastInfo store={homePodcastViewStore} item={item} t={t} />

            <SubPodcasts store={homePodcastViewStore} t={t} />
        </MrbPreviewPage>
    );
}

HomePodcastGroupTemplate.propTypes = {
    homePodcastViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const SubPodcasts = observer(function SubPodcasts({ store, t }) {
    const { item, loaderStore } = store;

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <HomePreviewSubPodcastListLoader />}>
            {() => (
                <React.Fragment>
                    {item && item.subPodcasts && item.subPodcasts.length > 0 && (
                        <div className="u-animation--fadeIn">
                            <div className="c-container--sidebar">
                                <div>
                                    {map(item.subPodcasts, (subPodcast) => (
                                        <HomeSubPodcast
                                            key={subPodcast.id}
                                            subPodcastId={subPodcast.id}
                                            homePodcastViewStore={store}
                                            t={t}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
});

function PodcastInfo({ store, item, t }) {
    const { loaderStore } = store;

    return (
        <React.Fragment>
            {!item || (item && item.alternativeDescription) ? (
                <div
                    className={mergeCss(
                        "c-container--sidebar",
                        !item || (item && item.alternativeDescription) ? "u-padd--bottom--none" : null
                    )}
                >
                    <div className="c-card--primary c-card--med">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DescriptionLoader />}>
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-12">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("APPLICATION.HOME.STATION.FIELDS.DESCRIPTION")}
                                                </p>
                                                <HTMLRender className="c-collapse-panel__body__description">
                                                    {item.alternativeDescription}
                                                </HTMLRender>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

export default defaultTemplate(HomePodcastGroupTemplate);
