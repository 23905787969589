import React from "react";
import { ProducerDashboardTemplate } from "themes/application/producer/pages";
import { ProducerDashboardViewStore } from "application/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ProducerDashboardViewStore(rootStore, componentProps),
    "producerDashboardViewStore"
)
class ProducerDashboard extends React.Component {
    render() {
        return <ProducerDashboardTemplate {...this.props} />;
    }
}

export default ProducerDashboard;
