import React from "react";

const ProducerTopTrendingEmptyStateTemplate = () => {
    return (
        <div className="c-empty-state--no-results">
            <i className="u-icon--empty u-icon--empty--lrg u-icon--empty--shuffle u-mar--bottom--sml"></i>
            <h3>No stats</h3>
            <p>No content found.</p>
        </div>
    );
};

export default ProducerTopTrendingEmptyStateTemplate;
