import React from "react";
import { UserProfilePasswordChangeViewStore } from "application/user-settings/stores";
import { UserProfilePasswordChangeTemplate } from "themes/application/user-settings/components";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserProfilePasswordChangeViewStore(rootStore, componentProps),
    "userProfilePasswordChangeViewStore"
)
class UserProfilePasswordChange extends React.Component {
    render() {
        return <UserProfilePasswordChangeTemplate {...this.props} />;
    }
}

export default UserProfilePasswordChange;