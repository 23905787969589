import { MrbBaseRouteStore } from "mrb/core";

class UserProfileEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.application.baasic.membershipModule.user;
    }

    getResource() {
        return Promise.resolve(this.rootStore.userStore.user);
    }

    update(resource) {
        return this.rootStore.userStore.updateAccountInfo(resource);
    }

    changeUserPassword(userId, resource) {
        return this.service.changePassword(userId, resource);
    }
}

export default UserProfileEditRouteStore;
