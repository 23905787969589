import React from "react";
import { setCurrentView } from "mrb/core";
import { UserProfileEditTemplate } from "themes/application/user-settings/pages";
import { UserProfileEditViewStore } from "application/user-settings/stores";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserProfileEditViewStore(rootStore, componentProps),
    "userProfileEditViewStore"
)
class UserProfileEdit extends React.Component {
    render() {
        return <UserProfileEditTemplate {...this.props} />;
    }
}

export default UserProfileEdit;