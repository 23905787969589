import React from "react";
import { PropTypes } from "prop-types";
import { mergeCss } from "mrb/common/utils";
import { getContentActivityStatusIcon } from "application/naki-nigun/utils";
import { PremiumBadge, Image } from "common/components";
import { NakiNigunArtist, NakiNigunAlbum } from "application/naki-nigun/models";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { observer } from "mobx-react";
import { findIndex, isNil } from "lodash";

function NakiNigunHomeGridCellTemplate(props) {
    if (props.item instanceof NakiNigunArtist) {
        return <NakiNigunArtistCell {...props} />;
    } else if (props.item instanceof NakiNigunAlbum) {
        return <NakiNigunAlbumCell {...props} />;
    }
}

NakiNigunHomeGridCellTemplate.propTypes = {
    onCellClick: PropTypes.func.isRequired,
    premiumSubscriptionStore: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

const NakiNigunArtistCell = observer(function NakiNigunArtistCell({
    onCellClick,
    goTo,
    item,
    nakiNigunManageSelectedItemsViewStore,
}) {
    const contentAccessResult = useCanAccessContent(nakiRadioContentType.nakiNigun, {
        isPremium: item.isPremium,
        unavailableInMobileApp: item.isVisibleInApp === false,
        unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
    });
    const { isBulk, artistBulkEditViewStore } = nakiNigunManageSelectedItemsViewStore;

    function getBulkModeSelectIndicatorValue(
        item,
        canAccessArtist,
        isAllSelected,
        selectedArtistIds,
        deselectedArtistIds
    ) {
        if (!canAccessArtist) {
            return false;
        }
        const selectedIndex = findIndex(selectedArtistIds, (selectedItemId) => selectedItemId === item.artistId);
        if (selectedIndex !== -1) {
            return true;
        }
        const deselectedIndex = findIndex(
            deselectedArtistIds,
            (deselectedItemId) => deselectedItemId === item.artistId
        );
        if (deselectedIndex !== -1) {
            return false;
        }
        if (!isNil(isAllSelected)) {
            return isAllSelected;
        }
        return item.numberOfAlbums === item.numberOfEnabledAlbums;
    }

    function bulkSelectOrDeselect(item, canAccessArtist, isAllSelected, selectedArtistIds, deselectedArtistIds) {
        const isSelected = getBulkModeSelectIndicatorValue(
            item,
            canAccessArtist,
            isAllSelected,
            selectedArtistIds,
            deselectedArtistIds
        );
        if (isSelected) {
            artistBulkEditViewStore.deselectItem(item);
        } else {
            artistBulkEditViewStore.selectItem(item);
        }
    }

    return (
        <div
            className={mergeCss(
                "c-home__card",
                isBulk && (contentAccessResult.canAccess ? "c-home__card--secondary" : "c-home__card--tertiary")
            )}
        >
            <div
                className="c-home__card__media"
                onClick={() => {
                    isBulk
                        ? bulkSelectOrDeselect(
                              item,
                              contentAccessResult.canAccess,
                              artistBulkEditViewStore.bulkSelection.isAllSelected,
                              artistBulkEditViewStore.bulkSelection.selectedIds,
                              artistBulkEditViewStore.bulkSelection.deselectedIds
                          )
                        : onCellClick(item);
                }}
            >
                <div
                    className={
                        contentAccessResult.canAccess
                            ? mergeCss(
                                  "c-home__status c-home__status--secondary",
                                  isBulk
                                      ? getBulkModeSelectIndicatorValue(
                                            item,
                                            contentAccessResult.canAccess,
                                            artistBulkEditViewStore.bulkSelection.isAllSelected,
                                            artistBulkEditViewStore.bulkSelection.selectedIds,
                                            artistBulkEditViewStore.bulkSelection.deselectedIds
                                        )
                                          ? "c-home__status--enabled"
                                          : "c-home__status--disabled"
                                      : getContentActivityStatusIcon(item)
                              )
                            : null
                    }
                ></div>

                <div
                    className={mergeCss(
                        "c-home__card__img-wrapper jq-onboarding-turn-on-off",
                        contentAccessResult.canAccess
                            ? item.isTurnedOn
                                ? "status--disabled"
                                : "status--enabled"
                            : "status--off",
                        !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                    )}
                >
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge
                        isPremium={item.isPremium}
                        isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                    />
                </div>
            </div>

            <div className="c-home__card__title jq-onboarding-title" onClick={() => goTo(item)}>
                {item.name}
            </div>
        </div>
    );
});

const NakiNigunAlbumCell = observer(function NakiNigunAlbumCell({
    onCellClick,
    goTo,
    item,
    nakiNigunManageSelectedItemsViewStore,
}) {
    const contentAccessResult = useCanAccessContent(nakiRadioContentType.nakiNigun, {
        isPremium: item.isPremium,
        unavailableInMobileApp: item.isVisibleInApp === false,
        unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
    });
    const { isBulk, albumBulkEditViewStore } = nakiNigunManageSelectedItemsViewStore;

    function getBulkModeSelectIndicatorValue(
        item,
        canAccessAlbum,
        isAllSelected,
        selectedAlbumIds,
        deselectedAlbumIds
    ) {
        if (!canAccessAlbum) {
            return false;
        }
        const selectedIndex = findIndex(selectedAlbumIds, (selectedItemId) => selectedItemId === item.albumId);
        if (selectedIndex !== -1) {
            return true;
        }
        const deselectedIndex = findIndex(deselectedAlbumIds, (deselectedItemId) => deselectedItemId === item.albumId);
        if (deselectedIndex !== -1) {
            return false;
        }
        if (!isNil(isAllSelected)) {
            return isAllSelected;
        }
        return item.isTurnedOn;
    }

    function bulkSelectOrDeselect(item, canAccessAlbum, isAllSelected, selectedAlbumIds, deselectedAlbumIds) {
        const isSelected = getBulkModeSelectIndicatorValue(
            item,
            canAccessAlbum,
            isAllSelected,
            selectedAlbumIds,
            deselectedAlbumIds
        );
        if (isSelected) {
            albumBulkEditViewStore.deselectItem(item);
        } else {
            albumBulkEditViewStore.selectItem(item);
        }
    }

    return (
        <div
            className={mergeCss(
                "c-home__card",
                isBulk && (contentAccessResult.canAccess ? "c-home__card--secondary" : "c-home__card--tertiary")
            )}
        >
            <div
                className="c-home__card__media"
                onClick={() => {
                    isBulk
                        ? bulkSelectOrDeselect(
                              item,
                              contentAccessResult.canAccess,
                              albumBulkEditViewStore.bulkSelection.isAllSelected,
                              albumBulkEditViewStore.bulkSelection.selectedIds,
                              albumBulkEditViewStore.bulkSelection.deselectedIds
                          )
                        : onCellClick(item);
                }}
            >
                <div
                    className={
                        contentAccessResult.canAccess
                            ? mergeCss(
                                  "c-home__status c-home__status--secondary",
                                  isBulk
                                      ? getBulkModeSelectIndicatorValue(
                                            item,
                                            contentAccessResult.canAccess,
                                            albumBulkEditViewStore.bulkSelection.isAllSelected,
                                            albumBulkEditViewStore.bulkSelection.selectedIds,
                                            albumBulkEditViewStore.bulkSelection.deselectedIds
                                        )
                                          ? "c-home__status--enabled"
                                          : "c-home__status--disabled"
                                      : getContentActivityStatusIcon(item)
                              )
                            : null
                    }
                ></div>

                <div
                    className={mergeCss(
                        "c-home__card__img-wrapper jq-onboarding-turn-on-off",
                        contentAccessResult.canAccess
                            ? item.isTurnedOn
                                ? "status--disabled"
                                : "status--enabled"
                            : "status--off",
                        !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                    )}
                >
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge
                        isPremium={item.isPremium}
                        isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                    />
                </div>
            </div>

            <div className="c-home__card__title jq-onboarding-title" onClick={() => goTo(item)}>
                {item.title}
            </div>
        </div>
    );
});

export default NakiNigunHomeGridCellTemplate;
