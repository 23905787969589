import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { TogglePremium } from "application/home/components";
import { NakiNigunAlbumUnavailableInApp } from "application/common/components";
import { Image } from "common/components";
import { observer } from "mobx-react";
import { MrbButton } from "mrb/components/button";
import { mergeCss } from "mrb/common/utils";

function NakiNigunArtistAlbumTemplate({ nakiNigunArtistAlbumViewStore, t }) {
    const { album, isCollapsed, toggleCollapse, setPanelRef } = nakiNigunArtistAlbumViewStore;
    return (
        <MrbCollapsiblePanel
            title={album.title}
            innerRef={setPanelRef}
            t={t}
            isCollapsed={isCollapsed}
            onChangeCollapse={(event) => {
                // Since our control is inside a collapse panel, we must prevent toggle button from triggering the collapse change event
                if (
                    event.target.className &&
                    (event.target.className.includes("c-toggle") || event.target.className.includes("toggle-button"))
                ) {
                    return;
                }
                toggleCollapse();
            }}
            headerClassNameExtend="u-cursor--pointer"
            headerRender={(props) => (
                <AlbumPanelHeader {...props} nakiNigunArtistAlbumViewStore={nakiNigunArtistAlbumViewStore} />
            )}
        >
            {() => (
                <div className="row">
                    <div className="col col-sml-12 col-xlrg-3 u-mar--bottom--sml">
                        <p className="c-collapse-panel__body__label">
                            {t("APPLICATION.NAKI_NIGUN.ARTIST.ALBUM.FIELDS.GENRES")}
                        </p>
                        {album.genres}
                    </div>
                    <div className="col col-sml-12 col-xlrg-3 u-mar--bottom--sml">
                        <p className="c-collapse-panel__body__label">
                            {t("APPLICATION.NAKI_NIGUN.ARTIST.ALBUM.FIELDS.YEAR")}
                        </p>
                        {album.year}
                    </div>
                </div>
            )}
        </MrbCollapsiblePanel>
    );
}

NakiNigunArtistAlbumTemplate.propTypes = {
    nakiNigunArtistAlbumViewStore: PropTypes.object.isRequired,
};

const AlbumPanelHeader = observer(function AlbumPanelHeader({ nakiNigunArtistAlbumViewStore, isCollapsed, t }) {
    const {
        album,
        coverImageUrl,
        isTurnedOn,
        isDisabled,
        toggleAlbumStatus,
        contentAccessResult,
        rootStore: { userDeviceStore },
    } = nakiNigunArtistAlbumViewStore;

    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                <i
                    className={`u-icon u-icon--med u-icon--arrow-down ${
                        isCollapsed ? "u-rotate--180" : ""
                    } u-cursor--pointer u-mar--right--sml`}
                ></i>
                <div
                    className={mergeCss(
                        "c-home__preview__icon u-mar--right--sml",
                        !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                    )}
                >
                    <Image src={coverImageUrl} />
                </div>
                <div>
                    <div className="c-home__preview__title u-mar--bottom--none">
                        <p className={!contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null}>
                            {album.title}
                        </p>
                        {album.isPremium && !contentAccessResult.canAccessPremium && (
                            <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                        )}
                    </div>
                    {!contentAccessResult.canAccessOnSelectedDevice && <NakiNigunAlbumUnavailableInApp />}
                </div>
            </div>

            {userDeviceStore.deviceCount > 0 && contentAccessResult.canAccessOnSelectedDevice && (
                <div>
                    <TogglePremium
                        isPremium={album.isPremium}
                        canAccessPremiumContent={contentAccessResult.canAccessPremium}
                        t={t}
                    >
                        <MrbButton
                            id={`album-status-${album.id}`}
                            value={isTurnedOn}
                            disabled={isDisabled}
                            onAction={() => {
                                toggleAlbumStatus(!isTurnedOn);
                            }}
                            icon="u-icon u-icon--lrg u-icon--premium--white"
                            classNameOverride={mergeCss(
                                "c-btn c-btn--xsml c-btn--status c-btn--rounded",
                                isTurnedOn ? "c-btn--status--enabled" : "c-btn--status--disabled"
                            )}
                        />
                    </TogglePremium>
                </div>
            )}
        </React.Fragment>
    );
});

export default observer(NakiNigunArtistAlbumTemplate);
