import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbButton } from "mrb/components/button";
import { MrbModal } from "mrb/components/modal";
import { MrbContentLoader } from "mrb/components/loader";
import { applicationDefaults } from "common/constants";
import { mergeCss } from "mrb/common/utils";
import { CreditCardInfo, SubscriptionPrice, ChangeSubscriptionPlanLoader } from "application/payment/components";
import { currencyFormatter, dateFormatter } from "mrb/common/formatting";
import { subscriptionStatuses, subscriptionPlans } from "common/constants";
import { defaultTemplate } from "common/hoc";
import { map, isNil } from "lodash";

function ChangeSubscriptionPlanTemplate({ changeSubscriptionPlanViewStore, t }) {
    const {
        subscriptionChangePreview,
        changePlanSuccessModal,
        currentSubscription,
        isChangePlanDisabled,
        customerPaymentMethod,
        loaderStore,
        changePlan,
    } = changeSubscriptionPlanViewStore;

    return (
        <MrbPage>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <MrbRouterLink routeName="master.application.premium.subscriptions">
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        Subscriptions
                    </MrbRouterLink>
                </div>
            </MrbPageHeader>

            <div className="c-container--sml--alt">
                <div>
                    <h1 className="u-type--base u-mar--bottom--sml">Change Subscription Plan details</h1>
                    <div className="c-payment">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <ChangeSubscriptionPlanLoader />}
                        >
                            {() => (
                                <React.Fragment>
                                    <div className="c-payment__list c-payment__list--plan">
                                        <SubscriptionPlanCard
                                            subscriptionPlan={currentSubscription.product}
                                            price={currentSubscription.amount}
                                        />

                                        <div>
                                            <i className="u-icon u-icon--lrg u-icon--arrow-right--secondary"></i>
                                        </div>

                                        <SubscriptionPlanCard
                                            subscriptionPlan={subscriptionChangePreview.newSubscriptionPlan}
                                            isNew
                                        />
                                    </div>

                                    <div className="c-payment__card c-payment__card--full u-mar--top--med">
                                        <div className="u-mar--bottom--sml">
                                            <SubscriptionPolicies
                                                subscriptionChangePreview={subscriptionChangePreview}
                                                currentSubscription={currentSubscription}
                                                t={t}
                                            />
                                            <ProratedInvoicePreview
                                                subscriptionChangePreview={subscriptionChangePreview}
                                                invoicePreview={subscriptionChangePreview.proratedInvoicePreview}
                                            />
                                        </div>

                                        {!isNil(customerPaymentMethod) && (
                                            <div className="u-type--base">
                                                <p className="u-mar--bottom--tny">Payment method</p>
                                                <CreditCardInfo creditCard={customerPaymentMethod} t={t} />
                                            </div>
                                        )}

                                        <div className="u-separator--primary"></div>

                                        <div>
                                            <MrbButton
                                                t={t}
                                                disabled={isChangePlanDisabled}
                                                onClick={changePlan}
                                                className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold"
                                                label={`Activate ${subscriptionChangePreview.newSubscriptionPlan.name}`}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>
            </div>

            <UpgradeSuccessModal modalParams={changePlanSuccessModal} t={t} />
        </MrbPage>
    );
}

ChangeSubscriptionPlanTemplate.propTypes = {
    changeSubscriptionPlanViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function SubscriptionPlanCard({ subscriptionPlan, isNew = false, price }) {
    let subscriptionPlanColor = "";
    let subscriptionPlanIcon = "";
    switch (subscriptionPlan.slug) {
        case subscriptionPlans.nakiNigun:
            subscriptionPlanColor = "tertiary";
            subscriptionPlanIcon = "u-icon--plan--nigun";
            break;
        case subscriptionPlans.nakiRadioPremium:
            subscriptionPlanColor = "primary";
            subscriptionPlanIcon = "u-icon--plan--premium";
            break;
        default:
            subscriptionPlanColor = "tertiary";
    }
    return (
        <div className={`c-payment__card c-payment__card--plan c-payment__card--${subscriptionPlanColor}`}>
            <div>
                <i className={mergeCss("u-icon u-icon--plan--base", subscriptionPlanIcon)}></i>
            </div>
            <div>
                <p className="u-type--sml u-type--wgt--bold u-mar--bottom--tny">{isNew ? "New Plan" : "Old Plan"}</p>
                <p>{subscriptionPlan.name}</p>
                <p>
                    <SubscriptionPrice price={isNil(price) ? subscriptionPlan.price : price} />
                </p>
            </div>
        </div>
    );
}

function SubscriptionPolicies({ subscriptionChangePreview, currentSubscription, t }) {
    if (currentSubscription.subscriptionStatus.abrv === subscriptionStatuses.trial) {
        return createTrialSubscriptionPaymentPolicy(subscriptionChangePreview, currentSubscription, t);
    }
    return createSubscriptionPaymentPolicy(subscriptionChangePreview, currentSubscription, t);
}

function createSubscriptionPaymentPolicy(subscriptionChangePreview, currentSubscription, t) {
    const paymentPolicy = [];
    if (!subscriptionChangePreview.isUpgrade) {
        paymentPolicy.push(
            <p>
                Your <strong>{currentSubscription.product.name}</strong> subscription will remain active until{" "}
                {dateFormatter.format(subscriptionChangePreview.dateOfChange, {
                    format: applicationDefaults.formats.date,
                })}
                .
            </p>
        );
    }
    paymentPolicy.push(<SubscriptionStartDate subscriptionChangePreview={subscriptionChangePreview} t={t} />);
    paymentPolicy.push(
        <p className="u-mar--top--tny">
            Starting from
            <span className="u-type--wgt--bold">
                {" "}
                {dateFormatter.format(currentSubscription.nextBillingDate, {
                    format: applicationDefaults.formats.date,
                })}{" "}
            </span>
            we will charge you{" "}
            <span className="u-type--wgt--bold">
                <SubscriptionPrice price={subscriptionChangePreview.newSubscriptionPlan.price} /> for{" "}
                {subscriptionChangePreview.newSubscriptionPlan.name}
            </span>{" "}
            subscription.
        </p>
    );

    return paymentPolicy;
}

function createTrialSubscriptionPaymentPolicy(subscriptionChangePreview, currentSubscription, t) {
    const paymentPolicy = [];
    paymentPolicy.push(<p>Your new subscription starts today</p>);
    if (isNil(currentSubscription.endDate)) {
        paymentPolicy.push(
            <p>
                Starting from{" "}
                {dateFormatter.format(currentSubscription.nextBillingDate, {
                    format: applicationDefaults.formats.date,
                })}{" "}
                we will charge you <SubscriptionPrice price={subscriptionChangePreview.newSubscriptionPlan.price} /> for{" "}
                {subscriptionChangePreview.newSubscriptionPlan.name} subscription
            </p>
        );
    } else {
        paymentPolicy.push(
            <p>
                {subscriptionChangePreview.newSubscriptionPlan.name} Trial subscription will be active until{" "}
                {dateFormatter.format(currentSubscription.endDate, {
                    format: applicationDefaults.formats.date,
                })}
            </p>
        );
    }

    return paymentPolicy;
}

function SubscriptionStartDate({ subscriptionChangePreview, t }) {
    if (isNil(subscriptionChangePreview.dateOfChange)) {
        return <p>Your new subscription plan starts today</p>;
    }
    return (
        <p>
            Your new subscription plan starts on{" "}
            {dateFormatter.format(subscriptionChangePreview.dateOfChange, {
                format: applicationDefaults.formats.date,
            })}
            .
        </p>
    );
}

function ProratedInvoicePreview({ subscriptionChangePreview, invoicePreview }) {
    if (isNil(invoicePreview)) {
        return null;
    }
    if (invoicePreview.totalAmount === 0) {
        return null;
    }

    return (
        <div className="c-payment__card--info">
            <p className="u-mar--bottom--sml">
                We will immediately charge you{" "}
                {currencyFormatter.format(invoicePreview.totalAmount, {
                    currency: "USD",
                })}{" "}
                for upgrading to {subscriptionChangePreview.newSubscriptionPlan.name} subscription:
            </p>
            <div>
                {map(invoicePreview.lineItems, (lineItem, index) => {
                    return (
                        <div className={index === 0 ? "u-mar--bottom--sml" : ""}>
                            <p>{lineItem.description}</p>
                            <p className="u-type--wgt--bold">
                                {currencyFormatter.format(lineItem.amount, {
                                    currency: "USD",
                                })}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function UpgradeSuccessModal({ modalParams, t }) {
    const { params, data = {} } = modalParams;
    let title, description;
    let confirmLabel = t("APPLICATION.PREMIUM.CHANGE_PLAN.SUCCESS.CONFIRM_BUTTON", {
        subscriptionPlan: data.subscriptionPlan,
    });
    if (data.billingStartDate) {
        const billingStartDate = dateFormatter.format(data.billingStartDate, {
            format: applicationDefaults.formats.longDate,
        });
        title = t("APPLICATION.PREMIUM.CHANGE_PLAN.SUCCESS.TITLE");
        description = t("APPLICATION.PREMIUM.CHANGE_PLAN.SUCCESS.DESCRIPTION_FUTURE", {
            startDate: billingStartDate,
            subscriptionPlan: data.subscriptionPlan,
        });
    } else {
        title = t("APPLICATION.PREMIUM.CHANGE_PLAN.SUCCESS.TITLE");
        description = t("APPLICATION.PREMIUM.CHANGE_PLAN.SUCCESS.DESCRIPTION", {
            subscriptionPlan: data.subscriptionPlan,
        });
    }
    return (
        <MrbModal modalParams={modalParams} shouldCloseOnOverlayClick={false} displayCloseIcon={false}>
            <div>
                <div className="modal__icon">
                    <i className="u-icon u-icon--radio-lrg"></i>
                </div>
                <div className="modal__header">
                    <h2>{title}</h2>
                </div>
                <div className="modal__body">
                    <p>{description}</p>
                </div>
                <div className="modal__footer">
                    <MrbButton t={t} label={confirmLabel} onClick={params.onClickExplorePremium} />
                </div>
            </div>
        </MrbModal>
    );
}

export default defaultTemplate(ChangeSubscriptionPlanTemplate);
