import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbButton } from "mrb/components/button";
import { TogglePremium } from "application/home/components";
import { PodcastUnavailableInApp } from "application/common/components";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";
import { map, orderBy } from "lodash";

function TorahAnytimeCollectionListItemTemplate({ torahAnytimeCollectionListItemViewStore, actions, t }) {
    const { collection, isCollapsed, toggleCollapse, speakersLength, topicsLength } =
        torahAnytimeCollectionListItemViewStore;

    return (
        <MrbCollapsiblePanel
            title={collection.name}
            //innerRef={setPanelRef}
            t={t}
            isCollapsed={isCollapsed}
            onChangeCollapse={toggleCollapse}
            headerClassNameExtend="u-cursor--pointer"
            headerRender={(props) => (
                <PanelHeader
                    {...props}
                    store={torahAnytimeCollectionListItemViewStore}
                    item={collection}
                    actions={actions}
                />
            )}
        >
            {() => (
                <div>
                    {collection.speakers && speakersLength > 0 && (
                        <SpeakersList speakers={collection.speakers} speakersLength={speakersLength} />
                    )}
                    {collection.topics && topicsLength > 0 && (
                        <TopicsList topics={collection.topics} topicsLength={topicsLength} />
                    )}
                </div>
            )}
        </MrbCollapsiblePanel>
    );
}

TorahAnytimeCollectionListItemTemplate.propTypes = {
    torahAnytimeCollectionListItemViewStore: PropTypes.object.isRequired,
    onCellClick: PropTypes.func.isRequired,
    t: PropTypes.func,
};

const PanelHeader = observer(function PanelHeader({ title, store, t, isCollapsed, item, actions }) {
    const {
        isTurnedOn,
        isDisabled,
        toggleCollectionStatus,
        contentAccessResult,
        hasTopics,
        hasSpeakers,
        torahAnytimeViewStore: { torahAnytimePodcast },
        rootStore: { userDeviceStore },
    } = store;
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center u-width--100">
                <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                    <i
                        className={`u-icon u-icon--med u-icon--arrow-down ${
                            isCollapsed ? "u-rotate--180" : ""
                        } u-cursor--pointer`}
                    ></i>

                    <div className="u-display--flex u-display--flex--ai--center">
                        <div className="c-home__preview__title u-mar--bottom--none">
                            <p className={!contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null}>
                                {title}
                            </p>
                            {torahAnytimePodcast &&
                                torahAnytimePodcast.isPremium &&
                                !contentAccessResult.canAccessPremium && (
                                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                                )}
                        </div>
                        {!contentAccessResult.canAccessOnSelectedDevice && <PodcastUnavailableInApp />}
                    </div>
                </div>

                <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                    <div className="u-display--flex u-display--flex--ai--center u-mar--right--xlrg">
                        {(!hasTopics || !hasSpeakers) && (
                            <p className="u-type--sml u-type--color--warning--light">
                                {!hasSpeakers && !hasTopics
                                    ? t("APPLICATION.TORAH_ANYTIME.LIST.REMOVED_DATA.SPEAKERS_AND_TOPICS")
                                    : !hasSpeakers
                                    ? t("APPLICATION.TORAH_ANYTIME.LIST.REMOVED_DATA.SPEAKERS")
                                    : t("APPLICATION.TORAH_ANYTIME.LIST.REMOVED_DATA.TOPICS")}
                            </p>
                        )}
                    </div>
                    {userDeviceStore.deviceCount > 0 && contentAccessResult.canAccessOnSelectedDevice && (
                        <TogglePremium
                            isPremium={torahAnytimePodcast.isPremium}
                            canAccessPremiumContent={contentAccessResult.canAccessPremium}
                            t={t}
                        >
                            <MrbButton
                                id={`collection-status-${item.id}`}
                                value={isTurnedOn}
                                disabled={isDisabled}
                                onAction={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    toggleCollectionStatus(!isTurnedOn);
                                }}
                                icon="u-icon u-icon--med u-icon--premium--white"
                                classNameOverride={mergeCss(
                                    "c-btn c-btn--status--sml",
                                    isTurnedOn ? "c-btn--status--enabled" : "c-btn--status--disabled"
                                )}
                            />
                        </TogglePremium>
                    )}
                    {actions.delete && (
                        <MrbButton
                            classNameOverride="c-btn c-btn--base c-btn--ghost"
                            label="Delete"
                            t={t}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                actions.delete.onClick(item);
                            }}
                        />
                    )}
                    {actions.edit && (
                        <MrbButton
                            label="Edit"
                            t={t}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                actions.edit.onClick(item);
                            }}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
});

function SpeakersList({ speakers, speakersLength }) {
    speakers = orderBy(speakers, ["speaker.title", "speaker.firstName", "speaker.lastName"]);
    return (
        <div className="row u-mar--bottom--sml">
            <div className="col col-sml-12">
                <p className="u-type--color--opaque">Speakers ({speakersLength})</p>
            </div>
            <div className="col col-sml-12">
                {map(
                    speakers,
                    (speaker) =>
                        speaker?.speaker && (
                            <div key={speaker.id}>
                                {[
                                    speaker?.speaker?.title,
                                    speaker?.speaker?.firstName,
                                    speaker?.speaker?.lastName,
                                ].join(" ")}
                            </div>
                        )
                )}
            </div>
        </div>
    );
}

function TopicsList({ topics, topicsLength }) {
    topics = orderBy(topics, ["topic.title"]);
    return (
        <div className="row">
            <div className="col col-sml-12">
                <p className="u-type--color--opaque">Topics ({topicsLength})</p>
            </div>
            <div className="col col-sml-12">
                {map(topics, (topic) => topic?.topic && <div key={topic.id}>{topic?.topic?.title}</div>)}
            </div>
        </div>
    );
}

export default observer(TorahAnytimeCollectionListItemTemplate);
