import React from "react";
import { TorahAnytimeSelectSpeakersTemplate } from "themes/application/torah-anytime/components";
import { TorahAnytimeSelectSpeakersViewStore } from "application/torah-anytime/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TorahAnytimeSelectSpeakersViewStore(rootStore, componentProps),
    "torahAnytimeSelectSpeakersViewStore"
)
class TorahAnytimeSelectSpeakers extends React.Component {
    render() {
        return <TorahAnytimeSelectSpeakersTemplate {...this.props} />;
    }
}

export default TorahAnytimeSelectSpeakers;
