import { MrbBaseRouteStore } from "mrb/core";
import { TorahAnytimeService, UserTorahAnytimeCollectionService, UserContentService } from "common/services";

class TorahAnytimeWizardRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(TorahAnytimeService);
        this.userTorahAnytimeCollectionService = rootStore.createApplicationService(UserTorahAnytimeCollectionService);
        this.userContentService = rootStore.createApplicationService(UserContentService);
    }

    async findTopics(params, abortController) {
        const response = await this.service.findTopics(params, abortController);
        return response.data;
    }

    async findSpeakers(params, abortController) {
        const response = await this.service.findSpeakers(params, abortController);
        return response.data;
    }

    async findSpeakersByGender(filter) {
        const response = await this.service.findSpeakersByGender(filter);
        return response.data;
    }

    async findSpeakerIdsByTopicIds(filter) {
        const response = await this.service.findSpeakerIdsByTopicIds(filter);
        return response.data;
    }

    async createUserTorahAnytimeCollection(data) {
        const response = await this.userTorahAnytimeCollectionService.create(data);
        return response.data;
    }

    async updateUserTorahAnytimeCollection(data) {
        const response = await this.userTorahAnytimeCollectionService.update(data);
        return response.data;
    }

    async getUserTorahAnytimeCollection(id) {
        const response = await this.userTorahAnytimeCollectionService.get(id, {
            embed: "topics,topics.topic,speakers,speakers.speaker",
        });
        return response.data;
    }

    async getPodcast(podcastId) {
        const response = await this.userContentService.getPodcast(podcastId);
        return response.data;
    }
}

export default TorahAnytimeWizardRouteStore;
