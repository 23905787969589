import { MrbBaseViewStore } from "mrb/core";
import { observable, makeObservable, action, computed } from "mobx";
import { isNil } from "lodash";
import { NakiNigunBulkSelect } from "application/naki-nigun/models";

class NakiNigunAlbumBulkEditViewStore extends MrbBaseViewStore {
    @observable isUpdating = false;
    @observable bulkSelection = new NakiNigunBulkSelect();

    @computed get isSelectedItemsAny() {
        return !isNil(this.bulkSelection.isAllSelected) || this.bulkSelection.selectedIds.length > 0;
    }

    constructor(rootStore, nakiNigunHomeViewStore) {
        super(rootStore);
        makeObservable(this);
        this.nakiNigunHomeViewStore = nakiNigunHomeViewStore;
        this.routeStore = nakiNigunHomeViewStore.routeStore;
    }

    @action.bound
    selectAll() {
        this.bulkSelection.selectAll();
    }

    @action.bound
    deselectAll() {
        this.bulkSelection.deselectAll();
    }

    @action.bound
    selectItem(item) {
        if (this.isUpdating) {
            return;
        }

        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });

        if (contentAccessResult.canAccess) {
            this.bulkSelection.addSelectedItem(item.albumId);
        }
    }

    @action.bound
    deselectItem(item) {
        if (this.isUpdating) {
            return;
        }

        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });

        if (contentAccessResult.canAccess) {
            this.bulkSelection.addDeselectedItem(item.albumId);
        }
    }

    @action.bound
    async saveSelection() {
        if (!this.isUpdating) {
            this.setIsUpdating(true);
            try {
                this.rootStore.mainLoaderStore.suspend();
                const options = {
                    isAllSelected: this.bulkSelection.isAllSelected,
                    selectedAlbumIds: this.bulkSelection.selectedIds,
                    deselectedAlbumIds: this.bulkSelection.deselectedIds,
                };
                await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatusBulk(options);
                this.nakiNigunHomeViewStore.onAlbumBulkEditSuccess(this.bulkSelection);

                return true;
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );

                return false;
            } finally {
                this.setIsUpdating(false);
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    @action.bound
    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }

    @action.bound
    clearSelection() {
        this.bulkSelection = new NakiNigunBulkSelect();
    }
}

export default NakiNigunAlbumBulkEditViewStore;
