import React from "react";
import { TorahAnytimeTemplate } from "themes/application/torah-anytime/pages";
import { TorahAnytimeViewStore } from "application/torah-anytime/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TorahAnytimeViewStore(rootStore, componentProps),
    "torahAnytimeViewStore"
)
class TorahAnytime extends React.Component {
    render() {
        return <TorahAnytimeTemplate {...this.props} />;
    }
}

export default TorahAnytime;
