import { moduleProviderFactory } from "mrb/core";
import { UserProfileEdit } from "application/user-settings/pages";
import { UserProfileEditRouteStore } from "application/user-settings/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "UserSettings",
        routes: [
            {
                name: "master.application.user",
                pattern: "/userprofile",
                children: [
                    {
                        name: "master.application.user.edit",
                        component: UserProfileEdit,
                        store: UserProfileEditRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.SETTINGS",
                order: 6,
                icon: "settings",
                subMenu: [
                    {
                        title: "User Settings",
                        order: 1,
                        route: "master.application.user.edit",
                    },
                ],
            },
        ],
    });
})();
