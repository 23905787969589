import { moduleProviderFactory } from "mrb/core";
import {
    ActivateTrial,
    ChangeSubscriptionPlan,
    UpgradeTrial,
    TakePayment,
    UserSubscriptionsPreview,
    PremiumSubscriptions,
} from "application/payment/pages";
import {
    ActivateTrialRouteStore,
    ChangeSubscriptionPlanRouteStore,
    UpgradeTrialRouteStore,
    TakePaymentRouteStore,
    UserSubscriptionsPreviewRouteStore,
    PremiumSubscriptionsRouteStore,
} from "application/payment/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "application.premium",
        routes: [
            {
                name: "master.application.premium",
                pattern: "/premium",
                children: [
                    {
                        name: "master.application.premium.trial",
                        pattern: "/trial/:planId",
                        component: ActivateTrial,
                        store: ActivateTrialRouteStore,
                    },
                    {
                        name: "master.application.premium.payment",
                        pattern: "/payment/:planId",
                        component: TakePayment,
                        store: TakePaymentRouteStore,
                    },
                    {
                        name: "master.application.premium.trial.upgrade",
                        pattern: "/trial-upgrade/:subscriptionId",
                        component: UpgradeTrial,
                        store: UpgradeTrialRouteStore,
                    },
                    {
                        name: "master.application.premium.info",
                        pattern: "",
                        component: UserSubscriptionsPreview,
                        store: UserSubscriptionsPreviewRouteStore,
                        data: {
                            title: "APPLICATION.PREMIUM.MY_PLANS",
                        },
                    },
                    {
                        name: "master.application.premium.subscriptions",
                        pattern: "/subscriptions",
                        component: PremiumSubscriptions,
                        store: PremiumSubscriptionsRouteStore,
                        data: {
                            title: "APPLICATION.PREMIUM.TITLE",
                        },
                    },
                    {
                        name: "master.application.premium.change-plan.edit",
                        pattern: "/change-plan/:planId",
                        component: ChangeSubscriptionPlan,
                        store: ChangeSubscriptionPlanRouteStore,
                    },
                    // {
                    //     name: "master.application.premium.change-plan.preview",
                    //     pattern: "/change-plan",
                    //     component: ChangeSubscriptionPlanPreview,
                    //     store: ChangeSubscriptionPlanPreviewRouteStore,
                    // },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.SUBSCRIPTIONS",
                order: 5,
                icon: "subscriptions",
                subMenu: [
                    {
                        title: "Subscriptions",
                        order: 1,
                        route: "master.application.premium.subscriptions",
                    },
                    {
                        title: "My Plans",
                        order: 2,
                        route: "master.application.premium.info",
                    },
                ],
            },
        ],
    });
})();
