import { action, observable, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { DeviceCreateForm } from "application/device-settings/forms";
import { applicationErrorCodes } from "common/constants";
import { localizationService } from "common/localization";
import { MrbModalParams } from "mrb/common/models";

class DeviceCreateViewStore extends MrbBaseEditViewStore {
    @observable currentDeviceGuideImage = 1;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            name: "device-create",
            FormClass: DeviceCreateForm,
            autoFocusField: "deviceTemporaryCode",
            successCreateNotification: null,
            successUpdateNotification: null,
            navigateBack: "master.application.device.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.deviceIdInfoModal = new MrbModalParams();

        this.onClickDeviceIdInfo = this.onClickDeviceIdInfo.bind(this);
    }

    @action.bound
    deviceTemporaryCodeToUpper(inputValue) {
        this.form.$("deviceTemporaryCode").onChange(inputValue.toUpperCase());
    }

    create(resource) {
        if (!this.rootStore.userDeviceStore.isDeviceSlotAvailable) {
            this.rootStore.notificationStore.error(
                localizationService.t("APPLICATION.DEVICE_SETTINGS.CREATE.ERROR.MAXIMUM_NUMBER_OF_DEVICES_REACHED")
            );
            return;
        }
        return this.routeStore.registerUserDevice(resource);
    }

    onCreateError(error) {
        if (error.data.errorCode === applicationErrorCodes.device.notExists) {
            this.form
                .$("deviceTemporaryCode")
                .invalidate(localizationService.t("APPLICATION.DEVICE_SETTINGS.CREATE.ERROR.DEVICE_NOT_EXIST"));
        } else if (error.data.errorCode === applicationErrorCodes.device.alreadyRegistered) {
            this.form
                .$("deviceTemporaryCode")
                .invalidate(
                    localizationService.t("APPLICATION.DEVICE_SETTINGS.CREATE.ERROR.DEVICE_ALREADY_REGISTERED")
                );
        } else {
            this.rootStore.notificationStore.error(
                localizationService.t("APPLICATION.DEVICE_SETTINGS.CREATE.ERROR.GENERIC_MESSAGE")
            );
        }
    }

    @action.bound
    toggleCurrentDeviceGuideImage() {
        switch (this.currentDeviceGuideImage) {
            case 1:
                this.currentDeviceGuideImage = 2;
                break;
            case 2:
                this.currentDeviceGuideImage = 3;
                break;
            case 3:
                this.currentDeviceGuideImage = 1;
                break;
            default:
                break;
        }
    }

    onClickDeviceIdInfo() {
        this.deviceIdInfoModal.open();
    }
}

export default DeviceCreateViewStore;
