import { runInAction } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { TorahAnytimeCollectionEditForm } from "application/torah-anytime/forms";

class TorahAnytimeCollectionEditViewStore extends MrbBaseEditViewStore {
    constructor(rootStore, { routeStore, torahAnytimeWizardViewStore }) {
        super(rootStore, {
            id: torahAnytimeWizardViewStore.collectionId,
            name: "torah-anytime-collection",
            FormClass: TorahAnytimeCollectionEditForm,
            autoFocusField: "name",
            successCreateNotification: null,
            successUpdateNotification: null,
            redirectOnCreateSuccess: () =>
                this.rootStore.routerStore.goTo("master.application.home.torah-anytime", {
                    id: torahAnytimeWizardViewStore.podcastId,
                }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.application.home.torah-anytime", {
                    id: torahAnytimeWizardViewStore.podcastId,
                }),
        });
        this.routeStore = routeStore;
        this.torahAnytimeWizardViewStore = torahAnytimeWizardViewStore;
    }

    async initializeResource() {
        if (this.id) {
            runInAction(() => {
                const item = this.torahAnytimeWizardViewStore.item;
                this.setItem(item);
                this.updateForm(item);
            });
        } else {
            this.resetForm();
        }
    }

    create(data) {
        return this.torahAnytimeWizardViewStore.createCollection(data);
    }

    async update(data) {
        await this.torahAnytimeWizardViewStore.updateCollection(data);
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to create resource.", err);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }
}

export default TorahAnytimeCollectionEditViewStore;
