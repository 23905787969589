import { moduleProviderFactory } from "mrb/core";
import { MainLayout } from "common/layouts";
import { MainRouteStore } from "common/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "application",
        routes: [
            {
                name: "master.application",
                pattern: "",
                component: MainLayout,
                store: MainRouteStore,
                beforeEnter: (fromState, toState, routerStore) => {
                    const { userStore } = routerStore.rootStore;
                    if (userStore.isAdministrator) {
                        return Promise.reject(routerStore.notFoundState);
                    }
                },
            },
        ],
    });
})();
