import React from "react";
import { ProducerPreviewTemplate } from "themes/application/producer/pages";
import { ProducerPreviewViewStore } from "application/producer/stores";
import { setCurrentView } from "mrb/core";
@setCurrentView(
    (rootStore, componentProps) => new ProducerPreviewViewStore(rootStore, componentProps),
    "producerPreviewViewStore"
)
class ProducerPreview extends React.Component {
    render() {
        return <ProducerPreviewTemplate {...this.props} />;
    }
}

export default ProducerPreview;
