import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage } from "mrb/components/layout";
import { UserSubscriptionInfo, CustomerPaymentMethodInfo } from "application/payment/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";

function UserSubscriptionsPreviewTemplate({ userSubscriptionsPreviewViewStore, t }) {
    const { pendingSubscription } = userSubscriptionsPreviewViewStore;

    return (
        <MrbPreviewPage t={t} store={userSubscriptionsPreviewViewStore}>
            <div
                className={mergeCss(
                    "c-container--sidebar c-container--sidebar--med u-padd--none",
                    pendingSubscription ? "c-container--med--alt" : "c-container--med"
                )}
            >
                <div>
                    <SubscriptionPlan userSubscriptionsPreviewViewStore={userSubscriptionsPreviewViewStore} t={t} />
                </div>
                <div>
                    <CustomerPaymentMethodInfo t={t} />
                </div>
            </div>
        </MrbPreviewPage>
    );
}

UserSubscriptionsPreviewTemplate.propTypes = {
    userSubscriptionsPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const SubscriptionPlan = observer(function SubscriptionPlan({ userSubscriptionsPreviewViewStore, t }) {
    const { routeStore } = userSubscriptionsPreviewViewStore;
    return (
        <div>
            <h1 className="u-type--base u-mar--bottom--med">Subscription Plan</h1>
            <UserSubscriptionInfo
                userSubscriptionsPreviewViewStore={userSubscriptionsPreviewViewStore}
                routeStore={routeStore}
                t={t}
            />
        </div>
    );
});

export default defaultTemplate(UserSubscriptionsPreviewTemplate);
