import React from "react";
import { TorahAnytimeSelectTopicsTemplate } from "themes/application/torah-anytime/components";
import { TorahAnytimeSelectTopicsViewStore } from "application/torah-anytime/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TorahAnytimeSelectTopicsViewStore(rootStore, componentProps),
    "torahAnytimeSelectTopicsViewStore"
)
class TorahAnytimeSelectTopics extends React.Component {
    render() {
        return <TorahAnytimeSelectTopicsTemplate {...this.props} />;
    }
}

export default TorahAnytimeSelectTopics;
