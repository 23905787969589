import React from "react";
import PropTypes from "prop-types";
import { HomePageLayout } from "application/home/components";
import { defaultTemplate } from "common/hoc";

function TorahAnytimeWizardTemplate({ torahAnytimeWizardViewStore, t }) {
    const { currentStep, routeStore, torahAnytimeSelectionViewStore } = torahAnytimeWizardViewStore;
    return (
        <HomePageLayout store={torahAnytimeWizardViewStore} t={t}>
            {
                <currentStep.component
                    routeStore={routeStore}
                    torahAnytimeSelectionViewStore={torahAnytimeSelectionViewStore}
                    torahAnytimeWizardViewStore={torahAnytimeWizardViewStore}
                    t={t}
                />
            }
        </HomePageLayout>
    );
}

TorahAnytimeWizardTemplate.propTypes = {
    torahAnytimeWizardViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(TorahAnytimeWizardTemplate);
