import React from "react";
import { computed, observable, makeObservable, action, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { nakiRadioSystemTypes } from "common/constants";
import { find, isNil } from "lodash";

class NakiNigunArtistAlbumViewStore extends MrbBaseViewStore {
    @observable isCollapsed = false;
    @observable isUpdating = false;

    panelRef = React.createRef();
    albumId = null;

    @computed get coverImageUrl() {
        if (this.album && this.album.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(this.album.coverImageId, 50, 50);
        }

        return null;
    }

    @computed get contentAccessResult() {
        return this.evaluateContentAccess(this.album);
    }

    @computed get isDisabled() {
        return this.nakiNigunArtistViewStore.updatingStatus || this.isUpdating;
    }

    @computed get album() {
        if (this.nakiNigunArtistViewStore.item && this.nakiNigunArtistViewStore.item.albums) {
            return find(this.nakiNigunArtistViewStore.item.albums, (album) => album.id === this.albumId);
        }
        return null;
    }

    @computed get albumStatusItem() {
        if (this.album) {
            return this.album.userDeviceAlbum;
        }
        return null;
    }

    @computed get isTurnedOn() {
        if (!isNil(this.albumStatusItem)) {
            return this.albumStatusItem.isTurnedOn;
        } else if (this.album) {
            const selectedDevice = this.rootStore.userDeviceStore.selectedDevice;
            if (selectedDevice && selectedDevice.device && selectedDevice.device.systemType) {
                switch (selectedDevice.device.systemType.abrv) {
                    case nakiRadioSystemTypes.venice:
                        return this.album.isTurnedOnByDefault;
                    case nakiRadioSystemTypes.mobile:
                        return this.album.isTurnedOnByDefaultInApp;
                    case nakiRadioSystemTypes.mobile_embedded:
                        return this.album.isTurnedOnByDefaultInEmbeddedApp;
                    default:
                        break;
                }
            }
        }
        return false;
    }

    constructor(rootStore, { albumId, nakiNigunArtistViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.albumId = albumId;
        this.nakiNigunArtistViewStore = nakiNigunArtistViewStore;

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed && this.panelRef) {
                    // We need to execute scroll function asynchronously in order to take effect.
                    setTimeout(() => {
                        this.panelRef.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    });
                }
            }
        );
    }

    initialize() {
        const albumId = this.rootStore.routerStore.routerState.queryParams.albumId;
        if (albumId && this.album.id === albumId) {
            runInAction(() => {
                this.isCollapsed = true;
            });
        }
        return Promise.resolve();
    }

    @action.bound
    async toggleAlbumStatus(isTurnedOn) {
        this.isCollapsed = !this.isCollapsed;
        this.setIsUpdating(true);
        await this.nakiNigunArtistViewStore.updateAlbumTurnedOnStatus(this.albumId, isTurnedOn);
        this.setIsUpdating(false);
    }

    @action.bound
    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    evaluateContentAccess(item) {
        if (isNil(item)) {
            return false;
        }
        const artist = this.nakiNigunArtistViewStore.item;
        return this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: artist.isVisibleInApp === false,
            unavailableInNakiTouch: artist.isVisibleInEmbeddedApp === false,
        });
    }
}

export default NakiNigunArtistAlbumViewStore;
