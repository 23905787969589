import { action, computed, makeObservable, override, runInAction } from "mobx";
import { BaseHomePreviewViewStore } from "application/home/stores";
import { UpdateNakiNigunAlbumStatusOptions } from "application/common/models";
import { forEach, find, findIndex, isNil } from "lodash";

class NakiNigunArtistViewStore extends BaseHomePreviewViewStore {
    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(
                this.item.coverImageId,
                200,
                200
            );
        }

        return null;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            navigateBack: "master.application.naki-nigun.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async get(id) {
        if (this.rootStore.userDeviceStore.selectedDevice) {
            return this.routeStore.getNakiNigunArtistWthDeviceSettings(
                id,
                this.rootStore.userDeviceStore.selectedDevice.id
            );
        }
        return this.routeStore.getNakiNigunArtist(id);
    }

    @action.bound
    async updateAlbumTurnedOnStatus(albumId, isTurnedOn) {
        try {
            this.statusToggleLoader.suspend();
            const albumIndex = findIndex(this.item.albums, (album) => album.id === albumId);
            const options = new UpdateNakiNigunAlbumStatusOptions(
                albumId,
                this.item.albums[albumIndex].title,
                this.item.albums[albumIndex].isPremium,
                this.item.albums[albumIndex].isVisibleInApp === false,
                this.item.albums[albumIndex].isVisibleInEmbeddedApp === false
            );
            await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatus(options, isTurnedOn);
            runInAction(() => {
                if (this.item.albums[albumIndex].userDeviceAlbum) {
                    this.item.albums[albumIndex].userDeviceAlbum.isTurnedOn = isTurnedOn;
                } else {
                    this.item.albums[albumIndex].userDeviceAlbum = { isTurnedOn: isTurnedOn };
                }
            });
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        } finally {
            this.statusToggleLoader.resume();
        }
    }

    getStatusItem(deviceId, resourceId) {
        return this.routeStore.getUserDeviceAlbumsByArtist(deviceId, resourceId);
    }

    @override
    setStatusItem(statusItem) {
        forEach(this.item.albums, (album) => {
            if (statusItem && statusItem.length > 0) {
                album.userDeviceAlbum = find(statusItem, (item) => item.albumId === album.id);
            } else {
                album.userDeviceAlbum = null;
            }
        });
    }

    evaluateContentAccess(item) {
        if (isNil(item)) {
            return false;
        }
        return this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
            unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
        });
    }
}

export default NakiNigunArtistViewStore;
