import { LocalizedForm } from "common/localization";

export default class ProducerRevenueTransferForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "amount",
                    label: "APPLICATION.PRODUCER.REVENUE_TRANSFER.FIELDS.AMOUNT_LABEL",
                    placeholder: "APPLICATION.PRODUCER.REVENUE_TRANSFER.FIELDS.AMOUNT_PLACEHOLDER",
                    rules: "required|numeric|min:1",
                },
            ],
        };
    }
}
