import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { sideScroll } from "common/utils";
import { UpdateNakiNigunAlbumStatusOptions, UpdateNakiNigunArtistStatusOptions } from "application/common/models";
import { map, find, findIndex, isNil, forEach } from "lodash";
import { NakiNigunArtist, NakiNigunAlbum, nakiNigunContentType } from "application/naki-nigun/models";

class NakiNigunHomeFeaturedContentViewStore extends MrbBaseViewStore {
    @observable.ref items = [];
    @observable isFeaturedScrollable = false;
    @observable.ref featuredContainerRef = null;

    @computed get selectedDevice() {
        return this.rootStore.userDeviceStore.selectedDevice;
    }

    constructor(rootStore, nakiNigunHomeViewStore) {
        super(rootStore);
        makeObservable(this);
        this.nakiNigunHomeViewStore = nakiNigunHomeViewStore;
        this.routeStore = nakiNigunHomeViewStore.routeStore;

        this.reaction(
            () => ({
                groups: this.items,
                container: this.featuredContainerRef,
            }),
            (context) => {
                if (context.container) {
                    setTimeout(() => {
                        runInAction(() => {
                            this.isFeaturedScrollable = context.container.scrollWidth > context.container.clientWidth;
                        });
                    });
                }
            }
        );

        this.reaction(
            () => ({
                deviceId: this.selectedDevice && this.selectedDevice.id,
                contentType: this.nakiNigunHomeViewStore.queryUtility.filter.contentType,
            }),
            (context) => {
                this.fetchResources(context);
            }
        );
    }

    onInit() {
        let deviceId;
        if (this.selectedDevice) {
            deviceId = this.selectedDevice.id;
        }
        return this.fetchResources({ deviceId: deviceId });
    }

    getFeaturedArtists(deviceId) {
        return this.routeStore.getFeaturedArtists(deviceId);
    }

    getFeaturedAlbums(deviceId) {
        return this.routeStore.getFeaturedAlbums(deviceId);
    }

    async fetchResources({ deviceId }) {
        this.loaderStore.suspend();
        try {
            if (this.nakiNigunHomeViewStore.queryUtility.filter.contentType === nakiNigunContentType.artists) {
                const artists = await this.getFeaturedArtists(deviceId);
                this.setFeaturedArtists(artists);
            } else {
                const albums = await this.getFeaturedAlbums(deviceId);
                this.setFeaturedAlbums(albums);
            }
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying to load featured content. Please contact the support team.",
                err
            );
        } finally {
            this.loaderStore.resume();
        }
    }

    @action.bound
    onAlbumTurnedOnStatusChange(changedAlbum) {
        const album = find(this.items, (item) => item.albumId === changedAlbum.albumId);
        if (album && album.isTurnedOn !== changedAlbum.isTurnedOn) {
            album.isTurnedOn = changedAlbum.isTurnedOn;
        }
    }

    @action.bound
    onArtistTurnedOnStatusChange(changedArtist) {
        const artist = find(this.items, (item) => item.artistId === changedArtist.artistId);
        if (artist && artist.isTurnedOn !== changedArtist.isTurnedOn) {
            artist.isTurnedOn = changedArtist.isTurnedOn;
        }
    }

    goTo = (item) => {
        return this.nakiNigunHomeViewStore.goTo(item);
    };

    onClickCellItem = async (item) => {
        const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
            isPremium: item.isPremium,
            unavailableInMobileApp: item.isVisibleInApp === false,
        });
        if (contentAccessResult.canAccess) {
            if (item instanceof NakiNigunArtist) {
                await this.toggleFeaturedArtistTurnedOn(item, this.selectedDevice.id);
            } else if (item instanceof NakiNigunAlbum) {
                await this.toggleFeaturedAlbumTurnedOn(item, this.selectedDevice.id);
            }
        }
    };

    async toggleFeaturedAlbumTurnedOn(item, deviceId) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateNakiNigunAlbumStatusOptions(
                    item.albumId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false
                );
                await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatus(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    this.nakiNigunHomeViewStore.onFeaturedAlbumTurnedOnStatusChange(item);
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    async toggleFeaturedArtistTurnedOn(item, deviceId) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateNakiNigunArtistStatusOptions(
                    item.artistId,
                    item.name,
                    item.isPremium,
                    item.isVisibleInApp === false
                );
                await this.rootStore.userContentManagerStore.updateNakiNigunAlbumsStatusByArtist(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    this.nakiNigunHomeViewStore.onFeaturedArtistTurnedOnStatusChange(item);
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    @action.bound
    onFeaturedArtistBulkEditSuccess(artistsBulkEdit) {
        forEach(this.items, (artist) => {
            const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
                isPremium: artist.isPremium,
                unavailableInMobileApp: artist.isVisibleInApp === false,
            });
            if (contentAccessResult.canAccess) {
                const selectedIndex = findIndex(
                    artistsBulkEdit.selectedIds,
                    (selectedId) => selectedId === artist.artistId
                );
                if (selectedIndex !== -1) {
                    artist.isTurnedOn = true;
                    return;
                }
                const deselectedIndex = findIndex(
                    artistsBulkEdit.deselectedIds,
                    (deselectedId) => deselectedId === artist.artistId
                );
                if (deselectedIndex !== -1) {
                    artist.isTurnedOn = false;
                    return;
                }
                if (!isNil(artistsBulkEdit.isAllSelected)) {
                    artist.isTurnedOn = artistsBulkEdit.isAllSelected;
                }
            }
        });
    }

    @action.bound
    onFeaturedAlbumBulkEditSuccess(albumsBulkEdit) {
        forEach(this.items, (album) => {
            const contentAccessResult = this.rootStore.contentAccessValidator.canAccessNakiNigun({
                isPremium: album.isPremium,
                unavailableInMobileApp: album.isVisibleInApp === false,
            });
            if (contentAccessResult.canAccess) {
                const selectedIndex = findIndex(
                    albumsBulkEdit.selectedIds,
                    (selectedId) => selectedId === album.albumId
                );
                if (selectedIndex !== -1) {
                    album.isTurnedOn = true;
                    return;
                }

                const deselectedIndex = findIndex(
                    albumsBulkEdit.deselectedIds,
                    (deselectedId) => deselectedId === album.albumId
                );
                if (deselectedIndex !== -1) {
                    album.isTurnedOn = false;
                    return;
                }

                if (!isNil(albumsBulkEdit.isAllSelected)) {
                    album.isTurnedOn = albumsBulkEdit.isAllSelected;
                }
            }
        });
    }

    @action.bound
    resetItems() {
        this.items = [...this.items];
    }

    @action.bound
    setFeaturedArtists(items) {
        this.items = map(items, (item) => {
            return new NakiNigunArtist({
                ...item,
                coverImageUrl: this.nakiNigunHomeViewStore.createArtistCoverImageUrl(item),
            });
        });
    }

    @action.bound
    setFeaturedAlbums(items) {
        this.items = map(items, (item) => {
            return new NakiNigunAlbum({
                ...item,
                coverImageUrl: this.nakiNigunHomeViewStore.createAlbumCoverImageUrl(item),
            });
        });
    }

    @action.bound
    setFeaturedContainerRef(ref) {
        this.featuredContainerRef = ref;
    }

    @action.bound
    scrollFeaturedLeft() {
        sideScroll(this.featuredContainerRef, "left", 20, 400, 20);
    }

    @action.bound
    scrollFeaturedRight() {
        sideScroll(this.featuredContainerRef, "right", 20, 400, 20);
    }
}

export default NakiNigunHomeFeaturedContentViewStore;
