import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";

function TorahAnytimeAvailabilityModalTemplate({ modalParams, t }) {
    return (
        <MrbModal modalParams={modalParams} t={t} shouldCloseOnOverlayClick={false}>
            <MrbModal modalParams={modalParams} className="modal__content modal__content--secondary">
                <div>
                    <div className="modal__header">
                        <h2>TorahAnytime Availability</h2>
                    </div>
                    <div className="modal__body">
                        <p className="u-type--base u-mar--bottom--sml">
                            The TorahAnytime feature is available on the following Naki devices:
                        </p>
                        <ul className="u-type--base">
                            <li>NakiRadio Solo</li>
                            <li>NakiRadio Duo</li>
                            <li>NakiRadio (2023)</li>
                            {/* <li>Naki Go App</li>  */}
                        </ul>
                    </div>
                    <div className="modal__footer--secondary">
                        <MrbButton
                            t={t}
                            label="Don't show again"
                            onClick={modalParams.params.disableTorahAnytimeAvailabilityModal}
                            classNameOverride="c-btn c-btn--base c-btn--anchor c-btn--anchor--underline"
                        />
                        <MrbButton t={t} label="Close" onClick={modalParams.close} />
                    </div>
                </div>
            </MrbModal>
        </MrbModal>
    );
}

TorahAnytimeAvailabilityModalTemplate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default TorahAnytimeAvailabilityModalTemplate;
