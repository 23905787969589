import React from "react";
import { PropTypes } from "prop-types";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { Image } from "common/components";

function NakiNigunSearchResultGridCellTemplate({ item, onCellClick }) {
    const canAccessContent = useCanAccessContent(nakiRadioContentType.nakiNigun, {
        isPremium: item.isPremium,
    });
    return (
        <div onClick={() => onCellClick(item)} className="c-home__preview__item c-home__preview__item--search">
            <div className="c-home__preview__icon u-mar--right--sml">
                <Image src={item.coverImageUrl} />
            </div>
            <div>
                <div className="c-home__preview__title">
                    {item.title}{" "}
                    {item.isPremium && !canAccessContent && (
                        <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                    )}
                </div>
                {item.artistName && (
                    <div className="c-home__preview__desc c-home__preview__desc--primary">{item.artistName}</div>
                )}
            </div>
        </div>
    );
}

NakiNigunSearchResultGridCellTemplate.propTypes = {
    item: PropTypes.object.isRequired,
};

export default NakiNigunSearchResultGridCellTemplate;
