import React from "react";
import { PropTypes } from "prop-types";
import { HomePageLayout, ConnectDeviceModal } from "application/home/components";
import {
    NakiNigunHomePageHeader,
    NakiNigunHomeMainView,
    NakiNigunHomeSearchResults,
} from "application/naki-nigun/components";
import { MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function NakiNigunHomeTemplate({ nakiNigunHomeViewStore, t }) {
    const { connectDeviceModal } = nakiNigunHomeViewStore;
    return (
        <HomePageLayout store={nakiNigunHomeViewStore} t={t}>
            <div>
                <MrbPageHeader classNameExtend="c-page__header--secondary">
                    <NakiNigunHomePageHeader nakiNigunHomeViewStore={nakiNigunHomeViewStore} t={t} />
                </MrbPageHeader>
                <ResultsContainer nakiNigunHomeViewStore={nakiNigunHomeViewStore} t={t} />
            </div>
            <ConnectDeviceModal
                modalParams={connectDeviceModal}
                description="APPLICATION.NAKI_NIGUN.CONNECT_DEVICE.DESCRIPTION"
                t={t}
            />
        </HomePageLayout>
    );
}

NakiNigunHomeTemplate.propTypes = {
    nakiNigunHomeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const ResultsContainer = observer(function ResultsContainer({ nakiNigunHomeViewStore, t }) {
    const { inSearchMode, nakiNigunGenericSearchViewStore } = nakiNigunHomeViewStore;
    return (
        <React.Fragment>
            {inSearchMode ? (
                <NakiNigunHomeSearchResults nakiNigunGenericSearchViewStore={nakiNigunGenericSearchViewStore} t={t} />
            ) : (
                <NakiNigunHomeMainView nakiNigunHomeViewStore={nakiNigunHomeViewStore} t={t} />
            )}
        </React.Fragment>
    );
});

export default defaultTemplate(NakiNigunHomeTemplate);
