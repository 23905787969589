import React from "react";
import PropTypes from "prop-types";
import { ProducerReportsTable } from "common/components/producer";
import { TableNoDataOverlay } from "common/components";
import { ProducerRevenueLoader, ProducerSummaryPreview } from "application/producer/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbContentLoader } from "mrb/components/loader";
import { ProducerAccountPreviewBalance, ProducerAccountPreviewRevenueTransaction } from "common/components/producer";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { MrbButton } from "baasic-react-bootstrap/src";
import Tabs from "common/components/tabs/Tabs";

function ProducerRevenueTemplate({ producerRevenueViewStore, t }) {
    const { reportsGridStore, revenueGridStore, balanceGridStore, producers, selectedProducerId, selectProducer } =
        producerRevenueViewStore;

    return (
        <div className="c-container--sidebar u-padd--none">
            <div className="u-overflow--hidden">
                <div className="u-mar--bottom--sml">
                    <div>
                        <h1 className="u-type--base u-mar--bottom--sml">
                            {t("APPLICATION.PRODUCER.REVENUE.REPORTS_TITLE")}
                        </h1>
                        {producers && producers.length > 1 && (
                            <div className="u-padd--bottom--sml">
                                <Tabs
                                    id="producer-radio-buttons"
                                    options={producers}
                                    value={selectedProducerId}
                                    onChange={(value) => {
                                        selectProducer(value);
                                    }}
                                />
                            </div>
                        )}
                        <div className="u-mar--bottom--sml">
                            <ProducerReportsTable
                                gridStore={reportsGridStore}
                                emptyStateRenderer={TableNoDataOverlay}
                                t={t}
                            />
                        </div>
                    </div>
                </div>

                <div className="u-mar--bottom--sml">
                    <ProducerAccountPreviewRevenueTransaction
                        title="APPLICATION.PRODUCER.REVENUE.REVENUE_TRANSACTIONS_TITLE"
                        gridStore={revenueGridStore}
                        emptyStateRenderer={TableNoDataOverlay}
                        t={t}
                    />
                </div>
                <ProducerAccountPreviewBalance
                    title="APPLICATION.PRODUCER.REVENUE.ACCOUNT_BALANCE_TITLE"
                    gridStore={balanceGridStore}
                    emptyStateRenderer={TableNoDataOverlay}
                    t={t}
                />
            </div>

            <div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                        <ProducerSummaryPreview producerRevenueViewStore={producerRevenueViewStore} />
                    </div>
                </div>

                <Actions producerRevenueViewStore={producerRevenueViewStore} t={t} />
            </div>
        </div>
    );
}

ProducerRevenueTemplate.propTypes = {
    producerRevenueViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const Actions = observer(function Actions({ producerRevenueViewStore, t }) {
    const {
        completedOnboarding,
        isStripeAccountConnected,
        createStripeAccount,
        stripeAccountLink,
        isCreateStripeAccountButtonDisabled,
        loaderStore,
    } = producerRevenueViewStore;
    return (
        <div>
            <h1 className="u-type--base u-mar--bottom--sml">Actions</h1>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ProducerRevenueLoader />}>
                    {() => (
                        <div className="row">
                            <div className="col col-sml-12 col-med-6 col-lrg-12">
                                {completedOnboarding && (
                                    <MrbRouterLink
                                        routeName="master.application.producer.payout"
                                        className="c-btn c-btn--base c-btn--primary c-btn--full"
                                    >
                                        Pay out balance
                                    </MrbRouterLink>
                                )}
                                {!completedOnboarding && !isStripeAccountConnected && (
                                    <div>
                                        <MrbButton
                                            t={t}
                                            label="Link bank account"
                                            onClick={createStripeAccount}
                                            aria-busy={isCreateStripeAccountButtonDisabled}
                                            className="c-btn c-btn--base c-btn--primary c-btn--full u-mar--bottom--xsml"
                                            disabled={isCreateStripeAccountButtonDisabled}
                                        />
                                    </div>
                                )}
                                {!completedOnboarding && isStripeAccountConnected && (
                                    <div>
                                        <p className="u-type--base u-mar--bottom--sml">
                                            Please click button below to complete account creation
                                        </p>
                                        <MrbButton
                                            t={t}
                                            label="Complete"
                                            onClick={() => (window.location.href = stripeAccountLink)}
                                            className="c-btn c-btn--base c-btn--primary c-btn--full u-mar--bottom--xsml"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
});

export default defaultTemplate(ProducerRevenueTemplate);
