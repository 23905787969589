import React from "react";
import { ProducerRevenueTransferTemplate } from "themes/application/producer/pages";
import { ProducerRevenueTransferViewStore } from "application/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ProducerRevenueTransferViewStore(rootStore, componentProps),
    "producerRevenueTransferViewStore"
)
class ProducerRevenueTransfer extends React.Component {
    render() {
        return <ProducerRevenueTransferTemplate {...this.props} />;
    }
}

export default ProducerRevenueTransfer;
