import { MrbBaseRouteStore } from "mrb/core";
import { UserTorahAnytimeCollectionService, UserContentService } from "common/services";

class TorahAnytimeRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(UserTorahAnytimeCollectionService);
        this.userContentService = rootStore.createApplicationService(UserContentService);
    }

    async findUserTorahAnytimeCollections(deviceId, params) {
        const response = await this.service.findWthDeviceSettings(deviceId, params);
        return response.data;
    }

    async deleteUserTorahAnytimeCollection(item) {
        const response = await this.service.delete(item);
        return response.data;
    }

    async getPodcast(podcastId) {
        const response = await this.userContentService.getPodcast(podcastId);
        return response.data;
    }
}

export default TorahAnytimeRouteStore;
