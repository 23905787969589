import { action, makeObservable, observable, computed } from "mobx";
import { MrbSimpleSelectStore } from "baasic-react-bootstrap/src";
import moment from "moment";
import { find } from "lodash";
import "moment-timezone";

const timeRangeType = {
    today: "today",
    yesterday: "yesterday",
    last3Days: "last-3-days",
    thisWeek: "this-week",
    lastWeek: "last-week",
    thisMonth: "this-month",
    lastMonth: "last-month",
    custom: "custom",
};

const defaultDropdownOptions = [
    { label: "Custom", value: timeRangeType.custom },
    { label: "Today", value: timeRangeType.today },
    { label: "Yesterday", value: timeRangeType.yesterday },
    { label: "Last 3 days", value: timeRangeType.last3Days },
    { label: "This Week", value: timeRangeType.thisWeek },
    { label: "Last Week", value: timeRangeType.lastWeek },
    { label: "This Month", value: timeRangeType.thisMonth },
    { label: "Last Month", value: timeRangeType.lastMonth },
];

const timeZone = moment.tz.guess();

class ProducerDashboardTimeRangeViewStore {
    @observable filter = {
        to: moment().utc().tz(timeZone).endOf("week").toISOString(),
        from: moment().utc().tz(timeZone).startOf("week").toISOString(),
        previousCycleTo: moment().utc().tz(timeZone).subtract(1, "week").endOf("week").toISOString(),
        previousCycleFrom: moment().utc().tz(timeZone).subtract(1, "week").startOf("week").toISOString(),
        timeZone: timeZone,
    };

    maxDate = null;
    fetchTrendingAlbums = null;
    fetchTrendingPodcasts = null;

    @computed get isDateRangeDisabled() {
        return this.timeRangeSelectStore.value !== timeRangeType.custom;
    }

    @computed get minDate() {
        return moment(this.filter.from).toDate();
    }

    constructor(rootStore, { setDateFilter }) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.maxDate = moment().startOf("day").toDate();
        this.setDateFilter = setDateFilter;

        this.timeRangeSelectStore = new MrbSimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                actions: {
                    onChange: async (newOption) => {
                        this.timeRangeSelectStore.setSelectedItem(
                            find(defaultDropdownOptions, (option) => option.value === newOption.value)
                        );
                        if (newOption.value !== timeRangeType.custom) {
                            this.updateFilter(newOption.value);
                        }
                    },
                },
            },
            defaultDropdownOptions
        );
        this.timeRangeSelectStore.setSelectedItem(
            find(defaultDropdownOptions, (option) => option.value === timeRangeType.thisWeek)
        );
    }

    @action.bound
    updateFilter(value) {
        const now = moment().utc().tz(timeZone);
        switch (value) {
            case timeRangeType.today:
                this.filter = {
                    to: moment(now).endOf("day").toISOString(),
                    from: moment(now).startOf("day").toISOString(),
                    previousCycleTo: moment(now).subtract(1, "day").endOf("day").toISOString(),
                    previousCycleFrom: moment(now).subtract(1, "day").startOf("day").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.yesterday:
                this.filter = {
                    to: moment(now).subtract(1, "day").endOf("day").toISOString(),
                    from: moment(now).subtract(1, "day").startOf("day").toISOString(),
                    previousCycleTo: moment(now).subtract(2, "day").endOf("day").toISOString(),
                    previousCycleFrom: moment(now).subtract(2, "day").startOf("day").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.last3Days:
                this.filter = {
                    to: moment(now).endOf("day").toISOString(),
                    from: moment(now).subtract(2, "days").startOf("day").toISOString(),
                    previousCycleTo: moment(now).subtract(2, "days").endOf("day").toISOString(),
                    previousCycleFrom: moment(now).subtract(4, "days").startOf("day").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.thisWeek:
                this.filter = {
                    to: moment(now).endOf("week").toISOString(),
                    from: moment(now).startOf("week").toISOString(),
                    previousCycleTo: moment(now).subtract(1, "week").endOf("week").toISOString(),
                    previousCycleFrom: moment(now).subtract(1, "week").startOf("week").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.lastWeek:
                this.filter = {
                    to: moment(now).subtract(1, "week").endOf("week").toISOString(),
                    from: moment(now).subtract(1, "week").startOf("week").toISOString(),
                    previousCycleTo: moment(now).subtract(2, "weeks").endOf("week").toISOString(),
                    previousCycleFrom: moment(now).subtract(2, "weeks").startOf("week").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.thisMonth:
                this.filter = {
                    to: moment(now).endOf("month").toISOString(),
                    from: moment(now).startOf("month").toISOString(),
                    previousCycleTo: moment(now).subtract(1, "month").endOf("month").toISOString(),
                    previousCycleFrom: moment(now).subtract(1, "month").startOf("month").toISOString(),
                    timeZone: timeZone,
                };
                break;

            case timeRangeType.lastMonth:
                this.filter = {
                    to: moment(now).subtract(1, "month").endOf("month").toISOString(),
                    from: moment(now).subtract(1, "month").startOf("month").toISOString(),
                    previousCycleTo: moment(now).subtract(2, "months").endOf("month").toISOString(),
                    previousCycleFrom: moment(now).subtract(2, "months").startOf("month").toISOString(),
                    timeZone: timeZone,
                };
                break;
            default:
                break;
        }
        this.setDateFilter(this.filter);
    }

    @action.bound
    setFromDate(value) {
        this.filter.from = moment.utc(value).tz(this.filter.timeZone).startOf("day").toISOString();
    }

    @action.bound
    setToDate(value) {
        this.filter.to = moment.utc(value).tz(this.filter.timeZone).endOf("day").toISOString();
    }

    @action.bound
    applyFilter() {
        this.setDateFilter(this.filter);
    }
}

export default ProducerDashboardTimeRangeViewStore;
