import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { SubscriptionPlanCard } from "application/payment/components";
import { defaultTemplate } from "common/hoc";
import { map, isNil } from "lodash";

function ChangeSubscriptionPlanPreviewTemplate({ changeSubscriptionPlanPreviewViewStore, t }) {
    const { subscriptionPlans, subscription, loaderStore } = changeSubscriptionPlanPreviewViewStore;
    return (
        <MrbPage>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <MrbRouterLink routeName="master.application.premium.subscriptions">
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        Subscription
                    </MrbRouterLink>
                </div>
            </MrbPageHeader>

            <div className="row">
                {map(subscriptionPlans, (subscriptionPlan) => {
                    return (
                        <SubscriptionPlanCard
                            isPremiumTrialPeriodUsed={true}
                            subscriptionPlan={subscriptionPlan}
                            loaderStore={loaderStore}
                            isActive={!isNil(subscription) && subscription.product.id === subscriptionPlan.id}
                            key={subscriptionPlan.id}
                        />
                    );
                })}
            </div>
        </MrbPage>
    );
}

ChangeSubscriptionPlanPreviewTemplate.propTypes = {
    changeSubscriptionPlanPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(ChangeSubscriptionPlanPreviewTemplate);
