import { useEffect, useState } from "react";

const useElementScrollPosition = (elem) => {
    const [elemScrollPosition, setElemScrollPosition] = useState(0);

    useEffect(() => {
        if (!elem) return;

        const updatePosition = () => {
            setElemScrollPosition(elem.scrollLeft);
        }

        elem.addEventListener("scroll", updatePosition);

        updatePosition();

        return () => elem.removeEventListener("scroll", updatePosition);
    }, [elem]);

    return elemScrollPosition;
};

export default useElementScrollPosition;