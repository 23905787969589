import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const ProducerTotalLoaderTemplate = () => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div
            className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--sml u-type--base c-card--primary"
            ref={ref}
        >
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} 24`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
            >
                <rect x="0" y="0" rx="6" ry="6" width="240" height="24" />
                <rect x={loaderWidth - 15} y="0" rx="6" ry="6" width="15" height="24" />
            </ContentLoader>
        </div>
    );
};

export default ProducerTotalLoaderTemplate;
