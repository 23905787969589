import { computed, action, makeObservable, observable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { ProducerRevenueTransferForm } from "application/producer/forms";
import { MrbModalParams } from "mrb/common/models";
import { applicationErrorCodes } from "common/constants";
import { currencyFormatter } from "mrb/common/formatting";
import { isString } from "lodash";

class ProducerRevenueTransferViewStore extends MrbBaseViewStore {
    onSubmit = null;
    @observable.ref producerUserAccount = null;

    @computed get currentAccountBalance() {
        if (this.producerUserAccount) {
            return this.producerUserAccount.accountBalance;
        }
        return 0;
    }

    @computed get newAccountBalance() {
        let amount = this.form.$("amount").value || 0;
        if (isString(amount)) {
            amount = parseFloat(amount);
        }

        return Math.max(this.currentAccountBalance - amount, 0);
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.form = new ProducerRevenueTransferForm({
            onSuccess: (form) => {
                const item = form.values();
                if (isString(item.amount)) {
                    item.amount = parseFloat(item.amount);
                }
                return this.transferRevenue(item);
            },
        });

        this.transferRevenueSuccessModal = new MrbModalParams(null, {
            onClickGoToProducerAccount: this.goToProducerAccount,
        });
    }

    async onInit() {
        await this.getProducerUserAccount();
    }

    @action.bound
    async transferRevenue(resource) {
        const amount = resource.amount;
        if (amount > this.currentAccountBalance) {
            this.form.invalidate("Insufficient amount in your account.");
            return;
        }

        const formattedPrice = currencyFormatter.format(resource.amount, {
            currency: "USD",
        });
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Transfer revenue?",
            description: `${formattedPrice} will be transferred to your Stripe Connect account.`,
            yesLabel: `Transfer ${formattedPrice}`,
            onConfirm: async () => {
                try {
                    await this.routeStore.processPayoutToBankAccount(
                        this.producerUserAccount.producerUserAccountId,
                        resource
                    );

                    this.transferRevenueSuccessModal.open({ amount: amount });
                } catch (err) {
                    if (err.data.errorCode === applicationErrorCodes.producer.insufficientAccountBalance) {
                        this.form.invalidate("Insufficient amount in your account.");
                    } else if (err.data.errorCode === applicationErrorCodes.producer.stripeConnectAccountNotExists) {
                        this.form.invalidate("Stripe Connect account does not exists.");
                    } else if (err.data.errorCode === applicationErrorCodes.producer.producerUserAccountNotExists) {
                        this.form.invalidate("Producer account does not exists.");
                    } else if (err.data.errorCode === applicationErrorCodes.producer.stripeConnectAccountNotEnabled) {
                        this.form.invalidate("Stripe Connect account is not enabled.");
                    } else {
                        this.rootStore.notificationStore.error(
                            "An unexpected error occurred while trying to transfer revenu. Please contact the support team.",
                            err
                        );
                    }
                }
            },
        });
    }

    @action.bound
    goToProducerAccount() {
        this.rootStore.routerStore.goTo("master.application.producer.preview.revenue");
    }

    async getProducerUserAccount() {
        try {
            const account = await this.routeStore.getProducerAccountSummary();
            this.setProducerUserAccount(account);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load account. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setProducerUserAccount(account) {
        this.producerUserAccount = account;
    }
}

export default ProducerRevenueTransferViewStore;
