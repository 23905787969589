import { findIndex, pullAt } from "lodash";
import { action, observable, makeObservable } from "mobx";

class NakiNigunBulkSelect {
    @observable isAllSelected = null;
    @observable.shallow selectedIds = [];
    @observable.shallow deselectedIds = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    selectAll() {
        this.isAllSelected = true;
        this.selectedIds = [];
        this.deselectedIds = [];
    }

    @action.bound
    deselectAll() {
        this.isAllSelected = false;
        this.selectedIds = [];
        this.deselectedIds = [];
    }

    @action.bound
    addSelectedItem(id) {
        const selectedItemIndex = findIndex(this.selectedIds, (selectedId) => selectedId === id);
        if (selectedItemIndex === -1) {
            this.selectedIds.push(id);
            const deselectedItemIndex = findIndex(this.deselectedIds, (deselectedId) => deselectedId === id);
            if (deselectedItemIndex !== -1) {
                pullAt(this.deselectedIds, deselectedItemIndex);
            }
        }
    }

    @action.bound
    addDeselectedItem(id) {
        const deselectedItemIndex = findIndex(this.deselectedIds, (deselectedId) => deselectedId === id);
        if (deselectedItemIndex === -1) {
            this.deselectedIds.push(id);
            const selectedItemIndex = findIndex(this.selectedIds, (selectedId) => selectedId === id);
            if (selectedItemIndex !== -1) {
                pullAt(this.selectedIds, selectedItemIndex);
            }
        }
    }
}

export default NakiNigunBulkSelect;
