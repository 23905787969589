import React from "react";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { producerTypes } from "common/constants";

function ProducerPreviewTemplate({ producerPreviewViewStore, renderChildRoute, t, ...props }) {
    const { selectedTab, setSelectedTab, producerTypeSlug } = producerPreviewViewStore;

    function getHeaderTitle() {
        if (producerTypeSlug === producerTypes.podcasterSlug) {
            return t("APPLICATION.PRODUCER.PREVIEW.PODCASTER_HEADER_TITLE");
        } else if (producerTypeSlug === producerTypes.artistSlug) {
            return t("APPLICATION.PRODUCER.PREVIEW.ARTIST_HEADER_TITLE");
        } else {
            return "Producer Account";
        }
    }

    return (
        <React.Fragment>
            <MrbPage>
                <MrbPageHeader>
                    <div>{getHeaderTitle()}</div>
                </MrbPageHeader>

                <div className="c-tabs">
                    {producerTypeSlug !== producerTypes.podcasterSlug && (
                        <React.Fragment>
                            <MrbRouterLink
                                onClick={() => setSelectedTab(1)}
                                className={mergeCss(
                                    "c-tabs__item",
                                    selectedTab.title === "Dashboard" && "c-tabs__item--active"
                                )}
                                routeName="master.application.producer.preview.dashboard"
                            >
                                {t("APPLICATION.PRODUCER.PREVIEW.DASHBOARD_TITLE")}
                            </MrbRouterLink>
                            <MrbRouterLink
                                onClick={() => setSelectedTab(2)}
                                className={mergeCss(
                                    "c-tabs__item",
                                    selectedTab.title === "Revenue" && "c-tabs__item--active"
                                )}
                                routeName="master.application.producer.preview.revenue"
                            >
                                {t("APPLICATION.PRODUCER.PREVIEW.REVENUE_TITLE")}
                            </MrbRouterLink>
                        </React.Fragment>
                    )}
                </div>

                {renderChildRoute(props)}
            </MrbPage>
        </React.Fragment>
    );
}

ProducerPreviewTemplate.propTypes = {
    producerPreviewViewStore: PropTypes.object.isRequired,
    renderChildRoute: PropTypes.func,
    t: PropTypes.func,
};

export default defaultTemplate(ProducerPreviewTemplate);
