import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import { UserEditLoader } from "administration/user/components";

import { UserProfilePasswordChange, UserProfileGeneralInfo } from "application/user-settings/components";
import { ChangePasswordLoader } from "common/components";

function UserProfileEditTemplate({ userProfileEditViewStore, t }) {
    const { loaderStore, routeStore } = userProfileEditViewStore;

    return (
        <MrbPage store={userProfileEditViewStore} t={t} contentClassNameExtend="c-container">
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <div>{t("APPLICATION.USER_SETTINGS.EDIT.FIELDS.TITLE")}</div>
                </div>
            </MrbPageHeader>
            <div className="c-container--sidebar c-container--sidebar--med u-padd--none">
                <div>
                    <h2 className="u-type--base u-mar--bottom--sml">Personal Details</h2>
                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <UserEditLoader button={true} />}
                        >
                            {() => <UserProfileGeneralInfo userProfileEditViewStore={userProfileEditViewStore} />}
                        </MrbContentLoader>
                    </div>
                </div>
                <div>
                    <h2 className="u-type--base u-mar--bottom--sml">
                        {t("ADMINISTRATION.USER.PASSWORD_CHANGE.TITLE")}
                    </h2>
                    <div className="c-card--primary">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ChangePasswordLoader />}>
                            {() => <UserProfilePasswordChange componentProps={routeStore} />}
                        </MrbContentLoader>
                    </div>
                </div>
            </div>
        </MrbPage>
    );
}

UserProfileEditTemplate.propTypes = {
    userProfileEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UserProfileEditTemplate);
