import React from "react";
import { observer } from "mobx-react";
import { UpgradeTrialTemplate } from "themes/application/payment/pages";
import { UpgradeTrialViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";
import { stripeProvider } from "common/hoc";

@stripeProvider
@setCurrentView(
    (rootStore, componentProps) => new UpgradeTrialViewStore(rootStore, componentProps),
    "upgradeTrialViewStore"
)
@observer
class UpgradeTrial extends React.Component {
    render() {
        return <UpgradeTrialTemplate {...this.props} />;
    }
}

export default UpgradeTrial;
