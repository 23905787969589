import { LocalizedForm } from "common/localization";

export default class TorahAnytimeCollectionEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "name",
                    label: "APPLICATION.TORAH_ANYTIME.EDIT.FIELDS.NAME_LABEL",
                    placeholder: "APPLICATION.TORAH_ANYTIME.EDIT.FIELDS.NAME_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
