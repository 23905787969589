import { action, computed, makeObservable, observable } from "mobx";
import { forEach } from "lodash";

class TorahAnytimeCollectionGridItemDecorator {
    @observable _userDeviceCollection = null;

    constructor(obj) {
        makeObservable(this);
        forEach(obj, (value, key) => {
            if (key === "_userDeviceCollection") {
                this.setIsTurnedOn(value);
            } else {
                this[key] = value;
            }
        });
    }

    @computed get userDeviceCollection() {
        return this._userDeviceCollection;
    }

    set userDeviceCollection(value) {
        this.setUserDeviceCollection(value);
    }

    @action.bound
    setUserDeviceCollection(value) {
        this._userDeviceCollection = value;
    }
}

export default TorahAnytimeCollectionGridItemDecorator;
