import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput } from "mrb/components";
import { defaultTemplate } from "common/hoc";
import { MrbEditFormLayout } from "mrb/components/layout";

function UserProfileGeneralInfo({ userProfileEditViewStore, t }) {
    const { form } = userProfileEditViewStore;

    return (
        <MrbEditFormLayout form={form} t={t}>
            <div className="u-animation--fadeIn">
                <div className="row row--align-center">
                    <div className="col col-sml-12 col-med-6 col-lrg-12 col-xxlrg-6">
                        <MrbFieldInput
                            t={t}
                            field={form.$("firstName")}
                            classNameExtend="c-input--outline"
                        />
                    </div>

                    <div className="col col-sml-12 col-med-6 col-lrg-12 col-xxlrg-6">
                        <MrbFieldInput
                            t={t}
                            field={form.$("lastName")}
                            classNameExtend="c-input--outline"
                        />
                    </div>

                    <div className="col col-sml-12 col-med-6 col-lrg-12 col-xxlrg-6">
                        <MrbFieldInput
                            t={t}
                            field={form.$("email")}
                            classNameExtend="c-input--outline"
                        />
                    </div>
                </div>
            </div>
        </MrbEditFormLayout>
    );
}

UserProfileGeneralInfo.propTypes = {
    userProfileEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UserProfileGeneralInfo);
