import { moduleProviderFactory } from "mrb/core";
import { NakiNigunHome, NakiNigunArtist } from "application/naki-nigun/pages";
import { NakiNigunHomeRouteStore, NakiNigunArtistRouteStore } from "application/naki-nigun/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "application.naki-nigun",
        routes: [
            {
                name: "master.application.naki-nigun",
                pattern: "/naki-nigun",
                children: [
                    {
                        name: "master.application.naki-nigun.list",
                        pattern: "",
                        component: NakiNigunHome,
                        store: NakiNigunHomeRouteStore,
                        data: {
                            title: "APPLICATION.NAKI_NIGUN.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.application.naki-nigun.artist",
                        pattern: "artist/:id",
                        component: NakiNigunArtist,
                        store: NakiNigunArtistRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.NAKI_NIGUN",
                order: 3,
                icon: "nigun",
                route: "master.application.naki-nigun.list",
            },
        ],
    });
})();
