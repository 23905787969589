import React from "react";
import { observer } from "mobx-react";
import { PremiumSubscriptionsTemplate } from "themes/application/payment/pages";
import { PremiumSubscriptionsViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PremiumSubscriptionsViewStore(rootStore, componentProps),
    "premiumSubscriptionsViewStore"
)
@observer
class PremiumSubscriptions extends React.Component {
    render() {
        return <PremiumSubscriptionsTemplate {...this.props} />;
    }
}

export default PremiumSubscriptions;
