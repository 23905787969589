import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbButton } from "mrb/components/button";
import { applicationDefaults, subscriptionPlans } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import {
    TrialActivatedModal,
    NakiRadioPremiumBadge,
    NakiNigunPremiumBadge,
    SubscriptionPlanLoader,
} from "application/payment/components";
import { defaultTemplate } from "common/hoc";
import moment from "moment";

function ActivateTrialTemplate({ activateTrialViewStore, t }) {
    const { activateTrial, isSubmitDisabled, trialActivatedModal, subscriptionPlan, loaderStore } =
        activateTrialViewStore;
    const endDate = moment().add(applicationDefaults.trialPeriodDays, "days");

    return (
        <MrbPage>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <MrbRouterLink routeName="master.application.premium.subscriptions">
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        Premium
                    </MrbRouterLink>
                </div>
            </MrbPageHeader>

            <div className="c-container--sml u-padd--none">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <SubscriptionPlanLoader />}>
                    {() => (
                        <React.Fragment>
                            <SubscriptionPlan subscriptionPlan={subscriptionPlan} />

                            <div className="c-payment c-payment__card">
                                <div>
                                    <div className="c-payment__total__item">
                                        <p>{t("APPLICATION.PREMIUM.TRIAL.START_DATE_LABEL")}</p>
                                        <p>{t("APPLICATION.PREMIUM.TRIAL.START_DATE_VALUE")}</p>
                                    </div>

                                    <div className="c-payment__total__item u-mar--bottom--med">
                                        <p>{t("APPLICATION.PREMIUM.TRIAL.END_DATE_LABEL")}</p>
                                        <p>
                                            {dateFormatter.format(endDate, {
                                                format: applicationDefaults.formats.date,
                                            })}
                                        </p>
                                    </div>

                                    <div className="c-payment__total__item">
                                        <p>Cancel Anytime Policy</p>
                                    </div>
                                </div>

                                <div className="u-separator--primary"></div>

                                <div>
                                    <MrbButton
                                        t={t}
                                        onClick={activateTrial}
                                        disabled={isSubmitDisabled}
                                        className="c-btn c-btn--base c-btn--full c-btn--primary"
                                        label="APPLICATION.PREMIUM.TRIAL.TRY_FOR_FREE"
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
            <TrialActivatedModal modalParams={trialActivatedModal} />
        </MrbPage>
    );
}

ActivateTrialTemplate.propTypes = {
    activateTrialViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function SubscriptionPlan({ subscriptionPlan }) {
    let premiumBadge = null;
    switch (subscriptionPlan.slug) {
        case subscriptionPlans.nakiRadioPremium:
            premiumBadge = <NakiRadioPremiumBadge />;
            break;
        case subscriptionPlans.nakiNigun:
            premiumBadge = <NakiNigunPremiumBadge />;
            break;
        default:
            break;
    }
    return (
        <div className="c-payment__card c-payment__card--tertiary u-mar--bottom--med">
            <div className="u-display--flex u-display--flex--ai--center">
                {premiumBadge}

                <div className="u-mar--left--med">
                    <p className="u-mar--bottom--tny">{subscriptionPlan.name} Trial</p>
                    <p className="u-type--med u-type--wgt--bold">Free</p>
                    <p className="u-type--sml u-type--wgt--bold">
                        {applicationDefaults.trialPeriodDays} days free trial
                    </p>
                </div>
            </div>
        </div>
    );
}

export default defaultTemplate(ActivateTrialTemplate);
