import React from "react";
import { TorahAnytimeWizardTemplate } from "themes/application/torah-anytime/pages";
import { TorahAnytimeWizardViewStore } from "application/torah-anytime/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TorahAnytimeWizardViewStore(rootStore, componentProps),
    "torahAnytimeWizardViewStore"
)
class TorahAnytimeWizard extends React.Component {
    render() {
        return <TorahAnytimeWizardTemplate {...this.props} />;
    }
}

export default TorahAnytimeWizard;
