import React from "react";
import { NakiNigunHomeTemplate } from "themes/application/naki-nigun/pages";
import { NakiNigunHomeViewStore } from "application/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunHomeViewStore(rootStore, componentProps),
    "nakiNigunHomeViewStore"
)
class NakiNigunHome extends React.Component {
    render() {
        return <NakiNigunHomeTemplate {...this.props} />;
    }
}

export default NakiNigunHome;
