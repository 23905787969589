import { computed, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { httpStatusCodes } from "mrb/core/constants";
import { TorahAnytimeSpeakersFilter } from "application/torah-anytime/filters";
import { map, filter, forEach, includes } from "lodash";

class TorahAnytimeSelectSpeakersViewStore extends MrbBaseListViewStore {
    _abortController = null;

    @computed get isSpeakerSelectionEmpty() {
        return this.torahAnytimeSelectionViewStore.selectedSpeakers.length === 0;
    }

    @computed get defaultSpeakerImageUrl() {
        const podcast = this.torahAnytimeWizardViewStore.torahAnytimePodcast;
        if (podcast && podcast.coverImageId) {
            return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(podcast.coverImageId, 200, 200);
        }
        return null;
    }

    constructor(rootStore, { routeStore, torahAnytimeSelectionViewStore, torahAnytimeWizardViewStore }) {
        super(rootStore, {
            queryConfig: {
                filter: new TorahAnytimeSpeakersFilter(),
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.torahAnytimeSelectionViewStore = torahAnytimeSelectionViewStore;
        this.torahAnytimeWizardViewStore = torahAnytimeWizardViewStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
            })
        );

        this.reaction(
            () => (this.queryUtility.filter.search || "").trim(),
            () => {
                this.queryUtility.fetch();
            },
            {
                delay: 300,
            }
        );
        this.selectSpeakersByGender = this.selectSpeakersByGender.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
    }

    onInit() {
        return this.queryUtility.initialize(true, true);
    }

    async selectSpeakersByGender(isFemale) {
        const topicIds = map(this.torahAnytimeSelectionViewStore.selectedTopics, (topic) => topic.topicId);
        const params = { topicIds: topicIds, isFemale: isFemale };

        try {
            this.loaderStore.suspend();
            const speakerIds = await this.routeStore.findSpeakersByGender(params);
            const speakers = map(speakerIds, (speakerId) => {
                return { id: speakerId };
            });

            if (speakerIds.length > 0) {
                this.torahAnytimeSelectionViewStore.selectSpeakers(speakers);
            }
        } catch (error) {
            this.rootStore.notificationStore.error("Error while selecting all rabbis or rebbetzins");
        } finally {
            this.loaderStore.resume();
        }
    }

    clearSelection() {
        this.torahAnytimeSelectionViewStore.clearSelectedSpeakers();
    }

    async find(params) {
        this.abortExistingRequest();
        const topicIds = map(this.torahAnytimeSelectionViewStore.selectedTopics, (topic) => topic.topicId);
        params.topicIds = topicIds;
        this._abortController = new AbortController();

        if (this.torahAnytimeWizardViewStore.currentStep.id === "speakers") {
            if (this.torahAnytimeSelectionViewStore.selectedSpeakers.length > 0) {
                await this.validateSpeakers();
            }
        }

        return await this.routeStore.findSpeakers(params, this._abortController);
    }

    async validateSpeakers() {
        let allSpeakerIdsByTopics = await this.torahAnytimeWizardViewStore.selectedTopicsSpeakerIds;

        const speakersForDeselect = filter(
            this.torahAnytimeSelectionViewStore.selectedSpeakers,
            (speaker) => !includes(allSpeakerIdsByTopics, speaker.speakerId)
        );

        forEach(speakersForDeselect, (speaker) => {
            this.torahAnytimeSelectionViewStore.deselectSpeaker({ id: speaker.speakerId });
        });
    }

    abortExistingRequest() {
        if (this._abortController) {
            this._abortController.abort();
        }
    }

    onFetchError(error) {
        if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
            return;
        }
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    onDestroy() {
        this.abortExistingRequest();
    }
}

export default TorahAnytimeSelectSpeakersViewStore;
