import React from "react";
import { HomePodcastTemplate } from "themes/application/home/pages";
import { HomePodcastViewStore } from "application/home/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new HomePodcastViewStore(rootStore, componentProps),
    "homePodcastViewStore"
)
class HomePodcast extends React.Component {
    render() {
        return <HomePodcastTemplate {...this.props} />;
    }
}

export default HomePodcast;
