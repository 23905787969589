import { MrbBaseRouteStore } from "mrb/core";
import { ProducerUserAccountService } from "common/services";

class ProducerRevenueTransferRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
    }

    async getProducerAccountSummary() {
        const response = await this.producerUserAccountService.getProducerAccountSummary();
        return response.data;
    }

    async processPayoutToBankAccount(accountId, resource) {
        const response = await this.producerUserAccountService.processPayoutToBankAccount(accountId, resource);
        return response.data;
    }
}

export default ProducerRevenueTransferRouteStore;
