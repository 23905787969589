import React from "react";
import { observer } from "mobx-react";
import { ChangeSubscriptionPlanPreviewTemplate } from "themes/application/payment/pages";
import { ChangeSubscriptionPlanPreviewViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";
import { stripeProvider } from "common/hoc";

@stripeProvider
@setCurrentView(
    (rootStore, componentProps) => new ChangeSubscriptionPlanPreviewViewStore(rootStore, componentProps),
    "changeSubscriptionPlanPreviewViewStore"
)
@observer
class ChangeSubscriptionPlanPreview extends React.Component {
    render() {
        return <ChangeSubscriptionPlanPreviewTemplate {...this.props} />;
    }
}

export default ChangeSubscriptionPlanPreview;
