import React from "react";
import { ProducerRevenueTemplate } from "themes/application/producer/pages";
import { ProducerRevenueViewStore } from "application/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ProducerRevenueViewStore(rootStore, componentProps),
    "producerRevenueViewStore"
)
class ProducerRevenue extends React.Component {
    render() {
        return <ProducerRevenueTemplate {...this.props} />;
    }
}

export default ProducerRevenue;
