import React from "react";
import { PropTypes } from "prop-types";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { MrbEditPage, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader } from "mrb/components/loader";
import {
    CreditCardInfo,
    TakePaymentHeader,
    PaymentSuccessModal,
    NakiNigunPremiumBadge,
    NakiRadioPremiumBadge,
    SubscriptionPlanLoader,
    SubscriptionPrice,
} from "application/payment/components";
import { StripeCreditCardField } from "common/components";
import { defaultTemplate } from "common/hoc";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { subscriptionPlans } from "common/constants";

function TakePaymentTemplate({ takePaymentViewStore, t }) {
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return <div>loading</div>;
    }
    takePaymentViewStore.setStripe(stripe);
    const { subscriptionPlan, isPaymentDisabled, paymentSuccessModal, loaderStore } = takePaymentViewStore;

    const subscribe = () => {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        takePaymentViewStore.onClickSubscribe(cardElement);
    };

    return (
        <MrbEditPage
            store={takePaymentViewStore}
            onSubmit={(event) => {
                subscribe();
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <MrbPageHeader>
                <TakePaymentHeader />
            </MrbPageHeader>

            <h1 className="u-type--base u-mar--bottom--sml">{t("APPLICATION.PREMIUM.PAYMENT.TITLE")}</h1>

            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <SubscriptionPlanLoader />}>
                {() => (
                    <React.Fragment>
                        <SubscriptionPlan subscriptionPlan={subscriptionPlan} />

                        <div className="c-payment__card">
                            <div className="c-payment">
                                <div className="c-payment__payment-card">
                                    <CreditCard store={takePaymentViewStore} t={t} />
                                </div>

                                <div className="c-payment__total">
                                    <div className="c-payment__total__item">
                                        <p>{t("APPLICATION.PREMIUM.PAYMENT.INFO.START_DATE_LABEL")}</p>
                                        <p>{t("APPLICATION.PREMIUM.PAYMENT.INFO.START_DATE_VALUE")}</p>
                                    </div>

                                    <div className="u-separator--primary"></div>
                                </div>

                                <MrbButton
                                    t={t}
                                    type="submit"
                                    disabled={isPaymentDisabled}
                                    className="c-btn c-btn--base c-btn--full c-btn--primary"
                                    label="APPLICATION.PREMIUM.PAYMENT.BUTTONS.PAY"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </MrbContentLoader>

            <PaymentSuccessModal modalParams={paymentSuccessModal} />
        </MrbEditPage>
    );
}

TakePaymentTemplate.propTypes = {
    takePaymentViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const CreditCard = observer(function CreditCard({ store, t }) {
    const {
        stripeField,
        paymentMethodExist,
        rootStore: { premiumSubscriptionStore },
    } = store;
    if (paymentMethodExist) {
        return (
            <div className="u-type--base">
                <p>Payment method</p>
                <CreditCardInfo creditCard={premiumSubscriptionStore.customerPaymentMethod} t={t} />
            </div>
        );
    } else {
        return (
            <StripeCreditCardField field={stripeField} label="APPLICATION.PREMIUM.PAYMENT.FIELDS.CREDIT_CARD" t={t} />
        );
    }
});

export default defaultTemplate(TakePaymentTemplate);

function SubscriptionPlan({ subscriptionPlan }) {
    let premiumBadge = null;
    let activeClassName = "";

    switch (subscriptionPlan.slug) {
        case subscriptionPlans.nakiRadioPremium:
            activeClassName = "primary";
            premiumBadge = <NakiRadioPremiumBadge iconSize={"med"} />;
            break;
        case subscriptionPlans.nakiNigun:
            activeClassName = "tertiary";
            premiumBadge = <NakiNigunPremiumBadge iconSize={"med"} />;
            break;
        default:
            break;
    }
    return (
        <div className={`c-payment__card c-payment__card--${activeClassName} u-mar--bottom--med`}>
            <div className="u-display--flex u-display--flex--ai--center">
                {premiumBadge}

                <div className="u-mar--left--med">
                    <p className="u-mar--bottom--tny">{subscriptionPlan.name}</p>
                    <p className="u-type--med u-type--wgt--bold">
                        <SubscriptionPrice price={subscriptionPlan.price} />
                    </p>
                </div>
            </div>
        </div>
    );
}
