import { moduleProviderFactory } from "mrb/core";
import { Home, HomeStation, HomePodcast, HomePodcastGroup } from "application/home/pages";
import { TorahAnytime, TorahAnytimeWizard } from "application/torah-anytime/pages";
import {
    HomeRouteStore,
    HomeStationRouteStore,
    HomePodcastRouteStore,
    HomePodcastGroupRouteStore,
} from "application/home/stores";
import { TorahAnytimeRouteStore, TorahAnytimeWizardRouteStore } from "application/torah-anytime/stores";

(function () {
    moduleProviderFactory.module("application").register({
        name: "application.home",
        routes: [
            {
                name: "master.application.home",
                pattern: "/home",
                children: [
                    {
                        name: "master.application.home.list",
                        pattern: "",
                        component: Home,
                        store: HomeRouteStore,
                        data: {
                            title: "APPLICATION.HOME.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.application.home.station",
                        pattern: "/station/:id",
                        component: HomeStation,
                        store: HomeStationRouteStore,
                    },
                    {
                        name: "master.application.home.podcast",
                        pattern: "/podcast/:id",
                        component: HomePodcast,
                        store: HomePodcastRouteStore,
                    },
                    {
                        name: "master.application.home.podcast-group",
                        pattern: "/podcast/:id/group",
                        component: HomePodcastGroup,
                        store: HomePodcastGroupRouteStore,
                    },
                    {
                        name: "master.application.home.torah-anytime",
                        pattern: "/torahanytime/:id",
                        component: TorahAnytime,
                        store: TorahAnytimeRouteStore,
                    },
                    {
                        name: "master.application.home.torah-anytime.create",
                        pattern: "torahanytime/:id/collection/create",
                        component: TorahAnytimeWizard,
                        store: TorahAnytimeWizardRouteStore,
                    },
                    {
                        name: "master.application.home.torah-anytime.edit",
                        pattern: "/torahanytime/:id/collection/:collectionId/edit",
                        component: TorahAnytimeWizard,
                        store: TorahAnytimeWizardRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.HOME",
                order: 2,
                icon: "home",
                route: "master.application.home.list",
            },
        ],
    });
})();
