import { MrbBaseEditViewStore } from "mrb/core";
import { DeviceEditForm } from "application/device-settings/forms";
import { DeviceDowntimeEditViewStore } from "application/device-settings/stores";
import { localizationService } from "common/localization";
import { nakiRadioSystemTypes } from "common/constants";
import { computed, makeObservable, observable } from "mobx";
import { isNil } from "lodash";

class DeviceEditViewStore extends MrbBaseEditViewStore {
    @observable isFormChanged = false;

    @computed get isDowntimeDisabled() {
        return (
            !isNil(this.item) &&
            !isNil(this.item.device) &&
            !isNil(this.item.device.systemType) &&
            this.item.device.systemType.abrv === nakiRadioSystemTypes.mobile
        );
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "device-edit",
            FormClass: DeviceEditForm,
            autoFocusField: "deviceName",
            navigateBack: "master.application.device.list",
            successCreateNotification: null,
            successUpdateNotification: null,
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.deviceDowntimeEditViewStore = new DeviceDowntimeEditViewStore(this.rootStore, this.routeStore, this.form);

        this.registerRouteExitBlocking();
        this.reaction(
            () => this.form.changed || this.deviceDowntimeEditViewStore.disableActionOccured,
            (isChanged) => {
                if (isChanged) {
                    this.isFormChanged = isChanged;
                }
            }
        );
    }

    get(id) {
        return this.routeStore.getUserDevice(id);
    }

    update(resource) {
        resource.prohibitedTime = this.deviceDowntimeEditViewStore.processFormResult(resource.prohibitedTime);
        this.isFormChanged = false;
        return this.routeStore.updateUserDevice(resource);
    }

    async onInit() {
        await super.onInit();
        await this.deviceDowntimeEditViewStore.initialize();
        this.deviceDowntimeEditViewStore.buildForm(this.item.prohibitedTime);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error(
            localizationService.t("APPLICATION.DEVICE_SETTINGS.EDIT.ERROR.GENERIC_MESSAGE")
        );
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }
}

export default DeviceEditViewStore;
