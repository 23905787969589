import React from "react";
import { observer } from "mobx-react";
import { ActivateTrialTemplate } from "themes/application/payment/pages";
import { ActivateTrialViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";
import { stripeProvider } from "common/hoc";

@stripeProvider
@setCurrentView(
    (rootStore, componentProps) => new ActivateTrialViewStore(rootStore, componentProps),
    "activateTrialViewStore"
)
@observer
class ActivateTrial extends React.Component {
    render() {
        return <ActivateTrialTemplate {...this.props} />;
    }
}

export default ActivateTrial;
