import { MrbBaseInfiniteScrollListViewStore } from "baasic-react-bootstrap/src";
import "moment-timezone";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";

class ProducerDashboardInfiniteScrollStore extends MrbBaseInfiniteScrollListViewStore {
    constructor(rootStore, { setPagingFilter, fetchData }) {
        super(rootStore, {
            queryConfig: {
                pageSize: 30,
                ignoreQueryParams: ["page", "timeZone", "rpp"],
            },
        });
        this.setPagingFilter = setPagingFilter;
        this.fetchData = fetchData;

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.nakiNigunAlbum?.id || item.podcast?.id,
                mapper: (item) => {
                    return {
                        id: item.nakiNigunAlbum?.id || item.podcast.id,
                        title: item.nakiNigunAlbum ? item.nakiNigunAlbum.title : item.podcast.title,
                        isPremium:
                            (item.nakiNigunAlbum && item.nakiNigunAlbum.isPremium) ||
                            (item.podcast && item.podcast.isPremium),
                        score: item.currentCycleScore,
                    };
                },
            })
        );
    }

    find(params) {
        this.setPagingFilter({ page: params.pageNumber, rpp: params.pageSize });
        return this.fetchData();
    }
}

export default ProducerDashboardInfiniteScrollStore;
