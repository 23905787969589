import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import { currencyFormatter } from "mrb/common/formatting";
import { defaultTemplate } from "common/hoc";

function ProducerRevenueTransferSuccessModalTemplate({ modalParams, t }) {
    const { params, data = {} } = modalParams;
    const formattedPrice = currencyFormatter.format(data.amount, {
        currency: "USD",
    });
    return (
        <MrbModal modalParams={modalParams} shouldCloseOnOverlayClick={false} displayCloseIcon={false}>
            <div>
                <div className="modal__header">
                    <h2>Success!</h2>
                </div>
                <div className="modal__body">
                    <p>{formattedPrice} has been transferred to your Stripe Connect account.</p>
                </div>
                <div className="modal__footer">
                    <MrbButton label="Go to Producer Account" onClick={params.onClickGoToProducerAccount} />
                </div>
            </div>
        </MrbModal>
    );
}

ProducerRevenueTransferSuccessModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(ProducerRevenueTransferSuccessModalTemplate);
