import { observable, action, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { pullAt, findIndex, forEach } from "lodash";

class TorahAnytimeSelectionViewStore extends MrbBaseViewStore {
    @observable selectedSpeakers = [];
    @observable selectedTopics = [];

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    initializeSelection(topics = [], speakers = []) {
        this.selectedTopics = topics;
        this.selectedSpeakers = speakers;
    }

    @action.bound
    selectSpeakers(speakers) {
        forEach(speakers, (speaker) => {
            const existingItemIndex = findIndex(
                this.selectedSpeakers,
                (selectedSpeaker) => selectedSpeaker.speakerId === speaker.id
            );
            if (existingItemIndex === -1) {
                this.selectedSpeakers.push({
                    speakerId: speaker.id,
                    speaker: speaker,
                });
            }
        });
    }

    @action.bound
    clearSelectedSpeakers() {
        this.selectedSpeakers = [];
    }

    @action.bound
    selectSpeaker(speaker) {
        const existingItemIndex = findIndex(
            this.selectedSpeakers,
            (selectedSpeaker) => selectedSpeaker.speakerId === speaker.id
        );
        if (existingItemIndex === -1) {
            this.selectedSpeakers.push({
                speakerId: speaker.id,
                speaker: speaker,
            });
        }
    }

    @action.bound
    deselectSpeaker(speaker) {
        const existingItemIndex = findIndex(
            this.selectedSpeakers,
            (selectedSpeaker) => selectedSpeaker.speakerId === speaker.id
        );
        if (existingItemIndex !== -1) {
            pullAt(this.selectedSpeakers, existingItemIndex);
        }
    }

    @action.bound
    selectTopic(topic) {
        const existingItemIndex = findIndex(this.selectedTopics, (selectedTopic) => selectedTopic.topicId === topic.id);
        if (existingItemIndex === -1) {
            this.selectedTopics.push({
                topicId: topic.id,
                topic: topic,
            });
        }
    }

    @action.bound
    deselectTopic(topic) {
        const existingItemIndex = findIndex(this.selectedTopics, (selectedTopic) => selectedTopic.topicId === topic.id);
        if (existingItemIndex !== -1) {
            pullAt(this.selectedTopics, existingItemIndex);
        }
    }
}

export default TorahAnytimeSelectionViewStore;
