import React from "react";
import { NakiNigunArtistTemplate } from "themes/application/naki-nigun/pages";
import { NakiNigunArtistViewStore } from "application/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistViewStore(rootStore, componentProps),
    "nakiNigunArtistViewStore"
)
class NakiNigunArtist extends React.Component {
    render() {
        return <NakiNigunArtistTemplate {...this.props} />;
    }
}

export default NakiNigunArtist;
