import { action, makeObservable, observable, computed } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { httpStatusCodes } from "baasic-react-bootstrap/src";
import moment from "moment";
import "moment-timezone";
import ProducerDashboardTimeRangeViewStore from "./ProducerDashboardTimeRangeViewStore";
import ProducerDashboardInfiniteScrollStore from "./ProducerDashboardInfiniteScrollStore";

const timeZone = moment.tz.guess();

class ProducerDashboardViewStore extends MrbBaseViewStore {
    @observable dateFilter = {
        to: moment().utc().tz(timeZone).endOf("week").toISOString(),
        from: moment().utc().tz(timeZone).startOf("week").toISOString(),
        previousCycleTo: moment().utc().tz(timeZone).subtract(1, "week").endOf("week").toISOString(),
        previousCycleFrom: moment().utc().tz(timeZone).subtract(1, "week").startOf("week").toISOString(),
        timeZone: timeZone,
    };
    @observable nakiNigunPagingFilter = { rpp: 30, page: 1 };
    @observable nakiTalesPagingFilter = { rpp: 30, page: 1 };

    statsFilterOptions = [
        { value: 1, label: "Streams" },
        { value: 2, label: "Unique Listeners" },
    ];

    @observable nakiNigunFilterSelectedOptionValue = 1;
    @observable nakiTalesFilterSelectedOptionValue = 1;

    @observable nakiNigunTotalListeners = null;
    @observable nakiTalesTotalListeners = null;

    @computed get producerTypeSlug() {
        return this.rootStore.userStore?.producerType?.slug;
    }

    @computed get nakiNigunFilter() {
        const uniqueListeners = this.nakiNigunFilterSelectedOptionValue === 2;
        return { ...this.dateFilter, ...this.nakiNigunPagingFilter, uniqueListeners };
    }

    @computed get nakiTalesFilter() {
        const uniqueListeners = this.nakiTalesFilterSelectedOptionValue === 2;
        return { ...this.dateFilter, ...this.nakiTalesPagingFilter, uniqueListeners };
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.producerDashboardTimeRangeViewStore = new ProducerDashboardTimeRangeViewStore(this.rootStore, {
            setDateFilter: (filter) => this.setDateFilter(filter),
        });

        this.nakiNigunStatsInfiniteScrollStore = new ProducerDashboardInfiniteScrollStore(this.rootStore, {
            setPagingFilter: (filter) => this.setNakiNigunPagingFilter(filter),
            fetchData: () => this.fetchTrendingAlbums(),
        });

        this.nakiTalesStatsInfiniteScrollStore = new ProducerDashboardInfiniteScrollStore(this.rootStore, {
            setPagingFilter: (filter) => this.setNakiTalesPagingFilter(filter),
            fetchData: () => this.fetchTrendingPodcasts(),
        });

        this.refreshData = this.refreshData.bind(this);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    async onInit() {
        await this.refreshData();
    }

    async refreshData() {
        await Promise.all([
            this.fetchTrendingAlbumsTotalStreams(),
            this.fetchTrendingPodcastsTotalStreams(),
            this.nakiNigunStatsInfiniteScrollStore.queryUtility.fetch(),
            this.nakiTalesStatsInfiniteScrollStore.queryUtility.fetch(),
        ]);
    }

    @action.bound
    async setDateFilter(filter) {
        this.dateFilter = filter;
        await this.refreshData();
    }

    @action.bound
    setNakiNigunPagingFilter(filter) {
        this.nakiNigunPagingFilter = filter;
    }
    @action.bound
    setNakiTalesPagingFilter(filter) {
        this.nakiTalesPagingFilter = filter;
    }

    @action.bound
    async setNakiTalesFilterSelectedOptionValue(value) {
        this.nakiTalesFilterSelectedOptionValue = value;
        await Promise.all([
            this.fetchTrendingPodcastsTotalStreams(),

            this.nakiTalesStatsInfiniteScrollStore.queryUtility.fetch(),
        ]);
    }

    @action.bound
    async setNakiNigunFilterSelectedOptionValue(value) {
        this.nakiNigunFilterSelectedOptionValue = value;
        await Promise.all([
            this.fetchTrendingAlbumsTotalStreams(),
            this.nakiNigunStatsInfiniteScrollStore.queryUtility.fetch(),
        ]);
    }

    @action.bound
    setNakiNigunTotalListeners(value) {
        this.nakiNigunTotalListeners = value;
    }

    @action.bound
    setNakiTalesTotalListeners(value) {
        this.nakiTalesTotalListeners = value;
    }

    async fetchTrendingPodcasts() {
        try {
            const data = await this.routeStore.getTrendingPodcasts(this.nakiTalesFilter);
            return data;
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load podcasts.",
                error
            );
        }
    }

    async fetchTrendingAlbums() {
        try {
            const data = await this.routeStore.getTrendingAlbums(this.nakiNigunFilter);
            return data;
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to load albums.", error);
        }
    }

    async fetchTrendingAlbumsTotalStreams() {
        try {
            this.setNakiNigunTotalListeners(null);
            const value = await this.routeStore.getTrendingAlbumsTotalStreams(this.nakiNigunFilter);
            this.setNakiNigunTotalListeners(value);
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.setNakiNigunTotalListeners(null);
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load all Naki Nigun content listeners.",
                error
            );
        }
    }

    async fetchTrendingPodcastsTotalStreams() {
        try {
            this.setNakiTalesTotalListeners(null);
            const value = await this.routeStore.getTrendingPodcastsTotalStreams(this.nakiTalesFilter);
            this.setNakiTalesTotalListeners(value);
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.setNakiTalesTotalListeners(null);
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load all Naki Tales content listeners.",
                error
            );
        }
    }
}

export default ProducerDashboardViewStore;
