import React from "react";
import { TorahAnytimeCollectionListItemTemplate } from "themes/application/torah-anytime/components";
import { TorahAnytimeCollectionListItemViewStore } from "application/torah-anytime/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TorahAnytimeCollectionListItemViewStore(rootStore, componentProps),
    "torahAnytimeCollectionListItemViewStore"
)
class TorahAnytimeCollectionListItem extends React.Component {
    render() {
        return <TorahAnytimeCollectionListItemTemplate {...this.props} />;
    }
}

export default TorahAnytimeCollectionListItem;
