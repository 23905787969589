import { action, computed, makeObservable, observable } from "mobx";
import { forEach } from "lodash";

class NakiNigunArtist {
    @observable _numberOfEnabledAlbums = 0;

    constructor(obj) {
        makeObservable(this);
        forEach(obj, (value, key) => {
            if (key === "numberOfEnabledAlbums") {
                this.setNumberOfEnabledAlbums(value);
            } else {
                this[key] = value;
            }
        });
    }

    @computed get numberOfEnabledAlbums() {
        return this._numberOfEnabledAlbums;
    }

    @computed get isTurnedOn() {
        return this._numberOfEnabledAlbums === this.numberOfAlbums;
    }

    set isTurnedOn(value) {
        if (value) {
            this.setNumberOfEnabledAlbums(this.numberOfAlbums);
        } else {
            this.setNumberOfEnabledAlbums(0);
        }
    }

    @action.bound
    setNumberOfEnabledAlbums(value) {
        this._numberOfEnabledAlbums = value;
    }
}

export default NakiNigunArtist;
