import { moduleProviderFactory } from "mrb/core";
import {
    ProducerDashboard,
    ProducerRevenue,
    ProducerRevenueReport,
    ProducerRevenueTransfer,
    ProducerPreview,
} from "application/producer/pages";
import {
    ProducerRevenueRouteStore,
    ProducerDashboardRouteStore,
    ProducerRevenueReportRouteStore,
    ProducerRevenueTransferRouteStore,
    ProducerPreviewRouteStore,
} from "application/producer/stores";
import { producerTypes } from "common/constants";

(function () {
    moduleProviderFactory.module("application").register({
        name: "application.producer",
        routes: [
            {
                name: "master.application.producer",
                pattern: "/producer",
                authorization: "nakiRadioProducerSection.read",
                children: [
                    {
                        name: "master.application.producer.preview",
                        pattern: "",
                        component: ProducerPreview,
                        store: ProducerPreviewRouteStore,
                        children: [
                            {
                                name: "master.application.producer.preview.dashboard",
                                pattern: "dashboard",
                                component: ProducerDashboard,
                                store: ProducerDashboardRouteStore,
                            },
                            {
                                name: "master.application.producer.preview.revenue",
                                pattern: "revenue",
                                component: ProducerRevenue,
                                store: ProducerRevenueRouteStore,
                                beforeEnter: (fromState, toState, routerStore) => {
                                    const { userStore } = routerStore.rootStore;
                                    if (userStore.applicationUser?.slug === producerTypes.podcasterSlug) {
                                        return Promise.reject(routerStore.notFoundState);
                                    }
                                },
                            },
                        ],
                    },
                    {
                        name: "master.application.producer.report",
                        pattern: "/:id/report",
                        component: ProducerRevenueReport,
                        store: ProducerRevenueReportRouteStore,
                        beforeEnter: (fromState, toState, routerStore) => {
                            const { userStore } = routerStore.rootStore;
                            if (userStore.producerType?.slug === producerTypes.podcasterSlug) {
                                return Promise.reject(routerStore.notFoundState);
                            }
                        },
                    },
                    {
                        name: "master.application.producer.payout",
                        pattern: "payout",
                        component: ProducerRevenueTransfer,
                        store: ProducerRevenueTransferRouteStore,
                        beforeEnter: (fromState, toState, routerStore) => {
                            const { userStore } = routerStore.rootStore;
                            if (userStore.producerType?.slug === producerTypes.podcasterSlug) {
                                return Promise.reject(routerStore.notFoundState);
                            }
                        },
                    },
                ],
            },
        ],
        menu: [
            {
                title: "APPLICATION.MENU.PRODUCER",
                order: 1,
                authorization: ["nakiRadioProducerSection.read"],
                icon: "producers",
                route: "master.application.producer.preview.dashboard",
            },
        ],
    });
})();
