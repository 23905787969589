import { NakiNigunAlbum, NakiNigunArtist } from "application/naki-nigun/models";

function getContentActivityStatusIcon(item) {
    if (item instanceof NakiNigunArtist) {
        if (item.numberOfEnabledAlbums === 0) {
            return "c-home__status--disabled";
        } else if (item.numberOfAlbums === item.numberOfEnabledAlbums) {
            return "c-home__status--enabled";
        } else if (item.numberOfAlbums > item.numberOfEnabledAlbums) {
            return "c-home__status--mix";
        }
    } else if (item instanceof NakiNigunAlbum) {
        if (item.isTurnedOn) {
            return "c-home__status--enabled";
        } else {
            return "c-home__status--disabled";
        }
    }
    return null;
}

export default getContentActivityStatusIcon;
