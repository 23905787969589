import React from "react";
import { DeviceEditTemplate } from "themes/application/device-settings/pages";
import { DeviceEditViewStore } from "application/device-settings/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new DeviceEditViewStore(rootStore, componentProps),
    "deviceEditViewStore"
)
class DeviceEdit extends React.Component {
    render() {
        return <DeviceEditTemplate {...this.props} />;
    }
}

export default DeviceEdit;
