import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader, MrbPageHeader } from "mrb/components/layout";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { HomePreviewHeader, HomePreviewSubPodcastListLoader } from "application/home/components";
import { NakiNigunArtistAlbum } from "application/naki-nigun/components";
import { NakiNigunArtistUnavailableInApp } from "application/common/components";
import { defaultTemplate } from "common/hoc";
import { map } from "lodash";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";
import { PremiumBadge, Image } from "common/components";

function NakiNigunArtistTemplate({ nakiNigunArtistViewStore, t }) {
    const { loaderStore, item, coverImageUrl, contentAccessResult } = nakiNigunArtistViewStore;

    return (
        <MrbPreviewPage store={nakiNigunArtistViewStore} t={t} contentClassNameExtend="u-padd--none">
            <MrbPageHeader>
                <HomePreviewHeader store={nakiNigunArtistViewStore} hideStatusButton={true} t={t} />
            </MrbPageHeader>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div>
                                <div className="u-animation--fadeIn">
                                    <div
                                        className={mergeCss(
                                            "c-home__preview__header__icon",
                                            !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                                        )}
                                    >
                                        <Image src={coverImageUrl} />
                                        <PremiumBadge
                                            isPremium={item.isPremium}
                                            isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("APPLICATION.NAKI_NIGUN.ARTIST.FIELDS.NAME")}
                                        </p>
                                        <p
                                            className={mergeCss(
                                                "c-content__header--primary__title",
                                                !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                                            )}
                                        >
                                            {item.name}
                                        </p>
                                        {!contentAccessResult.canAccessOnSelectedDevice && (
                                            <NakiNigunArtistUnavailableInApp />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <Albums store={nakiNigunArtistViewStore} t={t} />
        </MrbPreviewPage>
    );
}

NakiNigunArtistTemplate.propTypes = {
    nakiNigunArtistViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Albums = observer(function Albums({ store, t }) {
    const { item, loaderStore } = store;

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <HomePreviewSubPodcastListLoader />}>
            {() => (
                <React.Fragment>
                    {item && item.albums && item.albums.length > 0 && (
                        <div className="u-animation--fadeIn">
                            <div className="c-container--sidebar">
                                <div>
                                    {map(item.albums, (album) => (
                                        <NakiNigunArtistAlbum
                                            key={album.id}
                                            albumId={album.id}
                                            nakiNigunArtistViewStore={store}
                                            t={t}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
});

export default defaultTemplate(NakiNigunArtistTemplate);
