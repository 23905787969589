import { override } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { UserProfileChangePasswordForm } from "application/user-settings/forms";
import { localizationService } from "common/localization";

class UserProfilePasswordChangeViewStore extends MrbBaseEditViewStore {
    constructor(rootStore, { componentProps }) {
        super(rootStore, {
            name: "password-change",
            FormClass: UserProfileChangePasswordForm,
            successCreateNotification: localizationService.t(
                "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.SUCCESS_MESSAGE"
            ),
            successUpdateNotification: null,
            redirectOnCreateSuccess: false,
        });
        this.routeStore = componentProps;
    }

    create(data) {
        return this.routeStore.changeUserPassword(
            this.rootStore.userStore.user.id,
            {
                newPassword: data.password,
                siteUrl: window.location.origin,
            }
        );
    }

    @override
    onCreateSuccess(result) {
        this.notifySuccessCreate();
        this.form.clear();
    }

    onCreateError(error) {
        if (error.data && error.data.errorCode === 4000022103) {
            this.form.invalidate(
                "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.ERROR.USER_IS_DISAPPROVED"
            );
        } else {
            this.form.invalidate(
                "APPLICATION.USER_SETTINGS.PASSWORD_CHANGE.ERROR.GENERIC_MESSAGE"
            );
        }
    }
}

export default UserProfilePasswordChangeViewStore;
