import React from "react";
import PropTypes from "prop-types";
import { HomePageLayout } from "application/home/components";
import { MrbGrid } from "mrb/components/grid";
import { MrbButton } from "mrb/components/button";
import {
    TorahAnytimeHeader,
    TorahAnytimeCollectionListItem,
    TorahAnytimeAvailabilityModal,
} from "application/torah-anytime/components";
import { MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { MrbLineContentLoader } from "baasic-react-bootstrap/src";

function TorahAnytimeTemplate({ torahAnytimeViewStore, t }) {
    const { gridStore, goToCreateNewCollectionPage, torahAnytimeAvailabilityModal } = torahAnytimeViewStore;
    return (
        <HomePageLayout store={torahAnytimeViewStore} t={t}>
            <div>
                <MrbPageHeader classNameExtend="c-page__header--secondary">
                    <TorahAnytimeHeader store={torahAnytimeViewStore} t={t} />
                </MrbPageHeader>
                <GridHeader torahAnytimeViewStore={torahAnytimeViewStore} t={t} />
                <MrbGrid
                    classNameOverride="test"
                    store={gridStore}
                    loaderRender={() => (
                        <MrbLineContentLoader
                            rows={3}
                            lineheight={50}
                            setY={64}
                            backgroundColor="#3C3D3E"
                            foregroundColor="#404143"
                        />
                    )}
                    t={t}
                    cellRender={({ item, ...props }) => (
                        <TorahAnytimeCollectionListItem
                            torahAnytimeViewStore={torahAnytimeViewStore}
                            collectionId={item.id}
                            {...props}
                        />
                    )}
                    emptyStateRender={() => <NoDataEmptyState onClickCreateNew={goToCreateNewCollectionPage} t={t} />}
                />
            </div>
            <TorahAnytimeAvailabilityModal modalParams={torahAnytimeAvailabilityModal} t={t} />
        </HomePageLayout>
    );
}

TorahAnytimeTemplate.propTypes = {
    torahAnytimeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const GridHeader = observer(function GridHeader({ torahAnytimeViewStore, t }) {
    const { gridStore, goToCreateNewCollectionPage } = torahAnytimeViewStore;
    return (
        gridStore.dataInitialized &&
        gridStore.hasData && (
            <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center u-mar--bottom--med">
                <p>{t("APPLICATION.TORAH_ANYTIME.LIST.MY_PLAYLISTS")}</p>
                <MrbButton
                    label="APPLICATION.TORAH_ANYTIME.LIST.ACTIONS.CREATE"
                    onClick={goToCreateNewCollectionPage}
                    t={t}
                />
            </div>
        )
    );
});

function NoDataEmptyState({ onClickCreateNew, t }) {
    return (
        <div className="c-card c-card--med c-card--primary">
            <div className="c-empty-state--no-results c-empty-state--no-results--lrg">
                <div className="u-mar--bottom--sml">
                    <i className="u-icon u-icon--xhuge u-icon--playlist-add"></i>
                </div>

                <div className="u-mar--bottom--med">
                    <h3>{t("APPLICATION.TORAH_ANYTIME.LIST.NO_DATA.TITLE")}</h3>
                    <p>{t("APPLICATION.TORAH_ANYTIME.LIST.NO_DATA.DESCRIPTION")}</p>
                </div>
                <MrbButton
                    label="APPLICATION.TORAH_ANYTIME.LIST.NO_DATA.ACTIONS.CREATE"
                    onClick={onClickCreateNew}
                    t={t}
                />
            </div>
        </div>
    );
}

export default defaultTemplate(TorahAnytimeTemplate);
